import { createSlice } from '@reduxjs/toolkit';

export const tenantPaymentSlide = createSlice({
    name : "tenantPayment",
    initialState : {
        status : 'initial-state',
        msg : null,
        id : null,
        amount : null,
        licencia : null,
        planMensualTipo : null,
        BackendErrorMessage : null
    },
    reducers : {
        failedPayTenant: ( state, { payload } ) => {
            state.status = 'failed'; 
            state.msg = null;
            state.id = null;
            state.amount = null;
            state.licencia = null;
            state.planMensualTipo = null;
            state.BackendErrorMessage = payload;
        },
        paymentSuccess: ( state, { payload } ) => {
            state.status = 'success';
            state.msg = payload.msg;
            state.id = payload.id;
            state.amount = payload.amount;
            state.licencia = payload.licencia;
            state.planMensualTipo = payload.planMensualTipo;            
            state.BackendErrorMessage = null;
        },
        paying : ( state )=>{
            state.status = 'in-progress';
        }
    }
});

export const { failedPayTenant, paymentSuccess, paying } = tenantPaymentSlide?.actions;