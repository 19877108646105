import React, { FormEvent, useState, useEffect, ChangeEvent } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useForm } from '../customhooks/useForm';
import { Paper } from '@mui/material';
import { FormEventsUpdateProps } from '../interfaces/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { startEventUpdateThunk } from '../store/eventos/index';
import { AppDispatch, RootState } from '../store/store';

import Title from '../atoms/Title';
import { SnackBar } from '../atoms/SnackBar';


const paperStyle = {
  margin: 'auto auto auto 30px',
  borderRadius: '10px',
  width: '100%',
  marginTop: '25px',
};
const textFieldStyle = {
  marginBottom: '20px',
  marginTop: '10px',
  width: '100%'
};

export const FormEventosUpdate = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const eventosData = useSelector((state: RootState) => state.eventos.eventos);
  const { onInputChange, formState, onResetForm } = useForm({
    uid: '',
    nombre: '',
    descripcion: '',
    fecha_inicio: '',
    fecha_fin: '',
    cupo_maximo: '',
  });
  //recuperar uid de los params en la url http://localhost:3000/user/eventos/actualizar?uid=652ae82604665c263775192c
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uidEvent = searchParams.get('uid');

  useEffect(() => {
    if (uidEvent) {
      const event: React.ChangeEvent<HTMLInputElement> = {
        target: {
          name: 'uid',
          value: uidEvent,
        },
      } as ChangeEvent<HTMLInputElement>;
      onInputChange(event);
    }
  }, [uidEvent, onInputChange]);

  useEffect(() => {

  }, [])

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const useToResetForm = await dispatch(startEventUpdateThunk(formState, navigate, uidEvent));
    if (useToResetForm?.ok === true) {
      onResetForm();
    }
  };
  return (
    <Paper style={paperStyle}>
      <Container maxWidth="sm">
        <Box mt={3}>
          <Title>Editar datos de un evento</Title>
          <form onSubmit={(e) => handleSubmit(e)} style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', width: '100%' }}>
            <TextField
              fullWidth
              label="evento uid"
              name="_id"
              value={formState.uid}
              onChange={onInputChange}
              variant="outlined"
              // placeholder={(uidEvent !== undefined)&&uidEvent}
              placeholder={formState.uid as string}
              style={textFieldStyle}
              required
            />
            <TextField
              fullWidth
              label="Nombre del Evento"
              name='nombre'
              value={formState.nombre}
              onChange={onInputChange}
              variant="outlined"
              style={textFieldStyle}
              required
            />
            <TextField
              fullWidth
              label="Descripción"
              name="descripcion"
              value={formState.descripcion}
              onChange={onInputChange}
              variant="outlined"
              multiline
              rows={4}
              style={textFieldStyle}
              required
            />
            <TextField
              fullWidth
              label="Fecha de Inicio"
              name="fecha_inicio"
              value={formState.fecha_inicio}
              onChange={onInputChange}
              variant="outlined"
              type="date"
              style={textFieldStyle}
              InputLabelProps={{
                shrink: true, // Ajusta la posición de la etiqueta
              }}
              required
            />
            <TextField
              fullWidth
              label="Fecha de Fin"
              name="fecha_fin"
              value={formState.fecha_fin}
              onChange={onInputChange}
              variant="outlined"
              type="date"
              style={textFieldStyle}
              InputLabelProps={{
                shrink: true, // Ajusta la posición de la etiqueta
              }}
              required
            />
            <TextField
              fullWidth
              label="Cupo Máximo"
              name="cupo_maximo"
              value={formState.cupo_maximo}
              onChange={onInputChange}
              variant="outlined"
              type="number"
              style={textFieldStyle}
              required
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              style={textFieldStyle}
              fullWidth
            >
              ACTUALIZAR
            </Button>
          </form>
        </Box>
      </Container>
    </Paper>
  );
}
