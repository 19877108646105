import { config } from '../config';

/**
 *@description Take user license , user plan type and calculate the total amount for the tenant plan
 * Those values are only used in the front end side.
 * @param { string } license user license . Could be BASICO, ESENCIAL, ELITE
 * @param { string } userPlanType user plan . Could be MENSUAL, SEMESTRAL, ANUAL
 * @return { Object } amount and amountWithoutInscription values
 *  - {number} amount: Include the inscription payment and license chooised cost.
 *  - {number} amountWithoutInscription : Only include the user license cost.
 */
export const calculateUsrPlanCost =(license : string, userPlanType : string )=>{
    try{
        let amount : number;
        let amountWithoutInscription : number;

        const upercaseLicense = license.toUpperCase();
        const upercaseUserPlanType = userPlanType.toUpperCase();

        let userPlanCost = {
            BASICO : config?.USER_BASICO_COST,
            ESENCIAL : config?.USER_ESENCIAL_COST,
            ELITE : config?.USER_ELITE_COST
        }
        switch ( upercaseLicense ) {
            case config?.PLAN_BASICO || 'BASICO':                  
                amount = config.INSCRIPTION_PAYMENT + userPlanCost?.BASICO * ( upercaseUserPlanType === config?.MONTHLY_PLAN ? 1 : upercaseUserPlanType === config?.SIX_MONTHLY_PLAN ? 6 : upercaseUserPlanType === config?.ANUAL_PLAN ? 12 : 0);
                amountWithoutInscription = userPlanCost?.BASICO * ( upercaseUserPlanType === config?.MONTHLY_PLAN ? 1 : upercaseUserPlanType === config?.SIX_MONTHLY_PLAN ? 6 : upercaseUserPlanType === config?.ANUAL_PLAN ? 12 : 0);             
                break;
            case config?.PLAN_ESENCIAL || 'ESENCIAL':
                amount = config.INSCRIPTION_PAYMENT + userPlanCost?.ESENCIAL * ( upercaseUserPlanType === config?.MONTHLY_PLAN ? 1 : upercaseUserPlanType === config?.SIX_MONTHLY_PLAN ? 6 : upercaseUserPlanType === config?.ANUAL_PLAN ? 12 : 0);
                amountWithoutInscription = userPlanCost?.BASICO * ( upercaseUserPlanType === config?.MONTHLY_PLAN ? 1 : upercaseUserPlanType === config?.SIX_MONTHLY_PLAN ? 6 : upercaseUserPlanType === config?.ANUAL_PLAN ? 12 : 0);                               
                break;
            case config?.PLAN_ELITE || 'ELITE':
                amount = config.INSCRIPTION_PAYMENT + userPlanCost?.ELITE * ( upercaseUserPlanType === config?.MONTHLY_PLAN ? 1 : upercaseUserPlanType === config?.SIX_MONTHLY_PLAN ? 6 : upercaseUserPlanType === config?.ANUAL_PLAN ? 12 : 0);
                amountWithoutInscription = userPlanCost?.BASICO * ( upercaseUserPlanType === config?.MONTHLY_PLAN ? 1 : upercaseUserPlanType === config?.SIX_MONTHLY_PLAN ? 6 : upercaseUserPlanType === config?.ANUAL_PLAN ? 12 : 0);                
                break;
            default:
                amount = 0;
                amountWithoutInscription = 0;
                break;
        }
        return { 
            amount,
            amountWithoutInscription
        };        
    }catch(error){
        return error
    }
}