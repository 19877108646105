import { createSlice } from "@reduxjs/toolkit";

export const changePasswordSlice = createSlice({
  name: "changePassword",
  initialState: {
    status: "initial",
    userId: null,
    oldPassword: null,
    newPassword: null,
    msg: null,
    BackendErrorMessage: null,
  },
  reducers: {
    failedChangePassword: (state, { payload }) => {
      state.status = "failed";
      state.msg = null;
      state.BackendErrorMessage = payload;
    },
    changePassword: (state, action) => {
      state.status = "success";
      state.userId = action.payload.userId;
      state.oldPassword = action.payload.oldPassword;
      state.newPassword = action.payload.newPassword;
      state.msg = action.payload.msg;
      state.BackendErrorMessage = null;
    },
    changingPassword: (state) => {
      state.status = "in-progress";
    },
  },
});

export const { failedChangePassword, changePassword, changingPassword } = changePasswordSlice.actions;