import { useState } from "react";

//controla el número de usuarios que aparecen en la tabla
export const usePagination =()=>{
    const [page, setPage] = useState(1);
    const usersPerPage = 8;
    const handleChangePage = (event:React.ChangeEvent<unknown>, newPage:number) => {
        setPage(newPage);
    };
    const startIndex = (page - 1) * usersPerPage;
    const endIndex = startIndex + usersPerPage;
    return{
        page,
        usersPerPage,
        handleChangePage,
        startIndex,
        endIndex
    }
}