import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { SearchUser } from '../components/SearchUser';
import { SearchProduct } from '../components/SearchProduct';
import { SearchCategory } from '../components/SearchCategory';
import { SearchEvent } from '../components/SearchEvent';

export const SearchScreens = () => {
  const role = useSelector((state: RootState) => state.auth.role);
  const service = useSelector((state: RootState) => state.saveSearchTerm?.section);

  console.warn('SERVICE :',service)
  const componentMap: { [key: string]: React.ReactElement } = {
    usuarios: <SearchUser />,
    productos: <SearchProduct />,
    categoria: <SearchCategory />,
    eventos: <SearchEvent />,
  };
  const selectedComponent = componentMap[service || 'usuarios'];
  

  if (role && selectedComponent) {
    return selectedComponent;
  } else if(!service){
    return <SearchUser />;
  }
};
