import { createSlice } from "@reduxjs/toolkit"

export const eventsAddAnUser = createSlice({
  name: 'eventosUsuarios',
  initialState: {
    status: 'initial-state',
    msg: null,
    idUser: null,
    BackendErrorMessage: null
  },

  reducers: {
    failedAddUser: (state, { payload }) => {
      state.status = 'failed';
      state.msg = null;
      state.idUser = null;
      state.BackendErrorMessage = payload;
    },
    userAdded: (state, { payload }) => {
      state.status = 'success';
      state.msg = payload.msg;
      state.idUser = payload.idUser;
      state.BackendErrorMessage = null;
    },
    addingUser: (state) => {
      state.status = 'in-progress';
    }
  }
})

export const { failedAddUser, userAdded, addingUser } = eventsAddAnUser.actions