import Title from '../atoms/Title'
import { Box, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { PaginationAtom } from '../atoms/PaginationAtom'

import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../store'
import { useLocation } from 'react-router-dom'
import { usePagination } from '../customhooks/usePagination'

import React, { useEffect } from 'react';
import { getAllEventsThunk } from '../store/eventos'

const headerCellStyle = {
  fontWeight: 'bold',
  minWidth: 180,
}

export const FormDetallesDelEvento = React.memo(() => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const eventId = location.pathname.split('/')[3];

  const { page, usersPerPage, handleChangePage } = usePagination()

  const eventos = useSelector((state: RootState) => state?.eventos?.eventos);
  const updatedEvent = useSelector((state: RootState) => state?.updateEvent);
  const participantes = eventos?.find((event) => event._id === eventId)?.participantes;

  useEffect(() => {
    dispatch(getAllEventsThunk());
  }, [ updatedEvent?.status === 'success' ]);
  
  return (
    <>
      <Grid item xs={12} md={12} lg={12} spacing={ 2 }>
        <Paper sx={ { p: 2, display: 'flex', flexDirection: 'column', overflowX: 'auto' } }>
          {
            participantes && participantes?.length > 0 ?
            <>
              <Title>Personas registradas</Title>
              <Table size='medium'>
                <TableHead>
                  <TableRow>
                    <TableCell sx={ headerCellStyle }>Uid</TableCell>
                    <TableCell sx={ headerCellStyle }>Nombre</TableCell>
                    <TableCell sx={ headerCellStyle }>Apellido materno</TableCell>
                    <TableCell sx={ headerCellStyle }>Apellido paterno</TableCell>
                    <TableCell sx={ headerCellStyle }>Número de teléfono</TableCell>
                    <TableCell sx={ headerCellStyle }> Email </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    participantes?.map((participante, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{participante?.uid}</TableCell>
                          <TableCell>{participante?.nombre}</TableCell>
                          <TableCell>{participante?.apellidoMaterno}</TableCell>
                          <TableCell>{participante?.apellidoPaterno}</TableCell>
                          <TableCell>{participante?.phoneNumber}</TableCell>
                          <TableCell>{participante?.email}</TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
              <PaginationAtom count={ Math.ceil(participantes?.length / usersPerPage) } page={page} onChange={handleChangePage} color="primary" shape="rounded" style={ { marginTop: '10px' } } />
            </>
            : 
            <Box minHeight={'200px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Title>No se han registrado personas a este evento</Title>
            </Box>
          }
        </Paper>
      </Grid>
    </>
  )
})
