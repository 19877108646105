import { createSlice } from "@reduxjs/toolkit";

export const changeLicenseSlice = createSlice({
  name: "changeLicense",
  initialState: {
    status: "initial",
    license: null,
    planMensualTipo: null,
    msg: null,
    BackendErrorMessage: null,
  },
  reducers: {
    failedChangeLicense: (state, { payload }) => {
      state.status = "failed";
      state.msg = null;
      state.BackendErrorMessage = payload;
    },
    changeLicense: (state, action) => {
      state.status = "success";
      state.license = action.payload.license;
      state.planMensualTipo = action.payload.planMensualTipo;
      state.msg = action.payload.msg;
      state.BackendErrorMessage = null;
    },
    changingLicense: (state) => {
      state.status = "in-progress";
    },
  },
});

export const { failedChangeLicense, changeLicense, changingLicense } = changeLicenseSlice.actions;