
import { LoginCredentials, RegisterUser, EMAIL_REQUEST_PASSWORD } from '../../../interfaces/interfaces';
import { AppDispatch, RootState } from '../../store';
import { login, logout } from './AuthSlice';
// import { checkingCredentials } from './AuthSlice';
import { checkingCredentials } from './index';
import { getClientsProvider, getAllUsersSliceProvider, loginWithEmailPassword, TenantRegisterAndgymManagementrole, getDetailsInfoProvider, getManagementServiceProvider, requestPasswordServiceProvider } from './provider';
import { registrado, registrando, registro } from './Register';
import { ErrorMessage } from '../../../components/ErrorMessage';
import { useSelector } from 'react-redux';
import { CheckingAuth } from '../../../atoms/CheckingAuth';
import { failed, getClientSuccess, inProgress } from '../Clients';
import { failedAllUsers, getAllUsers, inprogress } from '../allUsersSlice';
import { AxiosResponse } from 'axios';
import { failedDetails, getDetailsSuccess, inProgressDetails } from '../detailsInfoSlice';
import { failedManagement, getManagementSuccess, inProgressManagement } from '../managementServiceSlice';
import {failedRecoverPassword, recoverPasswordSuccess, recoverPasswordInProgress} from '../recoverPasswordSlice';

type ApiResponse = {
  ok: boolean;
  data?: AxiosResponse;
  // response?: AxiosResponse<{}, string>[];
  error?: any;
};

export const checkingAuthentication = (email: string, password: string) => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(checkingCredentials())
  }
}

export const startGoogleSignIn = () => {

  return async (dispatch: AppDispatch): Promise<void> => {
    //si el manejo de la autenticación en google es correcto, se llama esta acción
    dispatch(checkingCredentials())
  }
}


export const startLoginWithEmailPassword = ({ email, contrasena }: LoginCredentials, navigate: any) => {
  return async (dispatch: AppDispatch) => {
    dispatch(checkingCredentials())
    try {
      const res = await loginWithEmailPassword({ email, contrasena }, navigate);
      if (res?.ok) {
        const {
          uid,
          email,
          tenant,
          licencia,
          token,
          role,
          nombreEmpresa,
          nombre,
          apellidoMaterno,
          apellidoPaterno,
          salarioMensual,
          phoneNumber,
          fechaNacimiento,
          direccion,
        } = res;
        dispatch(login({
          uid,
          email,
          tenant,
          licencia,
          token,
          role,
          nombreEmpresa,
          nombre,
          apellidoMaterno,
          apellidoPaterno,
          salarioMensual,
          phoneNumber,
          fechaNacimiento,
          direccion,
        }));
        navigate('/dashboard');
      } else {
        dispatch({
          type: logout,
          payload: res?.error
        });
        navigate('/login');
      }
    } catch (error) {
      console.error('Error:', error);
      //dispatch(logout());
    }
  }
}

export const startRegisterUser = ({ nombreEmpresa, tenantName, databaseName, email, contrasena, fechaNacimiento, direccion, planMensualTipo, licencia, role, timeZone, phoneNumber }: RegisterUser, navigate: any) => {
  return async (dispatch: AppDispatch) => {
    dispatch(registrando())
    try {
      const res = await TenantRegisterAndgymManagementrole({ nombreEmpresa, tenantName, databaseName, email, contrasena, fechaNacimiento, direccion, planMensualTipo, licencia, role, timeZone, phoneNumber }, navigate);

      if (res?.ok) {
        const { responsee } = res;
        dispatch({
          type: registrado,
          payload: responsee
        })
        navigate('/login');
        return {
          ok: true
        }
      } else {
        dispatch({
          type: registro,
          payload: res?.error
        })        
        return {
          ok: false
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
}
export const getClients = () => {

  return async (dispatch: AppDispatch) => {
    dispatch(inProgress())
    try {
      const res: ApiResponse = await getClientsProvider();

      if (res?.ok) {
        const { data } = res;
        dispatch(getClientSuccess(data))
      } else {        
        dispatch(failed(res?.error))        
      }
    } catch (error) {
      console.error(error);
      dispatch(failed(error))      
    }

  }
}
export const getAllUsersThunk = () => {

  return async (dispatch: AppDispatch) => {
    dispatch(inprogress())
    try {
      const res: ApiResponse = await getAllUsersSliceProvider();

      if (res?.ok) {
        const { data } = res;
        dispatch(getAllUsers(data))
      } else {
        dispatch(failedAllUsers(res?.error))        
      }
    } catch (error) {
      console.error(error);
      dispatch(failedAllUsers(error))      
    }

  }
}

export const getDetailsInfo = () => {

  return async (dispatch: AppDispatch) => {
    dispatch(inProgressDetails())
    try {
      const res: ApiResponse = await getDetailsInfoProvider();

      if (res?.ok) {
        const { data } = res;
        dispatch(getDetailsSuccess(data))
      } else {
        dispatch(failedDetails(res?.error))        
      }
    } catch (error) {
      console.error(error);
      dispatch(failedDetails(error))      
    }

  }
}

export const getManagementService = () => {

  return async (dispatch: AppDispatch) => {
    dispatch(inProgressManagement())
    try {
      const res: ApiResponse = await getManagementServiceProvider();

      if (res?.ok) {
        const { data } = res;
        dispatch(getManagementSuccess(data))
      } else {
        dispatch(failedManagement(res?.error))        
      }
    } catch (error) {
      console.error(error);
      dispatch(failedManagement(error))
    }

  }
}

export const requestPasswordReset =(email:EMAIL_REQUEST_PASSWORD)=> {
  
  return async (dispatch: AppDispatch) => {
    dispatch(recoverPasswordInProgress())
    try {
      const res : ApiResponse = await requestPasswordServiceProvider(email);

      if (res?.ok) {
        const { data } = res;
        dispatch(recoverPasswordSuccess(data))
      }else{
        dispatch(failedRecoverPassword(res?.error))        
      }
    } catch (error) {
      console.error(error);
      dispatch(failedRecoverPassword(error))      
    }
    
  }
}
