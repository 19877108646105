
import { ApiResponse } from '../../types/types';
import { AppDispatch } from '../store';
import { 
    results,
    failedgettingResults,
    gettingResults,
    searchByTermAndSectionProvider
} from './index'

export const searchThunk =(collection : string,term : string)=>{
    return async (dispatch: AppDispatch) => {
        dispatch({
            type : gettingResults.type
        })
        try {
          const res : ApiResponse = await searchByTermAndSectionProvider(collection,term);
    
          if (res?.ok) {
            const { data } = res;
            dispatch({
                type : results?.type,
                payload : data
            })
            return {
                ok : true
            }
          }else{
            dispatch({
                type    :   failedgettingResults.type,
                payload :   res?.error
            })             
            return {
                ok : false
            }
          }
        } catch (error) {
          console.error(error);
          dispatch({
              type  :   failedgettingResults.type,
              payload   : error
          })
        }
        
      }
}
