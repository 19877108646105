import React, { ChangeEvent,FormEvent, useEffect, useState } from 'react';
import { TextField, InputAdornment, IconButton, Select, MenuItem } from '@mui/material';
import { SelectInputProps } from "@mui/material/Select/SelectInput";
import SearchIcon from '@mui/icons-material/Search';
import { useForm } from '../customhooks';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import { saveSearchArgs } from '../store/search/index';

const SearchBar : React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSection, setSelectedSection] = useState('usuarios');
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  //const {handleSelectChange,formState} = useForm()

  useEffect(() => {
  }, [selectedSection,searchTerm])
  
  const handleSectionChange: SelectInputProps['onChange'] = (event) => {
    setSelectedSection(event.target.value as string);
  }; 
  const handleSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (searchTerm.trim().length <= 2) {
      setError('El término de búsqueda debe tener más de 2 caracteres.');
      return;
    }
    dispatch({
      type : saveSearchArgs.type,
      payload : { term: searchTerm, section: selectedSection }
    });
    setError(null);
  };

  return (
    <form onSubmit={handleSearch}>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Select value={selectedSection} onChange={handleSectionChange} sx={{ marginRight: '6px', minWidth: '50px' }}>
          <MenuItem value="usuarios">Usuarios</MenuItem>
          <MenuItem value="eventos">Eventos</MenuItem>
          <MenuItem value="categoria">Categorías</MenuItem>
          <MenuItem value="productos">Productos</MenuItem>
        </Select>
        <TextField
          sx={{ width: '60%' }}
          value={searchTerm}
          onChange={
            (e: ChangeEvent<HTMLInputElement>) =>{
              setSearchTerm(e.target.value)
              setError(null);
            } 
            
          }
          placeholder="Buscar..."
          error={!!error}
          helperText={error} 
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type="submit">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </form>
  );
};

export default SearchBar;
