import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

export const AuthSlice = createSlice({
    name: "auth",
    initialState: {
        //status : 'not-authenticated', //'checking', 'not-authenticated' , 'authenticated'
        status: "checking",
        uid: null,
        email: null,
        nombre: null,
        apellidoPaterno: null,
        apellidoMaterno: null,
        salarioMensual: null,
        phoneNumber: null,
        fechaNacimiento: null,
        direccion: null,
        displayName: null,
        nombreEmpresa: null,
        tenant: null,
        licencia: null,
        role: null,
        photoURL: null,
        BackendErrorMessage: null,
        token: null
    },
    reducers: {
        login: (state, { payload }) => {
            state.status = 'authenticated'; // 'checking', 'not-authenticated', 'authenticated'
            state.uid = payload.uid;
            state.email = payload.email;
            state.nombreEmpresa = payload.nombreEmpresa;
            state.nombre = payload.nombre;
            state.apellidoPaterno = payload.apellidoPaterno;
            state.apellidoMaterno = payload.apellidoMaterno;
            state.salarioMensual = payload.salarioMensual;
            state.phoneNumber = payload.phoneNumber;
            state.fechaNacimiento = payload.fechaNacimiento;
            state.direccion = payload.direccion;
            state.tenant = payload.tenant;
            state.licencia = payload.licencia;
            state.role = payload.role;
            state.BackendErrorMessage = null;
            state.token = payload.token;
            localStorage.setItem('root', JSON.stringify(state));
        },
        logout: (state, { payload }) => {
            state.status = 'not-authenticated'; // 'checking', 'not-authenticated', 'authenticated'
            state.uid = null;
            state.email = null;
            state.displayName = null;
            state.nombreEmpresa = null;
            state.role = null;
            state.photoURL = null;
            state.BackendErrorMessage = payload;
            state.tenant = null;
            state.token = null;
            localStorage.removeItem('root');
            localStorage.setItem('hasShownAlert', 'false');
        },
        checkingCredentials: (state) => {
            state.status = 'checking'
        }
    }
});

export const { login, logout, checkingCredentials } = AuthSlice.actions;