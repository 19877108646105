import { NavigateFunction } from 'react-router-dom';
import { LoginCredentials, RegisterUser, EMAIL_REQUEST_PASSWORD } from "../../../interfaces/interfaces";
import axios, { AxiosResponse } from 'axios';
import { config } from '../../../config'
import { stagingController } from '../../../utils/stagingController';
import { developmentConfig } from '../../../developmentConfig';

let host = stagingController();
export const loginWithEmailPassword = async ({ email, contrasena }: LoginCredentials, navigate: NavigateFunction) => {
    try {
        const cleanedEmail = email.trim();
        const cleanedPassword = contrasena.trim();
        const response = await fetch(`${(host?.localhost) ? developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/api/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email : cleanedEmail, contrasena : cleanedPassword })
        });

        if (response.status === 200) {
            const responseData = await response.json();            
            const {
                uid,
                role,
                tenant,
                licencia,
                email,
                nombreEmpresa,
                nombre,
                apellidoPaterno,
                apellidoMaterno,
                salarioMensual,
                phoneNumber,
                fechaNacimiento,
                direccion
            } = responseData?.usuario;
            const token = responseData?.token;
            localStorage.setItem('token', token);

            return {
                ok: true,
                uid,
                role,
                tenant,
                licencia,
                nombreEmpresa,
                email,
                nombre,
                phoneNumber,
                fechaNacimiento,
                direccion,
                apellidoPaterno,
                apellidoMaterno,
                salarioMensual,
                token,
            };
        } else if (response.status === 404) {
            return {
                ok: false,
                error: 'Usuario no encontrado'
            };
        } else if (response.status === 401) {            
            return {
                ok: false,
                error: 'Contraseña incorrecta'
            };
        } else {
            const errorData = await response.json();
            console.error('Error en la respuesta del servidor:', errorData);            
            return {
                ok: false,
                error: errorData.msg
            };
        }
    } catch (error) {
        console.error('Error:', error);
        return { ok: false, error: 'Error desconocido' };
    }
};


export const TenantRegisterAndgymManagementrole = async ({ nombreEmpresa, tenantName, databaseName, email, contrasena, fechaNacimiento, direccion, planMensualTipo, licencia, role, timeZone, phoneNumber }: RegisterUser, navigate: any) => {
    try {
        const cleanedEmail = email.trim();
        const cleanedPassword = contrasena.trim();

        const headers = {
            'Content-Type': 'application/json'
        };

        const data = {
            nombreEmpresa,
            tenantName,
            databaseName,
            email : cleanedEmail,
            contrasena : cleanedPassword,
            fechaNacimiento,
            direccion,
            planMensualTipo,
            licencia,
            role,
            timeZone,
            phoneNumber
        }
        const response = await axios.post(`${(host?.localhost) ? developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/api/tenant/registro`, JSON.stringify(data), {
            headers: headers
        });

        if (response?.status === 200 || response?.status === 201) {            
            const responsee = response?.data;
            return {
                ok: true,
                responsee
            };
        }

    } catch (error: any) {
        if( error?.response?.data?.error ){
            let backendError = error?.response?.data?.error;
            return {
                ok : false,
                error : backendError
            }
        }else if (error?.response?.data && error?.response?.data?.errors) {
            const errores = error.response.data.errors;
            const ErrorMessagee = errores.map((errorItem: any) => errorItem.msg);
            return {
                ok: false,
                error: ErrorMessagee
            };
        }else {
            return {
                ok: false,
                error: 'Error desconocido'
            };
        }
    }
}
export const getClientsProvider = async () => {
    let xToken = localStorage.getItem('token');
    const headers = {
        'x-token': xToken,
        'Content-Type': 'application/json',
    };
    return await axios.get(`${(host?.localhost) ? developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/api/user/clientes`, { headers })
        .then((response: AxiosResponse) => {            
            console.warn('RESPONSE TYPE :', typeof (response.data))
            // const data = response.data.usuarios;
            const data = response?.data;            
            return {
                ok: true,
                data
            };
        })
        .catch((error) => {            
            if (error?.code === 'ERR_NETWORK') {
                const localStorageData = JSON.parse(localStorage.getItem('persist:root') as string)
                const data = JSON.parse(localStorageData.getClients)

                return {
                    ok: true,
                    data
                }
            }
            return {
                ok: false,
                error: error.response?.data?.msg
            };

        })
}

export const getAllUsersSliceProvider = async () => {
    let xToken = localStorage.getItem('token');
    const headers = {
        'x-token': xToken,
        'Content-Type': 'application/json',
    };
    return await axios.get(`${(host?.localhost) ? developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/api/user/all-users`, { headers })
        .then((response: AxiosResponse) => {            
            const data = response?.data;
            return {
                ok: true,
                data
            };
        })
        .catch((error) => {            
            console.error(error)
            return {
                ok: false,
                error: error?.response?.data?.msg
            };

        })
}

export const getDetailsInfoProvider = async () => {
    let xToken = localStorage.getItem('token');
    const headers = {
        'x-token': xToken,
        'Content-Type': 'application/json',
    };
    return await axios.get(`${(host?.localhost) ? developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/api/user/detailsinfo`, { headers })
        .then((response: AxiosResponse) => {            
            const data = response?.data;
            return {
                ok: true,
                data
            };
        })
        .catch((error) => {            
            if (error?.code === 'ERR_NETWORK') {
                const localStorageData = JSON.parse(localStorage.getItem('persist:root') as string)
                const data = JSON.parse(localStorageData.detailsinfo)

                return {
                    ok: true,
                    data
                }
            }
            return {
                ok: false,
                error: error.response?.data?.msg
            };

        })
}

export const getManagementServiceProvider = async () => {
    let xToken = localStorage.getItem('token');
    const headers = {
        'x-token': xToken,
        'Content-Type': 'application/json',
    };
    return await axios.get(`${(host?.localhost) ? developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/api/user/managementService`, { headers })
        .then((response: AxiosResponse) => {            
            const data = response?.data;
            return {
                ok: true,
                data
            };
        })
        .catch((error) => {            
            console.error(error)
            return {
                ok: false,
                error: error?.response?.data?.msg
            };

        })
} 



export const requestPasswordServiceProvider = async(email : EMAIL_REQUEST_PASSWORD) => {


    try{
        const data = {
            email,
        };

        const headers = {
            'Content-Type': 'application/json'
        };

        const response = await axios.post(`${(host?.localhost)?developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/forgot-password/recover`, JSON.stringify(data), {
            headers: headers
        });

        if (response?.status === 200 || response?.status === 201) {
            const data = response?.data?.msg;
            return {
                ok: true,
                data
            };
        }


    }catch(error){
        if( error?.response?.data?.error ){
            let backendError = error?.response?.data?.error;
            return {
                ok : false,
                error : backendError
            }
        }else if (error?.response?.data && error?.response?.data?.errors) {
            const errores = error.response.data.errors;
            const ErrorMessagee = errores.map((errorItem: any) => errorItem.msg);
            return {
                ok: false,
                error: ErrorMessagee
            };
        }else {
            return {
                ok: false,
                error: 'Error desconocido'
            };
        }

    }
}
