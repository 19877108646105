import { createSlice } from '@reduxjs/toolkit';
/**  Evento plan BASICO*/
/*interface INITIALSTATE {
    "nombre" : "Introducción a la programacion",
}
 */

export const postCategorySlide = createSlice({
    name : "postCategory",
    initialState : {
        status : 'initial-state',
        msg : null,
        nombre : null,
        BackendErrorMessage : null
    },
    reducers : {
        failedCreateCategory: ( state, { payload } ) => {
            state.status = 'failed'; 
            state.msg = null;
            state.nombre = null;
            state.BackendErrorMessage = payload;
        },
        categoryCreated: ( state, { payload } ) => {
            state.status = 'success'; // 'checking', 'not-authenticated', 'authenticated'
            state.msg = payload.msg;
            state.nombre = payload.nombre;
            state.BackendErrorMessage = null;
        },
        creatingCategory : ( state )=>{
            state.status = 'in-progress';
        }
    }
});

export const { failedCreateCategory,categoryCreated,creatingCategory } = postCategorySlide.actions;