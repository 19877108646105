import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';

import { InactivosClientesAtom } from '../atoms/InactivosClientesAtom';
import { InactivosUsuariosComponent } from '../atoms/InactivosUsuariosComponent';

import { InactivosTenantsUsers } from '../components/InactivosTenantsUsers';
import { FormReNewMembership } from '../components/FormReNewMembership';

export const Inactivos = () => {
  //const roles = clientUsers.usuarios.map((user) => user.role);
  const role = useSelector((state: RootState) => state.auth.role);

  return (
    <>
      <FormReNewMembership />
      {role === 'ADMIN' && <InactivosClientesAtom />}
      {role === 'SUPER_ADMIN' && <InactivosUsuariosComponent/>}
      {role === 'MANAGEMENT-SERVICE-ROLE' && <InactivosTenantsUsers />}
      {role !== 'ADMIN' && role !== 'SUPER_ADMIN' && role !== 'MANAGEMENT-SERVICE-ROLE' && <div>No se ha seleccionado un rol válido.</div> }
    </>
  )
}
