import { LOADER } from "../interfaces/interfaces"
import Logo from '../img/LOGO.png';

export const Loader = ({loaderText}:LOADER)=>{
    return(
        <div className='absolute w-full h-screen top-0 flex flex-col justify-center items-center bg-whiteGray opacity-90'>
            <img src={Logo} alt="" className="bg-mainBlue w-40 px-5 py-2 rounded" />
            <div
                className="inline-block h-8 w-8 mt-5 border-mainBlue animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status">
            </div>
            <p className='text-mainBlue text-sm'>{loaderText}</p>
        </div>
    )
}
