import axios, { AxiosResponse } from 'axios';
import { config } from '../../config';
import { stagingController } from '../../utils/stagingController';
import { developmentConfig } from '../../developmentConfig';

let host = stagingController();

export const searchByTermAndSectionProvider = async (collection : string ,term : string)=>{
    let xToken = localStorage.getItem('token');
    const headers = {
        'x-token': xToken,
        'Content-Type': 'application/json',
    };
    return await axios.get(`${(host?.localhost)? developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/api/search/${collection}/${term}`, { headers })
        .then((response:AxiosResponse) => {            
            const data = response?.data;
            return {
                ok: true,
                data
            };
        })
        .catch((error) =>{            
            console.error(error)
            return {
                ok: false,
                error : error?.response?.data?.msg
            };
            
        }) 
}
