import React, { useState, useEffect, FormEvent } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { Stepper, Step, StepLabel, Select, MenuItem } from '@mui/material';

import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import { useFormValidation } from '../customhooks/FormValidation'
import { startRegisterUser } from '../store/slides/Auth/index';

import { enqueueSnackbar, SnackbarProvider } from 'notistack';

//utils 
import { validatePassword } from '../utils/validatefields';


import '../styles/Signin.css'
import '../styles/ErrorMessage.css'
import { SnackBar } from '../atoms/SnackBar';
import { LoadingAtom } from '../atoms/LoadingAtom';
import candado from '../img/candado.svg';
import emailImage from '../img/email.svg';
import userImage from '../img/usuario 2.svg';
import calendarImage from '../img/calendario 2.svg';

import companyImage from '../img/company.svg';
import databaseImage from '../img/basedatos.svg';
import locationImage from '../img/location.svg';
import roleImage from '../img/role.svg';
import licenciaImage from '../img/licencia.svg';
import planImage from '../img/plan.svg';
import people from '../img/Fotos.svg';
import bubbles from '../img/Circulos.svg';
import LogoImage from '../img/LOGO.png';
import { Alert } from '@mui/material';

import ojoClosed from '../img/ojoClosed.png';
import ojoOpen from '../img/ojoOpen.png';
import { getTimeZoneFromUser } from '../utils/timeZoneParser';
import { useForm } from '../customhooks';
import { useFormStateValidation } from '../customhooks/useFormStateValidation';

import { StripePaymentCard } from './StripePaymentCard';
import { Notifications } from '../atoms/Notifications';

export const Signin: React.FC = () => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { BackendErrorMessage, status } = useSelector((state: RootState) => state.register);
  const handleRegister = useSelector((state: RootState) => state?.register);
  const stripePaymentState = useSelector((state: RootState) => state?.tenantPay);
  const timeZoneData = getTimeZoneFromUser();

  /** Ocultar y mostrar contraseña en revision de data*/
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const {
    password,
    setErrorMessage,
  } = useFormValidation();

  const { handleSelectChange, onInputChange, formState, onResetForm } = useForm({
    tenantName: '',
    nombreEmpresa: '',
    databaseName: '',
    email: '',
    fechaNacimiento: '',
    direccion: '',
    planMensualTipo: 'MENSUAL',
    planStatusActivo: 'true',
    contrasena: '',
    confirmPassword: '',
    licencia: 'BASICO',
    phoneNumber: '',
    timeZone: {
      identifier: timeZoneData.userTimeZone,
      offset: timeZoneData.userOffset
    },
    role: 'SUPER_ADMIN',
  })

  const {
    errorPassword,
    passwordFormatError,
    diffPass,
    comparePassword,
    comparePasswordError,
    handlePassword,
    handleEmail,
    dateValidation,
    dateBornError,
    dateBorn,
    error,
    fieldFormatError
  } = useFormStateValidation(formState);

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [activeStep, setActiveStep] = useState(0);
  const [passwordInputType, setPasswordInputType] = useState('password');

  const steps = [
    'Datos Personales',
    'Datos de la empresa',
    'Detalles del Plan',
    'Revisar y Enviar',
    'Datos bancarios',
  ];

  useEffect(() => {
    if (showErrorMessage) {
      const timer = setTimeout(() => {
        setShowErrorMessage(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showErrorMessage]);

  useEffect(() => {
    const { contrasena, confirmPassword, email, fechaNacimiento } = formState;
    handlePassword(contrasena as string);
    comparePassword(contrasena as string, confirmPassword as string);
    handleEmail(email as string);
    dateValidation(fechaNacimiento as string);
  }, [formState?.contrasena, formState?.confirmPassword, formState?.email, formState?.fechaNacimiento])

  const registerUser = async () => {
    setErrorMessage('')
    setShowErrorMessage(false);
    const { nombreEmpresa, tenantName, databaseName, email, contrasena, fechaNacimiento, direccion, planMensualTipo, licencia, role, timeZone, phoneNumber } = formState;
    // await CheckoutForm();

    const success = await dispatch(startRegisterUser({ nombreEmpresa, tenantName, databaseName, email, contrasena, fechaNacimiento, direccion, planMensualTipo, licencia, role, timeZone, phoneNumber }, navigate));
    if (success?.ok === true) {
      onResetForm();
    } else {
      return
    }
  }

  const validateBeforeNextStep = () => {
    if (activeStep === 0) {
      if (formState?.tenantName === '' || formState?.email === '' || formState?.fechaNacimiento === '' || formState?.contrasena === '' || formState?.confirmPassword === '') {
        enqueueSnackbar('Completa todos los datos personales!', { variant: 'error' });
        return;
      }

      if (fieldFormatError) {
        enqueueSnackbar(error, { variant: 'error' });
        return;
      }

      if (dateBornError) {
        enqueueSnackbar(dateBorn, { variant: 'error' });
        return;
      }

      if (passwordFormatError || comparePasswordError) {
        enqueueSnackbar(errorPassword, { variant: 'error' });
        return;
      }

    }

    if (activeStep === 1) {
      if (formState?.nombreEmpresa === '' || formState?.databaseName === '' || formState?.direccion === '') {
        enqueueSnackbar('Completa todos los datos de la empresa!', { variant: 'error' });
        return;
      }
    }

    if (activeStep === 2) {
      if (formState?.role === '' || formState?.licencia === '' || formState?.planMensualTipo === '') {
        enqueueSnackbar('Completa todos los detalles del plan!', { variant: 'error' });
        return;
      }
    }

    if (activeStep !== 4) {
      setActiveStep(activeStep + 1);
      return;
    }

    if (activeStep === 4) {
      registerUser();
    }
  };

  return (
    <div className="w-full bg-white" style={{ height: '100vh' }}>
      <div className="p-12 absolute w-full h-screen z-30 flex flex-col justify-center items-center">
        <p className="text-mainBlue text-4xl font-bold text-center">Crear una cuenta</p>
        <p className="font-semibold text-lightGray mt-3 text-center">Regístrate para comenzar en Volcano Flex Admin</p>

        <div className="w-9/12 mt-2 md:w-full sm:w-full" style={{ overflowX: 'auto' }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <form className='w-full mt-8'>

          {activeStep === 0 ? (
            <div className='mt-8 w-10/12 md:w-full sm:w-full'>
              <p className='text-mainBlue font-bold text-xl'>Datos personales</p>
              <div className='flex flex-col mt-2 md:w-full'>
                <div className='flex items-center border-b border-lightBlue pb-1 mb-6' title='Nombre del propietario o empresa'>
                  <img src={userImage} alt="" className='mr-2 w-6 h-6' />
                  <input
                    className='w-full bg-transparent outline-0 placeholder:italic placeholder:text-sm text-lightGray'
                    type="text"
                    placeholder='Nombre del propietario o empresa'
                    name="tenantName"
                    value={formState?.tenantName as string}
                    onChange={onInputChange}
                    required
                  />
                </div>
                <div className='flex items-center border-b border-lightBlue pb-1 mb-6'>
                  <img src={emailImage} alt="" className='mr-2 w-6 h-6' />
                  <input
                    className='w-full bg-transparent outline-0 placeholder:italic placeholder:text-sm text-lightGray'
                    type="email"
                    placeholder='Direcciòn de correo'
                    name="email"
                    value={formState?.email as string}
                    onChange={onInputChange}
                    required
                  />
                </div>
                {fieldFormatError && (
                  <div className="text-red-500 text-sm">{error}</div>
                )}
                <div className='flex items-center border-b border-lightBlue pb-1 mb-6'>
                  <img src={calendarImage} alt="" className='mr-2 w- h-6' />
                  <Tooltip title="Fecha de nacimiento del usuario o de la empresa" arrow>
                    <input
                      className='w-auto bg-transparent outline-0 placeholder:italic placeholder:text-sm text-lightGray'
                      type="date"
                      name='fechaNacimiento'
                      value={formState?.fechaNacimiento as string}
                      onChange={onInputChange}
                    />
                  </Tooltip>
                </div>
                <div className='flex items-center border-b border-lightBlue pb-1 mb-6'>
                  <img src={candado} alt="" className='mr-2 w-6 h-6' />
                  <input
                    className='w-full bg-transparent outline-0 placeholder:italic placeholder:text-sm text-lightGray'
                    type={passwordInputType}
                    placeholder='Contraseña'
                    value={formState?.contrasena as string}
                    name="contrasena"
                    onChange={onInputChange}
                    required
                    pattern=".{8,}"
                  />
                  <img onClick={() => {
                    setPasswordInputType(passwordInputType === 'password' ? 'text' : 'password')
                  }} src={passwordInputType === 'password' ? ojoClosed : ojoOpen} alt="" className='w-6' />
                </div>
                {passwordFormatError && (
                  <div className="text-red-500 text-sm">{errorPassword}</div>
                )}
                <div className='flex items-center border-b border-lightBlue pb-1 mb-6'>
                  <img src={candado} alt="" className='mr-2 w-6 h-6' />
                  <input
                    className='w-full bg-transparent outline-0 placeholder:italic placeholder:text-sm text-lightGray'
                    type="password"
                    placeholder='Confirmaciòn de contraseña'
                    name='confirmPassword'
                    value={formState?.confirmPassword as string}
                    onChange={onInputChange}
                  />
                </div>
                {comparePasswordError && (
                  <div className="text-red-500 text-sm">{diffPass}</div>
                )}
              </div>
            </div>
          ) : null}

          {activeStep === 1 ? (
            <div className='mt-8 w-10/12 md:w-full sm:w-full'>
              <p className='text-mainBlue font-bold text-xl'>Datos de la empresa</p>
              <div className='flex flex-col mt-2'>
                <div className='flex items-center border-b border-lightBlue pb-1 mb-6'>
                  <img src={companyImage} alt="" className='mr-2 w-6 h-6' />
                  <input
                    className='w-full bg-transparent outline-0 placeholder:italic placeholder:text-sm text-lightGray'
                    type="text"
                    placeholder='Nombre de la empresa'
                    name="nombreEmpresa"
                    value={formState?.nombreEmpresa as string}
                    onChange={onInputChange}
                    required
                  />
                </div>
                <div className='flex items-center border-b border-lightBlue pb-1 mb-6'>
                  <img src={databaseImage} alt="" className='mr-2 w-6 h-6' />
                  <input
                    className='w-full bg-transparent outline-0 placeholder:italic placeholder:text-sm text-lightGray'
                    type="text"
                    placeholder='Mi base de datos'
                    name="nombreEmpresa"
                    value={formState?.databaseName as string}
                    onChange={onInputChange}
                    required
                  />
                </div>
                <div className='flex items-center border-b border-lightBlue pb-1 mb-6'>
                  <img src={locationImage} alt="" className='mr-2 w- h-6' />
                  <input
                    className='w-full bg-transparent outline-0 placeholder:italic placeholder:text-sm text-lightGray'
                    type="text"
                    placeholder='Direcciòn'
                    name='direccion'
                    onChange={onInputChange}
                    value={formState?.direccion as string}
                  />
                </div>
              </div>
            </div>
          ) : null}

          {activeStep === 2 ? (
            <div className='mt-8 w-10/12 md:w-full sm:w-full'>
              <p className='text-mainBlue font-bold text-xl'>Detalles del plan</p>
              <div className='flex flex-col mt-2'>
                {/* <div className='flex items-center border-b border-lightBlue pb-1 mb-6'>
                  <img src={roleImage} alt="" className='mr-2 w-6 h-6' />
                  <select id="role" name="role" value={formState?.role as string} onChange={handleSelectChange} className='w-full bg-transparent text-lightGray outline-0 placeholder:italic placeholder:text-sm bg-white'>
                    <option value="SUPER_ADMIN" className='text-lightGray'>Super admin</option>
                  </select>
                </div> */}
                <div className='flex items-center border-b border-lightBlue pb-1 mb-6'>
                  <img src={licenciaImage} alt="" className='mr-2 w-6 h-6' />
                  <select id="licencia" name="licencia" value={formState?.licencia as string} onChange={handleSelectChange} className='w-full bg-transparent text-lightGray outline-0 placeholder:italic placeholder:text-sm bg-white'>
                    <option value="BASICO" className='text-lightGray'>Básico</option>
                    {/* <option value="ESENCIAL" className='text-lightGray'>Esencial</option>
          <option value="ELITE" className='text-lightGray'>Elite</option> */}
                  </select>
                </div>
                <div className='flex items-center border-b border-lightBlue pb-1 mb-6'>
                  <img src={planImage} alt="" className='mr-2 w- h-6' />
                  <select id="planMensualTipo" name="planMensualTipo" value={formState?.planMensualTipo as string} onChange={handleSelectChange} className='w-full outline-0 placeholder:italic placeholder:text-sm bg-white text-lightGray'>
                    <option value="MENSUAL" className='text-lightGray'>Mensual</option>
                    <option value="SEMESTRAL" className='text-lightGray'>Semestral</option>
                    <option value="ANUAL" className='text-lightGray'>Anual</option>
                  </select>
                </div>
              </div>
            </div>
          ) : null}

          {activeStep === 3 ? (
            <div className='mt-8 w-full md:w-full sm:w-full max-w-[80%]' title='Apartado de revisar información'>
              <p className='text-mainBlue font-bold text-xl mb-6'>Por favor, revise cuidadosamente la información proporcionada antes de finalizar el registro.</p>
              <div className='flex flex-wrap justify-between w-full'>
                {/* Datos personales */}
                <div className='review-section mb-6 sm:mr-4'>
                  <p className='font-semibold text-mainBlue mb-2'>Datos personales</p>
                  <div className='flex flex-col'>
                    <div className='flex items-center mb-1' title='Nombre del propietario del negocio'>
                      <img src={userImage} alt="" className='review-icon mr-2 w-5 h-5' />
                      <p className='text-lightGray text-sm'>{formState?.tenantName as string}</p>
                    </div>
                    <div className='flex items-center mb-1' title='Correo del usuario'>
                      <img src={emailImage} alt="" className='review-icon mr-2 w-5 h-5' />
                      <p className='text-lightGray text-sm'>{formState?.email as string}</p>
                    </div>
                    <div className='flex items-center mb-1' title='contraseña para la cuenta con volcano admin'>
                      <img
                        src={showPassword ? ojoOpen : ojoClosed}
                        alt="Opción de mostrar contraseña"
                        className='review-icon mr-2 w-5 h-5'
                        onClick={togglePasswordVisibility}
                        style={{ cursor: 'pointer' }}
                      />
                      <p className='text-lightGray text-sm'>{showPassword ? formState?.contrasena as string : '********'}</p>
                    </div>
                    <div className='flex items-center' title='Fecha de nacimiento de la empresa o propietario'>
                      <img src={calendarImage} alt="" className='review-icon mr-2 w-5 h-5' />
                      <p className='text-lightGray text-sm'>{formState?.fechaNacimiento as string}</p>
                    </div>
                  </div>
                </div>

                {/* Datos de la empresa */}
                <div className='review-section mb-6 sm:mr-4'>
                  <p className='font-semibold text-mainBlue mb-2'>Datos de la empresa</p>
                  <div className='flex flex-col'>
                    <div className='flex items-center mb-1' title='Nombre de la empresa'>
                      <img src={companyImage} alt="" className='review-icon mr-2 w-5 h-5' />
                      <p className='text-lightGray text-sm'>{formState?.nombreEmpresa as string}</p>
                    </div>
                    <div className='flex items-center mb-1' title='Datos en la nube'>
                      <img src={databaseImage} alt="" className='review-icon mr-2 w-5 h-5' />
                      <p className='text-lightGray text-sm'>{formState?.databaseName as string}</p>
                    </div>
                    <div className='flex items-center' title='Dirección de la empresa'>
                      <img src={locationImage} alt="" className='review-icon mr-2 w-5 h-5' />
                      <p className='text-lightGray text-sm'>{formState?.direccion as string}</p>
                    </div>
                  </div>
                </div>

                {/* Detalles del plan */}
                <div className='review-section mb-6 sm:mr-4'>
                  <p className='font-semibold text-mainBlue mb-2'>Detalles del plan</p>
                  <div className='flex flex-col'>
                    {/* <div className='flex items-center mb-1' title='Mi role de usuario'>
                      <img src={roleImage} alt="" className='review-icon mr-2 w-5 h-5' />
                      <p className='text-lightGray text-sm'>{formState?.role as string}</p>
                    </div> */}
                    <div className='flex items-center mb-1' title='Licencia'>
                      <img src={licenciaImage} alt="" className='review-icon mr-2 w-5 h-5' />
                      <p className='text-lightGray text-sm'>{formState?.licencia as string}</p>
                    </div>
                    <div className='flex items-center' title='Tipo de plan'>
                      <img src={planImage} alt="" className='review-icon mr-2 w-5 h-5' />
                      <p className='text-lightGray text-sm'>{formState?.planMensualTipo as string}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {activeStep === 4 ? (
            <div className='mt-8 w-10/12 md:w-full sm:w-full'>
              <p className='text-mainBlue font-bold text-xl'>Datos bancarios</p>
              <div className='flex flex-col mt-2'>
                <div className="border border-grey-400 rounded-lg p-5 mb-10">
                  <StripePaymentCard
                    custumerEmail={formState?.email}
                    licencia={formState?.licencia}
                    planMensualTipo={formState?.planMensualTipo}
                    nombreEmpresa={formState?.nombreEmpresa}
                    nuevoRegistro={true}
                  />
                </div>
              </div>
            </div>
          ) : null}

        </form>

        {/* Navegación entre pasos */}
        <div className="flex sm:flex-col justify-between w-full mt-4 md:w-8/12 sm:w-full">
          {activeStep >= 1 && (
            <button
              className="border border-mainBlue outline-none bg-white hover:cursor-pointer rounded-sm w-3/12 md:w-auto sm:w-full px-2 py-2 sm:mb-4"
              onClick={() => { setActiveStep(activeStep - 1) }}
            >
              <p className="text-mainBlue text-center">Volver</p>
            </button>
          )}
          <button
            className="bg-mainBlue hover:cursor-pointer w-3/12 md:w-auto sm:w-full rounded-sm px-2 py-2 transition duration-300 ease-in-out transform hover:shadow-lg hover:bg-blue-600"
            onClick={(e) => {
              validateBeforeNextStep();
              //handleClick(e);         
            }}
          >
            <p className="text-white text-center">{activeStep === 4 ? 'Registrar' : 'Siguiente'}</p>
          </button>
        </div>

        {/* Mensajes de error o información */}
        <SnackbarProvider autoHideDuration={2000} maxSnack={2} />

        {handleRegister.status === 'usuario-no-registrado' ? (
          <SnackBar severity="error" errorMsg={handleRegister.BackendErrorMessage} />
        ) : null}

        {handleRegister.status === 'registrando' ? (
          <SnackBar severity="info" errorMsg="Registrando usuario, apreciamos su paciencia!" />
        ) : null}
      </div>

      {/* Imágenes decorativas */}
      {/* <img src={wave} alt="" className="w-full absolute bottom-0 z-10" /> */}
      <div className="absolute right-0 w-2/12 z-10 animate-smallJump">
        <img src={people} alt="" className="absolute top-0 w-full h-full" />
        <img src={bubbles} alt="" className="top-0 w-full h-full sm:hidden md:hidden" />
      </div>
      <Notifications handler={stripePaymentState} actionType='register' actionName='pay' />
    </div>
  );
}