import { Box, Button, Divider, InputAdornment, Modal, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import React, { FormEvent, useState, useEffect } from "react";

import { AppDispatch, RootState } from "../store";
import { closeModal } from "../store/modal";
import { sellProductThunk } from "../store/productos/index";

import Title from "../atoms/Title";
import { useForm } from "../customhooks";

const style = {
  display: 'flex',
  gap: '15px',
  flexDirection: 'column',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,
};

const btnStyle = {
  minWidth: '100px',
  fontSize: '13px',
}

interface ModalSellProductProps {
  modalType: string
}

const ModalSellProduct = ({ modalType }: ModalSellProductProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();

  const [amount, setAmount] = useState(0);
  const [pay, setPay] = useState('');

  const open = useSelector((state: RootState) => state?.modal?.openModal);
  const currentModalType = useSelector((state: RootState) => state?.modal?.modalType)
  const productById = useSelector((state: RootState) => state?.productById?.producto);


  const { onInputChange, formState, onResetForm } = useForm({
    serviceIdentifier: 'producto',
    product: productById?._id,
    quantity: '',
    unitPrice: Number(productById?.precio)
  });

  const disponibilidad = Number(formState?.quantity) > productById?.stock
  const lessThanZero = Number(pay) < amount
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const responsiveStyle = {
    ...style,
    width: isXSmall ? '300px' : isSmall ? '300px' : '600px',
  };


  const handleClose = () => {
    onResetForm()
    setPay('')
    dispatch(closeModal())
  }

  const handleSumbit = async (e: FormEvent) => {
    e.preventDefault();
    if (disponibilidad) return
    if (lessThanZero) return
    if (Number(formState?.quantity) === 0) return

    const success = await dispatch(sellProductThunk(formState, amount))
    if (success?.ok === true) {
      onResetForm()
      setPay('')
      handleClose()
    }
  }

  useEffect(() => {
    setAmount(Number(formState?.quantity) * productById?.precio);
  }, [formState?.quantity, productById?.precio])

  return (
    <>
      {
        modalType === currentModalType && (
          <Modal open={ open } onClose={ handleClose } aria-labelledby="Vender producto" aria-describedby="vende un producto">
            <form onSubmit={ handleSumbit } style={ { display: 'flex', flexWrap: 'wrap', flexDirection: 'column', width: '100%' } }>
              <Box sx={ responsiveStyle }>
                <Title>VENDER { productById?.nombre }</Title>

                <TextField
                  error={ disponibilidad }
                  fullWidth
                  label="Cantidad del producto a vender"
                  name='quantity'
                  type='text'
                  helperText={ disponibilidad ? 'Excede el stock disponible. Ajusta la cantidad.' : '' }
                  value={ formState?.quantity }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value;
                    // Permitir solo números con hasta dos decimales y no negativos.
                    if (value === '' || /^[0-9]*\.?[0-9]{0,2}$/.test(value)) {
                      onInputChange(e);
                    }
                  } }
                  variant="outlined"
                  required
                  InputProps={ {
                    inputProps: {
                      min: 0
                    },
                    onKeyDown: (event) => {
                      // Permitir solamente números y el punto
                      if (
                        (event.key === '-' || event.key === 'e' || event.key === '+') || // No permitir 'e', '+', '-'
                        (event.key === '.' && event.currentTarget.value.includes('.')) // No permitir múltiples puntos
                      ) {
                        event.preventDefault();
                      }
                    }
                  } }
                />

                <TextField
                  error={ pay.length>0 && lessThanZero }
                  fullWidth
                  label="Cantidad con la que pagará"
                  name="pay"
                  type='text'
                  variant='outlined'
                  value={ pay }
                  helperText={ (pay.length>0 && lessThanZero) ? 'La cantidad pagada no puede ser menor al total a pagar.' : '' }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    // Permitir solo números con hasta dos decimales.
                    const value = e.target.value;
                    if (value === '' || /^(\d+\.?\d{0,2}|\.\d{0,2})$/.test(value)) {
                      setPay(value);
                    }
                  } }
                  onBlur={ () => {
                    // Formatear el número para asegurar que tenga dos decimales.
                    if (pay !== '') {
                      setPay(Number(pay).toFixed(2));
                    }
                  } }
                  InputProps={ {
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  } }
                />

                <Divider />

                <Box>
                  <Box display='flex' justifyContent='space-between'>
                    <Typography>Precio unitario</Typography>
                    <Typography>${productById?.precio}</Typography>
                  </Box>
                  <Box display='flex' justifyContent='space-between'>
                    <Typography>Total</Typography>
                    <Typography>${amount}</Typography>
                  </Box>
                  <Box display='flex' justifyContent='space-between'>
                    <Typography>Cambio</Typography>
                    <Typography>${ (pay ? Number(pay) - amount : 0).toFixed(2) }</Typography>
                  </Box>
                </Box>

                <Divider />

                <Box display='flex' justifyContent='space-between'>
                  <Button
                    variant="outlined"
                    color="error"
                    size="large"
                    style={ btnStyle }
                    onClick={ handleClose }
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    style={ btnStyle }
                    disabled = { disponibilidad || lessThanZero}
                  >
                    Vender
                  </Button>
                </Box>
              </Box>
            </form>
          </Modal>
        )
      }
    </>
  )
}

export default ModalSellProduct;