import { Avatar, Box, Grid, useMediaQuery } from "@mui/material";
import { InfoLabelWithTitle } from "../atoms/InfoLabelWithTitle";

import type { CONFIG_USER_DATA_PROPS } from "../interfaces/interfaces";

const ConfigurationUserData = ({ email, nombreEmpresa, tenant, licencia }: CONFIG_USER_DATA_PROPS) => {
  const mobile = useMediaQuery("(max-width: 425px)")

  return (
    <Box display={ "flex" } flexDirection={ mobile ? "column" : "row" } justifyContent={ "center" } alignItems={ "center" } gap={ 5 } margin={ 5 }>
      <Avatar sx={ { width: 150, height: 150, textAlign: "center" } }>No disponible en el plan basico</Avatar>

      <Grid container spacing={ 5 } flexDirection={ mobile ? "column" : "row" } alignContent={ "center" } justifyContent={ "center" } width={ "65%" }>
        <Grid item>
          <InfoLabelWithTitle title="Nombre de la empresa" value={ nombreEmpresa } />
          <InfoLabelWithTitle title="Tenant ID" value={ tenant } />
        </Grid>

        <Grid item display={ "flex" } flexDirection={ "column" }>
          <InfoLabelWithTitle title="Correo" value={ email } />
          <InfoLabelWithTitle title="Plan" value={ licencia } />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ConfigurationUserData;