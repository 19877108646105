/**
 * serch functionality actions
 */
import { createSlice } from "@reduxjs/toolkit";

export const getSearchResultsSlide = createSlice({
    name : "search-service",
    initialState : {
        status : 'normal', 
        msg : null,
        totalResults : 0,
        results : [] as any[], //users,categorias,productos,eventos
        BackendErrorMessage : null
    },
    reducers : {
        failedgettingResults: ( state, { payload } ) => {
            state.status = 'failed'; 
            state.totalResults = 0;
            state.results = [];
            state.BackendErrorMessage = payload;
        },
        results: ( state, { payload } ) => {
            state.status = 'success'; 
            state.msg = payload.msg; 
            state.totalResults = payload.totalResults;
            state.results = payload.results;
            state.BackendErrorMessage = null;
        },
        gettingResults : ( state )=>{
            state.status = 'in-progress'
        }
    }
});

export const { results,failedgettingResults,gettingResults} = getSearchResultsSlide.actions;