import { createSlice } from "@reduxjs/toolkit";

export const renewMembershipSlide = createSlice({
  name: "renewMembership",
  initialState: {
    status: "initial",
    msg: null,
    planMensualTipo: null,
    planMensualMonto: null,
    BackendErrorMessage : null

  },
  reducers: {
    failedRenewMembership: (state, { payload }) => {
      state.status = "failed";
      state.msg = null;
      state.BackendErrorMessage = payload;
    },
    renewMembership: (state, action) => {
      state.status = "success";
      state.msg = action.payload.msg;
      state.planMensualTipo = action.payload.planMensualTipo;
      state.planMensualMonto = action.payload.planMensualMonto;
      state.BackendErrorMessage = null;
    },
    renewingMembership: (state) => {
      state.status = "in-progress";
    },
  },
});

export const { failedRenewMembership, renewMembership, renewingMembership } = renewMembershipSlide.actions;