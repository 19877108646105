import React, { useEffect, useMemo } from 'react'
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { WELCOME_SNACK } from '../interfaces/interfaces';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export const WelcomeAtom = React.memo(({severity, msg }:WELCOME_SNACK) => {
    
    const authenticated = useSelector((state: RootState) => state.auth.status);
    const [open, setOpen] = React.useState(false);
    
    useEffect(() => {
        /** Al logearse el usuario hasShownAlert se convierte en true
         * por lo que es válido mostrar el mensaje, y después setearlo a false
         * para que solo se muestre una ves por sesión
         */
        const mostrar = localStorage.getItem('hasShownAlert');
        if(authenticated === 'authenticated' && mostrar !== 'true' ){
            localStorage.setItem('hasShownAlert','true')
            setOpen(true)
        }
    }, [authenticated]);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        event?.preventDefault();
        setOpen(false);
    };
    return (
        <>
            <Stack>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                        {msg}
                    </Alert>
                </Snackbar>
            </Stack>
        </>
    );
})