import { createSlice } from '@reduxjs/toolkit';
import { USER_ADMIN_TRAFFIC } from '../../../interfaces/interfaces';



export const getUserAdminTrafficReportSlide = createSlice({
    name : "adminUserTraffic",
    initialState : {
        status : 'normal', 
        traffic : {} as USER_ADMIN_TRAFFIC,
        BackendErrorMessage : null
    },
    reducers : {
        failedGettingTrafficData: ( state, { payload } ) => {
            state.status = 'failed';
            state.BackendErrorMessage = payload;
        },
        getTrafficData: ( state, { payload } ) => {
            state.status = 'success'; 
            state.traffic = payload;
            state.BackendErrorMessage = null;
        },
        gettingTrafficData : ( state )=>{
            state.status = 'in-progress'
        }
    }
});

export const { failedGettingTrafficData, getTrafficData, gettingTrafficData} = getUserAdminTrafficReportSlide.actions;