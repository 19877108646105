import React, { useState, ChangeEvent, useEffect } from 'react';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Chip, Grid, Paper, Typography } from '@mui/material';
import { useForm } from '../customhooks/useForm';
import { startRegisterClientThunk } from '../store/usuarios';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { useNavigate } from 'react-router-dom';
import { SnackBar } from '../atoms/SnackBar';
import { useFormStateValidation } from '../customhooks/useFormStateValidation';
import { getTimeZoneFromUser } from '../utils/timeZoneParser';


const paperStyle = {
  padding: '20px',
  margin: 'auto auto auto 30px',
  borderRadius: '10px',
  width: '100%',
  marginTop: '25px',
  //maxWidth: '500px',
};

const formStyle = {
  display: 'flex',
  flexWrap: 'wrap',
};

const textFieldStyle = {
  marginBottom: '20px',
  marginTop: '10px',
  width: '100%'
};

const selectStyle = {
  marginBottom: '20px',
  marginTop: '40px'
};

const buttonStyle = {
  marginTop: '20px',
  width: '40%'
};
interface FormularioData {
  nombre: string;
  apellidoPaterno: string;
  apellidoMaterno: string;
  email: string;
  fechaNacimiento: string;
  direccion: string;
  fechaIngreso: string;
  planMensualTipo: string;
  planMensualMonto: string;
  role: string;
}


export const RegisterClients: React.FC = () => {


  const [activeStep, setActiveStep] = useState(0);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const clientsHandler = useSelector((state: RootState) => state?.clientsRegister);
  const timeZoneData = getTimeZoneFromUser()
  const { handleSelectChange, onInputChange, formState, onResetForm } = useForm({
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    email: '',
    fechaNacimiento: '',
    direccion: '',
    fechaIngreso: '',
    planMensualTipo: 'MENSUAL',
    planStatusActivo: 'true',
    planMensualMonto: '',
    phoneNumber : '',
    fechaInicioPlan : '',
    pagoDeInscripcion: '',
    timeZone: {
      identifier: timeZoneData.userTimeZone,
      offset: timeZoneData.userOffset
    },
    role: 'CLIENTE',
  })
  const { error, fieldFormatError, handleEmail, dateBorn, dateBornError, dateValidation } = useFormStateValidation(formState);

  /*
  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>
  ) => {
    const { name, value } = event.target;
    setFormulario({
      ...formulario,
      [name as string]: value as string,
    });
  }; */

  /* Srepper */
  const handleNextStep = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBackStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };




  useEffect(() => {
    const email = formState?.email 
    const dateBorn = formState?.fechaNacimiento
    handleEmail(email as string)
    dateValidation(dateBorn as string)
  }, [formState.email,formState.fechaNacimiento]);

  const handleSubmit = async () => {
    const success = await dispatch(startRegisterClientThunk(formState, navigate));
    if(success?.ok === true){
      onResetForm()
    }
  };

  //const steps = ['Datos Personales', 'Dirección', 'Detalles del Plan'];
  const steps = [
    'Datos Personales',
    'Datos de la empresa',
    'Datos bancarios',
    'Revisar y Registrar',
  ];

  return (
    <Paper style={paperStyle}>
      <Typography variant="h6" align="center" mb={3}>
        Registro de Clientes
      </Typography>
      <Stepper activeStep={activeStep} sx={{ display: 'flex', flexDirection: 'row', overflowX: 'auto', marginBottom: '3px' }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <form style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }} noValidate autoComplete="off">
        {activeStep === 0 && (
          <div>
            <TextField
              name="nombre"
              label="Nombre"
              value={formState?.nombre}
              onChange={onInputChange}
              style={textFieldStyle}
              fullWidth
              required
            />
            <TextField
              name="apellidoPaterno"
              label="Apellido Paterno"
              value={formState?.apellidoPaterno}
              onChange={onInputChange}
              style={textFieldStyle}
              fullWidth
            />
            <TextField
              name="apellidoMaterno"
              label="Apellido Materno"
              value={formState?.apellidoMaterno}
              onChange={onInputChange}
              style={textFieldStyle}
              fullWidth
            />
            <TextField
              name="email"
              label="Email"
              value={formState?.email}
              onChange={onInputChange}
              style={textFieldStyle}
              fullWidth
              required
              error={fieldFormatError}
              helperText={
                fieldFormatError ? error : ''
              }
            />
            <TextField
              name="fechaNacimiento"
              label="Fecha de Nacimiento"
              value={formState?.fechaNacimiento}
              onChange={onInputChange}
              type="date"
              style={textFieldStyle}
              fullWidth
              error={dateBornError}
              helperText={
                dateBornError && dateBorn
              }
              InputLabelProps={{
                shrink: true, // Ajusta la posición de la etiqueta
              }}
            />
          </div>
        )}
        {activeStep === 1 && (
          <div>
            <TextField
              name="direccion"
              label="Dirección"
              value={formState?.direccion}
              onChange={onInputChange}
              style={textFieldStyle}
              fullWidth
              required
            />
            <TextField
              name="phoneNumber"
              label="Número de teléfono"
              value={formState?.phoneNumber}
              onChange={onInputChange}
              style={textFieldStyle}
              fullWidth
              required
            />
          </div>
        )}
        {activeStep === 2 && (
          <div>
            <FormControl style={selectStyle} fullWidth>
              <InputLabel id="planMensual-label">Tipo de plan</InputLabel>
              <Select
                labelId="planMensual-label"
                name="planMensualTipo"
                value={formState?.planMensualTipo}
                onChange={handleSelectChange}
              >
                <MenuItem value="SEMANAL">Semanal</MenuItem>
                <MenuItem value="MENSUAL">Mensual</MenuItem>
                <MenuItem value="SEMESTRAL">Semestral</MenuItem>
                <MenuItem value="CUATRIMESTRAL">Cuatrimestral</MenuItem>
                <MenuItem value="ESPECIAL">Especial mensual</MenuItem>
              </Select>
            </FormControl>
            <TextField
              name="fechaInicioPlan"
              label="Fecha de inicio del plan"
              value={formState?.fechaInicioPlan}
              type="date"
              onChange={onInputChange}
              style={textFieldStyle}
              InputLabelProps={{
                shrink: true, // Ajusta la posición de la etiqueta
              }}
              fullWidth
            />
            <TextField
              name="planMensualMonto"
              label="Monto del Plan"
              value={formState?.planMensualMonto}
              onChange={onInputChange}
              style={textFieldStyle}
              fullWidth
            />
            <TextField
              name="pagoDeInscripcion"
              label="Pago de inscripción"
              value={formState?.pagoDeInscripcion}
              onChange={onInputChange}
              style={textFieldStyle}
              fullWidth
            />
            <FormControl style={selectStyle} fullWidth>
              <InputLabel id="activarPlan-label">Activar Plan de suscripción</InputLabel>
              <Select
                labelId="activarPlan-label"
                name="planStatusActivo"
                value={formState?.planStatusActivo}
                onChange={handleSelectChange}
              >
                <MenuItem value="true">Si</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}
        {activeStep === 3 && (
          <div style={{ marginTop: '10px' }}>
            <Typography variant="h6">Resumen del Formulario</Typography>
            <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '5px', marginBottom: '20px', width: '100%' }}>
                <Typography key="nombre" variant="body1">
                  {`Nombre: ${formState?.nombre}`}
                </Typography>
                <Typography key="apellidoPaterno" variant="body1">
                  {`Apellido Paterno: ${formState?.apellidoPaterno}`}
                </Typography>
                <Typography key="apellidoMaterno" variant="body1">
                  {`Apellido Materno: ${formState?.apellidoMaterno}`}
                </Typography>
                <Typography key="email" variant="body1">
                  {`Email: ${(fieldFormatError) ? <Chip color='error' label={error} /> : formState?.email}`}
                </Typography>
                <Typography key="fechaNacimiento" variant="body1">
                  {`Fecha de Nacimiento: ${formState?.fechaNacimiento}`}
                </Typography>
                <Typography key="direccion" variant="body1">
                  {`Dirección: ${formState?.direccion}`}
                </Typography>
                <Typography key="planMensualTipo" variant="body1">
                  {`Tipo de plan: ${formState?.planMensualTipo}`}
                </Typography>
                <Typography key="planStatusActivo" variant="body1">
                  {`Estado del plan: ${(formState?.planStatusActivo !== 'false') ? 'ACTIVO' : <Chip color='error' label='ATENCIÓN , FALTA ACTIVAR EL PLAN EN CAMPO ACTIVAR PLAN DE SUSCRIPCIÓN!!!' />}`}
                </Typography>
                <Typography key="planMensualMonto" variant="body1">
                  {`Monto del Plan: ${formState?.planMensualMonto}`}
                </Typography>
                <Typography key="pagoDeInscripcion" variant="body1">
                  {`Pago único de inscripción: ${formState?.pagoDeInscripcion}`}
                </Typography>
                <Typography key="role" variant="body1">
                  {`Role: ${formState?.role}`}
                </Typography>
                <Typography key="phoneNumber" variant="body1">
                  {`Número de teléfono: ${formState?.phoneNumber}`}
                </Typography>
                <Typography key="fechaInicioPlan" variant="body1">
                  {`Fecha de inicio del plan: ${formState?.fechaInicioPlan}`}
                </Typography>
            </div>
          </div>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {activeStep !== 0 && (
            <Button
              variant="contained"
              color="inherit"
              style={buttonStyle}
              onClick={handleBackStep}
            >
              Atrás
            </Button>
          )}
          {activeStep < steps.length - 1 && (
            <Button
              variant="contained"
              color="primary"
              style={buttonStyle}
              onClick={handleNextStep}
              disabled = {fieldFormatError && true}
            >
              Siguiente
            </Button>
          )}
          {activeStep === steps.length - 1 && (
            <Button
              variant="contained"
              color="primary"
              style={buttonStyle}
              onClick={handleSubmit}
              // disabled={(formState.planStatusActivo==='false' || fieldFormatError || error) && true}
              disabled={`${formState?.planStatusActivo === 'false' || fieldFormatError || error}`}
            >
              Enviar
            </Button>)
          }
        </div>
        {
          /** cliente */
          (clientsHandler.status === 'un-register-user') && <SnackBar severity='error' errorMsg={clientsHandler?.BackendErrorMessage}></SnackBar>
        }
        {
          /** cliente */
          (clientsHandler.status === 'registering') && <SnackBar severity='info' errorMsg='Registrando cliente...'></SnackBar>
        }
        {
          /** cliente */
          (clientsHandler.status === 'user-registered') && <SnackBar severity='success' errorMsg='Cliente registrado éxitosamente !!!'></SnackBar>
        }
      </form>
    </Paper>
  );
};
