import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line
} from 'recharts';

import { AppDispatch, RootState } from '../store';
import { getAdminTrafficReportsThunk } from '../store/usuarios';
import { numeroAMes } from '../constants/ConstantsFlexSolutionManager';
import type { MesesKeys } from '../types/types';

import { LoadingAtom } from '../atoms/LoadingAtom';
import Title from '../atoms/Title';



export default function Chart() {

  const dispatch = useDispatch<AppDispatch>();
  const data = useSelector((state: RootState) => state?.getUserAdminTrafficReportSlide?.traffic);
  const theme = useTheme();
  
  const dataForRecharts = data?.monthlyVisitors && Object.entries(data?.monthlyVisitors).map(([month, numVisitors]) => ({
    month: numeroAMes[month as MesesKeys],
    numVisitors,
  }));


  useEffect(()=>{
    dispatch(getAdminTrafficReportsThunk('six_monthly'));
  },[]);

  return (
    <React.Fragment>
      {
        data?.monthlyVisitors === undefined && !dataForRecharts ? <LoadingAtom /> : (
          <>
            <Title>{ data?.msg }</Title>
            <ResponsiveContainer>
              <LineChart
                data={ dataForRecharts }
                margin={ {
                  top: 16,
                  right: 16,
                  bottom: 0,
                  left: 0,
                } }
              >
                <XAxis
                  dataKey='month'
                  type='category'
                  name='Mes'
                  stroke={ theme.palette.text.secondary }
                  style={ theme.typography.body2 }
                />
                <YAxis
                  stroke={ theme.palette.text.secondary }
                  style={ theme.typography.body2 }
                />
                <Tooltip />
                <Line
                  type='monotone'
                  dataKey='numVisitors'
                  name='Visitantes'
                  dot={false}
                  stroke={ theme.palette.primary.main }
                />
              </LineChart>
            </ResponsiveContainer>
          </>
        )
      }
    </React.Fragment>
  );
}