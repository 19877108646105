import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { DateTime } from 'luxon';

import { usePagination } from '../customhooks/usePagination';
import { useUsersActions } from '../customhooks/useUsersActions';
import { SnackBar } from '../atoms/SnackBar';
import Title from '../atoms/Title';

import { PaginationAtom } from '../atoms/PaginationAtom';
import { LoadingAtom } from '../atoms/LoadingAtom';
import SearchBar from '../atoms/SearchBar';
import { Chip, Grid, Paper } from '@mui/material';
import { searchThunk } from '../store/search/thunk';
import EventosActions from '../atoms/EventosActions';



function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}
const headerCellStyle = {
  fontWeight: 'bold',
  minWidth: 180,
}

export const SearchEvent = React.memo(() => {
  //pagination
  const { page, usersPerPage, handleChangePage, startIndex, endIndex } = usePagination()
  const { handleEventEdit, EventDelete, handleDelete, handleRegisterUser, RederiqDetailsEvents } = useUsersActions();
  //globalState
  const searchResults = useSelector((state: RootState) => state?.searchResults);
  const backendError = useSelector((state: RootState) => state?.searchResults?.BackendErrorMessage);
  const { term, section } = useSelector((state: RootState) => state?.saveSearchTerm);
  //response failed
  let res = (searchResults.status === 'failed') ? <SnackBar status={searchResults?.status} severity='error' errorMsg={backendError} /> : (<TableRow><TableCell colSpan={10} align="center"><LoadingAtom /></TableCell></TableRow>)

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(searchThunk(section, term || ''));
  }, [term, section])

  return (
    <React.Fragment>
      <Grid item xs={12} md={12} lg={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflowX: 'auto', borderRadius: '0', marginBottom: '8px' }}>
          <Title>Buscando sobre eventos</Title>
          <SearchBar />
          {/* <div style={{width:'100%'}}>Total de resultados : {searchResults.totalResults}</div> */}
        </Paper>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={headerCellStyle}>Evento Uid</TableCell>
                <TableCell sx={headerCellStyle}>Nombre</TableCell>
                <TableCell sx={headerCellStyle}>Descripción</TableCell>
                <TableCell sx={headerCellStyle}>Fecha de inicio</TableCell>
                <TableCell sx={headerCellStyle}>Estado</TableCell>
                <TableCell sx={headerCellStyle}>Fecha fin</TableCell>
                <TableCell sx={headerCellStyle}>Cupo máximo</TableCell>
                <TableCell sx={headerCellStyle} align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchResults.status === 'success' ? (
                searchResults.results.slice(startIndex, endIndex).map((event) => (
                  <TableRow key={event?._id}>
                    <TableCell>{(!event?.estado) ? <Chip label={event?._id} color="error" variant="outlined" /> : <Chip label={event?._id} color="primary" variant="outlined" />}</TableCell>
                    <TableCell>{(!event?.estado) ? <Chip label={event?.nombre} color="error" variant="outlined" /> : event?.nombre}</TableCell>
                    <TableCell>{(!event?.estado) ? <Chip label={event?.descripcion} color="error" variant="outlined" /> : event?.descripcion}</TableCell>
                    <TableCell>{(!event?.estado) ? <Chip label={
                      DateTime.fromISO(event?.fecha_inicio).setZone(event?.timeZone?.identifier).setLocale('es').toLocaleString({
                        year: 'numeric',
                        month: 'long',
                        day: '2-digit'
                      })
                    } color="error" variant="outlined" /> : `${DateTime.fromISO(event?.fecha_inicio).setZone(event?.timeZone?.identifier).setLocale('es').toLocaleString({
                      year: 'numeric',
                      month: 'long',
                      day: '2-digit'
                    })
                    }`}</TableCell>
                    <TableCell>{(!event?.estado) ? <Chip label='EVENTO BORRADO' color="error" variant="outlined" /> : <Chip label='EVENTO ACTIVO' color="success" variant="outlined" />}</TableCell>
                    <TableCell>{(!event?.estado) ? <Chip label={                      
                      DateTime.fromISO(event?.fecha_fin).setZone(event?.timeZone?.identifier).setLocale('es').toLocaleString({
                        year: 'numeric',
                        month: 'long',
                        day: '2-digit'
                      })
                    } color="error" variant="outlined" /> : `${
                      DateTime.fromISO(event?.fecha_fin).setZone(event?.timeZone?.identifier).setLocale('es').toLocaleString({
                        year: 'numeric',
                        month: 'long',
                        day: '2-digit'
                      })
                    }`}</TableCell>
                    <TableCell>{(!event?.estado) ? <Chip label={event?.cupo_maximo} color="error" variant="outlined" /> : event?.cupo_maximo}</TableCell>
                    <TableCell align="right">
                      <EventosActions
                        onEdit={() => handleEventEdit(event?._id)}
                        onDelete={() => {
                          if (event?._id) {
                            EventDelete(event?._id);
                          }
                        }}
                        addAnUser={() => handleRegisterUser()}
                        details={() => RederiqDetailsEvents()}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : res}
            </TableBody>
          </Table>
          {/* <Pagination count={Math.ceil(searchResults.results.length / usersPerPage)} page={page} onChange={handleChangePage} color="primary" shape="rounded" style={{ marginTop: '10px'}}/> */}
          <PaginationAtom count={Math.ceil(searchResults.results.length / usersPerPage)} page={page} onChange={handleChangePage} color="primary" shape="rounded" style={{ marginTop: '10px' }} />

          {
            /** resultados de búsqueda */
            (searchResults.status === 'failed') && <SnackBar severity='error' errorMsg={searchResults.BackendErrorMessage}></SnackBar>
          }
          {
            (searchResults.totalResults === 0) && <SnackBar severity='warning' errorMsg='No se econtraron resultados para esta búsqueda !'></SnackBar>
          }
          {
            (searchResults.status === 'in-progress') && <SnackBar severity='info' errorMsg='buscando, apreciamos tu paciencia...'></SnackBar>
          }
          {
            (searchResults.status === 'success') && <SnackBar severity='success' errorMsg={searchResults.msg}></SnackBar>
          }
        </Paper>
      </Grid>
    </React.Fragment >
  );
})