import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { getManagementService } from '../store/slides/Auth/index'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Title from './Title';

import { DateTime } from 'luxon';

import { Grid, Paper } from '@mui/material';
import { usePagination } from '../customhooks/usePagination';
import { useUsersActions } from '../customhooks/useUsersActions';
import UserActionsMenu from './UserActionsMenu';
import { PaginationAtom } from './PaginationAtom';
import { SnackBar } from './SnackBar';
import { LoadingAtom } from './LoadingAtom';
import { Notifications } from './Notifications';
import { SUPER_ADMIN } from '../interfaces/interfaces';

// import { usePagination } from '../customhooks/usePagination';
// import { PaginationAtom } from './PaginationAtom';
// import UserActionsMenu from './UserActionsMenu';
// import { LoadingAtom } from './LoadingAtom';
// import { useUsersActions } from '../customhooks/useUsersActions';
// import { SnackBar } from './SnackBar';

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}
const headerCellStyle = {
  fontWeight: 'bold',
  minWidth: 150,
}

export const ActivosTenantsManagementRole = React.memo(() => {
  //pagination
  const { page, usersPerPage, handleChangePage, startIndex, endIndex } = usePagination();
  const { handleConfirmDelete, handleRenewMembership , handlUserEdit, handleOpenModal } = useUsersActions();
  //globalState
  const renewMembershipHandler = useSelector((state: RootState) => state?.renewMembership)
  const managementService = useSelector((state: RootState) => state?.managementService);
  const backendError = useSelector((state: RootState) => state?.managementService?.BackendErrorMessage);
  const handleEditUsr = useSelector((state: RootState) => state?.editUser);

  let res = (managementService.status === 'failed') ? <SnackBar status={managementService?.status} severity='error' errorMsg={backendError} /> : (<TableRow><TableCell colSpan={10} align="center"><LoadingAtom /></TableCell></TableRow>)
  const usersHandler = useSelector((state: RootState) => state?.deletedUsers);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getManagementService());
  }, [])

  return (
    <React.Fragment>
      <Title>Inquilinos Activos en el sistema</Title>
      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell sx={headerCellStyle}>Uid</TableCell>
            <TableCell sx={headerCellStyle}>Nombre de la empresa</TableCell>
            <TableCell sx={headerCellStyle}>Tipo de plan</TableCell>
            <TableCell sx={headerCellStyle}>Rol</TableCell>
            <TableCell sx={headerCellStyle}>Fecha inicio del plan</TableCell>
            <TableCell sx={headerCellStyle}>Fecha de expiración del plan</TableCell>
            <TableCell sx={headerCellStyle}>Fechanacimiento</TableCell>
            <TableCell sx={headerCellStyle}>Estatus</TableCell>
            <TableCell sx={headerCellStyle}>Licencia</TableCell>
            <TableCell sx={headerCellStyle}>Email</TableCell>
            <TableCell sx={headerCellStyle}>Direccion</TableCell>
            <TableCell sx={headerCellStyle} align="right">Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {managementService?.status === 'success' ? (
            managementService?.usuarios?.slice(startIndex, endIndex).filter(( user : SUPER_ADMIN ) => (user?.planStatusActivo === true && user?.estado === true)).map((user : SUPER_ADMIN ) => (
              <TableRow key={user?.uid}>
                <TableCell>{user?.uid}</TableCell>
                <TableCell>{user?.nombreEmpresa}</TableCell>
                <TableCell>{user?.planMensualTipo}</TableCell>
                <TableCell>{user?.role}</TableCell>
                <TableCell>{                  
                  DateTime.fromISO(user?.fechaInicioPlan as string).setZone(user?.timeZone?.identifier).setLocale('es').toLocaleString({
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit'
                  })
                }</TableCell>
                <TableCell>{                  
                  DateTime.fromISO(user?.fechaExpiracion as string).setZone(user?.timeZone?.identifier).setLocale('es').toLocaleString({
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit'
                  })
                }</TableCell>              
                <TableCell>{
                  DateTime.fromISO(user?.fechaNacimiento as string).setZone(user?.timeZone?.identifier).setLocale('es').toLocaleString({
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit'
                  })
                }</TableCell>
                <TableCell>{(user?.estado !== false) ? 'ACTIVO' : 'ELIMINADO'}</TableCell>
                {/* <TableCell>{(user.planMensualTipo !== undefined) ? `${user.planMensualTipo}` : 'No aplica'}</TableCell> */}
                <TableCell>{user?.licencia}</TableCell>
                <TableCell>{user?.email}</TableCell>
                <TableCell>{user?.direccion}</TableCell>
                <TableCell align="right">
                  <UserActionsMenu
                    onEdit={ () =>  handlUserEdit( user?.uid as string, user?.role as string) }
                    onDelete={() => handleConfirmDelete(user?.uid)}
                    onRenewMembership={() => handleOpenModal(user?.uid as string)}
                  />
                </TableCell>
              </TableRow>
            ))
          ) : res}
          {/* <h2>{backendError}</h2> */}
        </TableBody>
      </Table>
      {/* <Pagination count={Math.ceil(clientUsers.usuarios.length / usersPerPage)} page={page} onChange={handleChangePage} color="primary" shape="rounded" style={{ marginTop: '10px'}}/> */}
      <PaginationAtom count={Math.ceil(managementService.usuarios.length / usersPerPage)} page={page} onChange={handleChangePage} color="primary" shape="rounded" style={{ marginTop: '10px' }} />


      <Notifications handler={usersHandler} actionName='usuario' actionType='Borrando' />
      <Notifications handler={renewMembershipHandler} actionName='membresía' actionType='Renovando' />
      <Notifications handler={ handleEditUsr } actionName='editarUsuario' actionType='errores' />
    </React.Fragment>
  );
})