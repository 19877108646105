import { createSlice } from '@reduxjs/toolkit';
import { EVENTS } from '../../../interfaces/interfaces';



export const deleteEventSlice = createSlice({
    name : "eventos",
    initialState : {
        status : 'normal', 
        msg : null,
        eventos : [] as EVENTS[],
        BackendErrorMessage : null
    },
    reducers : {
        faileddeleted: ( state, { payload } ) => {
            state.status = 'failed'; 
            state.eventos = [];
            state.BackendErrorMessage = payload;
        },
        deletedEventById: ( state, { payload } ) => {
            state.status = 'success'; 
            state.msg = payload.msg; 
            state.eventos = payload.eventos;
            state.BackendErrorMessage = null;
        },
        delitingEvent : ( state )=>{
            state.status = 'in-progress'
        }
    }
});

export const { faileddeleted,deletedEventById,delitingEvent} = deleteEventSlice.actions;