import { createSlice } from '@reduxjs/toolkit';

export const postEventSlide = createSlice({
    name : "postEvent",
    initialState : {
        status : 'initial-state',
        msg : null,
        nombre : null,
        descripcion:null,
        fecha_inicio: null,
        fecha_fin: null,
        cupo_maximo:null,
        BackendErrorMessage : null
    },
    reducers : {
        failedCreateEvent: ( state, { payload } ) => {
            state.status = 'failed'; 
            state.msg = null;
            state.nombre = null;
            state.descripcion = null;
            state.fecha_inicio = null;
            state.fecha_fin = null;
            state.cupo_maximo = null;
            state.BackendErrorMessage = payload;
        },
        eventCreated: ( state, { payload } ) => {
            state.status = 'success';
            state.msg = payload.msg;
            state.nombre = payload.nombre;
            state.descripcion = payload.descripcion;
            state.fecha_inicio = payload.fecha_inicio;
            state.fecha_fin = payload.fecha_fin;
            state.cupo_maximo = payload.cupo_maximo;
            state.BackendErrorMessage = null;
        },
        creatingEvent : ( state )=>{
            state.status = 'in-progress';
        }
    }
});

export const { failedCreateEvent,eventCreated,creatingEvent } = postEventSlide.actions;