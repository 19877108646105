import React from 'react';
import { useRouteError } from 'react-router-dom';

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);
  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>La ruta que estàs buscando no existe !</p>
      <p>
      {/* <i>{error }</i> */}
      </p>
    </div>
  );
}
