import { Box, Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { FormEvent } from "react"

import { useForm } from "../customhooks"

import { FormChangeLicenseProps } from "../interfaces/interfaces"

import { AppDispatch } from "../store"
import { useDispatch } from "react-redux"
import { changeLicenseThunk } from "../store/usuarios"
import { openModal } from "../store/modal"
import { config } from "../config"

const FormChangeLicense = ({ role }: FormChangeLicenseProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const selectOptions = role === 'MANAGEMENT-SERVICE-ROLE' ?
    ["Mensual", "Semestral", "Anual"] :
    [ "Mensual", "Semestral", "Anual" ];

  const { formState, onInputChange, onResetForm } = useForm({
    licencia: '',
    planMensualTipo: ''
  })

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const useResetForm = await dispatch(changeLicenseThunk(formState as any));
    if (useResetForm?.ok === true) {
      onResetForm();
      dispatch({
        type: openModal?.type,
        payload: config.MODAL_TYPE_EDIT_USER
      })

    }
  }

  return (
    <form onSubmit={ (e) => { handleSubmit(e) } } style={ { display: 'flex', flexWrap: 'wrap', flexDirection: 'column', width: '100%' } }>
      <Box display='flex' flexDirection='column' gap={3}>
        <FormControl fullWidth>
          <InputLabel id="licenciaLabel">Licencia</InputLabel>
          <Select
            labelId="licenciaLabel"
            id="demo-simple-select"
            value={ formState.licencia }
            label="Licencia"
            name="licencia"
            onChange={ onInputChange as any }
            required
          >
            <MenuItem value={ 'BASICO' }>Básico</MenuItem>
            <MenuItem value={ 'ESENCIAL' }>Esencial</MenuItem>
            <MenuItem value={ 'ELITE' }>Elite</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="planmensualtipo"> Tipo de plan </InputLabel>
          <Select
            labelId="planmensualtipo"
            id="demo-simple-select"
            value={ formState.planMensualTipo }
            label="Plan mensual tipo"
            name="planMensualTipo"
            onChange={ onInputChange as any }
            required
          >
            { selectOptions.map(option => (
              <MenuItem key={ option } value={ option.toUpperCase() }>{ option }</MenuItem>
            )) }
          </Select>
        </FormControl>

        <Box display='flex' justifyContent='flex-end'>
          <Button
            color="primary"
            type='submit'
          >
            Actualizar
          </Button>
        </Box>
      </Box>
    </form>
  )
}

export default FormChangeLicense