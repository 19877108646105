import { createSlice } from '@reduxjs/toolkit';
import { EVENTS } from '../../../interfaces/interfaces';



export const getEventSlice = createSlice({
    name : "eventos",
    initialState : {
        status : 'normal', 
        msg : null,
        total : null,
        eventos : [] as EVENTS[],
        BackendErrorMessage : null
    },
    reducers : {
        failedgettingEvents: ( state, { payload } ) => {
            state.status = 'failed'; 
            state.eventos = [];
            state.BackendErrorMessage = payload;
        },
        getAllEvents: ( state, { payload } ) => {
            state.status = 'success'; 
            state.msg = payload.msg; 
            state.total = payload.total;
            state.eventos = payload.eventos;
            state.BackendErrorMessage = null;
        },
        gettingEvents : ( state )=>{
            state.status = 'in-progress'
        }
    }
});

export const { failedgettingEvents,getAllEvents,gettingEvents} = getEventSlice.actions;