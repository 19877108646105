import { FormEvent, useEffect } from 'react';

import { FormProductUpdateProps } from '../interfaces/interfaces';
import { useForm } from '../customhooks/useForm';

import Title from '../atoms/Title';
import { Box, Button, Modal, TextField, Select, FormControl, InputLabel, MenuItem } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { startProductUpdateThunk } from '../store/productos/index';
import { AppDispatch, RootState } from '../store/store';
import { closeModal } from '../store/modal';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,
};

const textFieldStyle = {
  marginBottom: '20px',
  marginTop: '10px',
  width: '100%'
};

export const FormProductUpdate = ({ uidProduct, modalType }: FormProductUpdateProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const open = useSelector((state: RootState) => state?.modal?.openModal);
  const currentModalType = useSelector((state: RootState) => state?.modal?.modalType)
  const producById = useSelector((state: RootState) => state?.productById?.producto);
  const categories = useSelector((state: RootState) => state?.categories?.categorias);
  
  const { onInputChange, formState, onResetForm } = useForm({
    nombre: producById?.nombre,
    categoria: producById?.categoria,
    descripcion: producById?.descripcion,
    precio: producById?.precio,
    stock: producById?.stock,
  });

  useEffect(() => {
    if (producById) {
      onResetForm();
    }
  }, [producById])
  
  const handleClose = () => {
    dispatch(closeModal());
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const useToResetForm = await dispatch(startProductUpdateThunk(formState, uidProduct));
    if (useToResetForm?.ok === true) {
      onResetForm();
      handleClose();
    }
  };

  return (
    <>
      {
        modalType === currentModalType && (
          <Modal open={ open } onClose={ handleClose } aria-labelledby="Actualizar producto" aria-describedby="edita un producto">
            <form onSubmit={ handleSubmit } style={ { display: 'flex', flexWrap: 'wrap', flexDirection: 'column', width: '100%' } }>
              <Box sx={ style }>
                <Title>Actualizar datos de un producto</Title>

                <TextField
                  fullWidth
                  label="Nombre del Producto"
                  name='nombre'
                  value={formState?.nombre}
                  onChange={onInputChange}
                  variant="outlined"
                  style={textFieldStyle}
                  required
                />
                <FormControl fullWidth>
                  <InputLabel id="categoria">Categoría</InputLabel>
                  <Select
                    labelId="categoria"
                    id="categoria"
                    defaultValue={ formState?.categoria }
                    value={ formState?.categoria }
                    label="Categoria"
                    name="categoria"
                    onChange={ onInputChange as any }
                    required
                  >
                    { categories.map(category => (
                      <MenuItem key={ category._id } value={ category._id }>{ category.nombre }</MenuItem>
                    )) }
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  label="Descripción"
                  name="descripcion"
                  value={formState?.descripcion}
                  onChange={onInputChange}
                  variant="outlined"
                  multiline
                  rows={4}
                  style={textFieldStyle}
                  required
                />
                <TextField
                  fullWidth
                  label="Precio"
                  name="precio"
                  value={formState?.precio}
                  onChange={onInputChange}
                  variant="outlined"
                  type="number"
                  style={textFieldStyle}
                  required
                />
                <TextField
                  fullWidth
                  label="Stock"
                  name="stock"
                  value={formState?.stock}
                  onChange={onInputChange}
                  variant="outlined"
                  type="number"
                  style={textFieldStyle}
                  required
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  style={textFieldStyle}
                  fullWidth
                >
                  ACTUALIZAR
                </Button>
              </Box>
            </form>
          </Modal>
        )
      }
    </>
  );
}
