import { createSlice } from '@reduxjs/toolkit';
import { CLIENTS } from '../../interfaces/interfaces';

export const Clients = createSlice({
    name : "getClients",
    initialState : {
        status : 'normal', 
        msg : null,
        usuarios : [] as CLIENTS[],
        BackendErrorMessage : null
    },
    reducers : {
        failed: ( state, { payload } ) => {
            state.status = 'failed'; 
            state.usuarios = [];
            state.BackendErrorMessage = payload;
        },
        getClientSuccess: ( state, { payload } ) => {
            state.status = 'success';
            state.usuarios = payload.usuarios;
            state.BackendErrorMessage = null;
        },
        inProgress : ( state )=>{
            state.status = 'in-progress'
        }
    }
});

export const { failed, getClientSuccess, inProgress } = Clients.actions;