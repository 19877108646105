import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

const purgeReducer = createSlice({
  name: 'purge',
  initialState: {},
  reducers: {
    purgeState: (state, action) => {
      if (action.type === PURGE) {
        return {}; // Limpia el estado persistido
      }
      return state;
    },
  },
});

export const { purgeState } = purgeReducer.actions;
export default purgeReducer.reducer;
