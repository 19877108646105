import { Box, Typography, useMediaQuery } from "@mui/material";

import type { INFOBOX_PROPS } from "../interfaces/interfaces";

export const InfoLabelWithTitle = ({ title, value }: INFOBOX_PROPS) => {
  const mobile = useMediaQuery("(max-width: 425px)")

  return (
    <Box display={ mobile ? "block" : "flex" } alignItems={ "center" } gap={ 1 }>
      <Typography fontWeight={ "bold" } variant="subtitle1">{ title }: </Typography>
      <label>{ value }</label>
    </Box>
  )
}