
import axios, { AxiosResponse } from 'axios';
import { AnyRecord } from 'dns';
import { config } from '../../config';
import { PAY_TENANT_SUSCRIPTION } from '../../interfaces/interfaces';
import { stagingController } from '../../utils/stagingController';
import { developmentConfig } from '../../developmentConfig';

let host = stagingController();

export const getSalesRecordsProvider = async ( timeStamp : string )=>{
    let xToken = localStorage.getItem('token');
    const headers = {
        'x-token': xToken,
        'Content-Type': 'application/json',
    };
    return await axios.get(`${(host?.localhost)? developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/billing/sales/details/${timeStamp}`, { headers })
        .then((response:AxiosResponse) => {            
            const data = response?.data;
            return {
                ok: true,
                data
            };
        })
        .catch((error) =>{            
            console.error(error)
            return {
                ok: false,
                error : error?.response?.data?.error
            };
            
        }) 
}


export const startTenantPayment = async ( { id , licencia , planMensualTipo , custumerEmail , nombreEmpresa , origin } : PAY_TENANT_SUSCRIPTION)=>{
    let xToken = localStorage.getItem('token');
    const headers = {
        'x-token': xToken,
        'Content-Type': 'application/json',
    };
    const data = {
        id,        
        licencia,
        planMensualTipo,
        custumerEmail,
        nombreEmpresa,
        origin
    }
    return await axios.post(`${(host?.localhost) ? developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/wallet/payment`, JSON.stringify( data ),{ headers })
        .then((response:AxiosResponse) => {            
            const data = response?.data;
            return {
                ok: true,
                data
            };
        })
        .catch((error) =>{            
            if(error?.response?.data?.error?.payment_intent?.last_payment_error?.message){
                return {
                    ok: false,
                    error : error?.response?.data?.error?.payment_intent?.last_payment_error?.message
                };
            }else {
                return {
                    ok: false,
                    error : error?.response?.data?.error
                };
            }
            
        }) 
}