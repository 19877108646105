import { useEffect } from 'react';
import facebook from '../img/facebook.png';
import instagram from '../img/instagram.png';
import LogoImage from '../img/LOGO.png';
import { stagingController } from '../utils/stagingController';
import { developmentConfig } from '../developmentConfig';
import { config } from '../config';

export const HomeFooter = () => {
    let volcanoWebUrl;
    useEffect(() => {
        let host = stagingController()
        host?.localhost ? volcanoWebUrl = developmentConfig?.REACT_APP_DEV_VOLCANOTECH_URL : volcanoWebUrl = config?.REACT_APP_PROD_VOLCANOTECH_URL
    }, [])
    return (
        <div className="w-full bg-lightBlue py-12 mt-10">
            <div className="container mx-auto grid grid-cols-4 sm:grid-cols-1 md:grid-cols-4 gap-6 px-6 lg:px-16">
                <div className="flex justify-center items-center  md:justify-start">
                    <img src={LogoImage} alt="Logo" className=" w-6/12 sm:w-40" />
                    {/* <img src={LogoImage} alt="Logo" className="w-1/2 md:w-3/12" /> */}
                </div>
                <div className="flex flex-col items-center md:items-start">
                    <p className="font-semibold text-white mb-3">Acerca de nosotros</p>
                    <a href={`${volcanoWebUrl}`} className="text-white text-sm mb-2 hover:text-lightBlue transition-colors duration-300">Volcano Web</a>
                    <a href="#" className="text-white text-sm hover:text-lightBlue transition-colors duration-300">Nuestro producto</a>
                </div>
                <div className="flex flex-col items-center md:items-start">
                    <p className="font-semibold text-white mb-3">Contacto</p>
                    <p className="text-white text-sm mb-2"> info@volcanoc.com </p>
                    <p className="text-white text-sm mb-2">+52 3123104335</p>
                    <p className="text-white text-sm mb-2">+52 312 1688721</p>
                    <p className="text-white text-sm mb-2">+52 312 1136190</p>
                </div>
                <div className="flex flex-col items-center md:items-start">
                    <p className="font-semibold text-white mb-3">Redes sociales</p>
                    <div className="flex justify-center md:justify-start">
                        <a href="https://www.facebook.com/profile.php?id=100092267210913" className="mr-4">
                            <img src={facebook} className="w-8 h-8" alt="Facebook" />
                        </a>
                        {/* <a href="#">
                            <img src={instagram} className="w-8 h-8" alt="Instagram" />
                        </a> */}
                    </div>
                </div>
            </div>
        </div>
    );
}
