import { createSlice } from '@reduxjs/toolkit';
import { CATEGORIAS } from '../../../interfaces/interfaces';



export const getCategoriesByIdSlice = createSlice({
    name : "categoriasById",
    initialState : {
        status : 'normal', 
        total : null,
        categorias : [] as CATEGORIAS[],
        BackendErrorMessage : null
    },
    reducers : {
        failedgettingCategoriasById: ( state, { payload } ) => {
            state.status = 'failed'; 
            state.categorias = [];
            state.BackendErrorMessage = payload;
        },
        getSpecificCategoria: ( state, { payload } ) => {
            state.status = 'success';
            state.total = payload.total; 
            state.categorias = payload.categorias;
            state.BackendErrorMessage = null;
        },
        gettingCategoryById : ( state )=>{
            state.status = 'in-progress...'
        }
    }
});

export const { failedgettingCategoriasById,getSpecificCategoria,gettingCategoryById} = getCategoriesByIdSlice.actions;