import { createSlice } from '@reduxjs/toolkit';

export const sellProductSlice = createSlice({
  name: "sellProduct",
  initialState: {
    status: 'initial-state',
    msg: null,
    serviceIdentifier: null,
    product: null,
    quantity: null,
    unitPrice: null,
    amount: null,
    BackendErrorMessage: null
  },
  reducers: {
    failedProductSale: (state, { payload }) => {
      state.status = 'failed';
      state.msg = null;
      state.serviceIdentifier = null;
      state.product = null;
      state.quantity = null;
      state.unitPrice = null;
      state.amount = null;
      state.BackendErrorMessage = payload;
    },
    productSold: (state, { payload }) => {
      state.status = 'success';
      state.msg = payload.msg;
      state.serviceIdentifier = payload.serviceIdentifier;
      state.product = payload.product;
      state.quantity = payload.quantity;
      state.unitPrice = payload.unitPrice;
      state.amount = payload.amount;
      state.BackendErrorMessage = null;
    },
    sellingProduct: (state) => {
      state.status = 'in-progress';
    }
  }
});

export const { failedProductSale, productSold, sellingProduct } = sellProductSlice?.actions;