
export const getTimeZoneFromUser=()=>{
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
    const userOffset = new Date().getTimezoneOffset();

    return {
        userTimeZone,
        userOffset
    }
}
export const getTimeZoneFromProduct=()=>{
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;    
    const userOffset = new Date().getTimezoneOffset();    
    return {
        userTimeZone,
        userOffset
    }
}
