/** permite el análisis a tenants y roles de mantenimiento sobre ventas 
 * Realiza solicitud de reportes de ventas al backend del tiempo más largo deseado ver en el dashboard
 * Suma la cantidad de dinero generada en el último mes y
 * realiza al igual una consulta mensual para filtrar las ventas por día de los últimos 7 días
 */ 

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';

import { useTheme } from '@mui/material/styles';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

import { DateTime } from 'luxon';

import Title from '../atoms/Title';

import { getSalesRecordsThunk } from '../store/sales/index';
import { SALES_RECORDS } from '../interfaces/interfaces';
import { Typography } from '@mui/material';

const createData = (time: string, total?: number) => {
  return { time, total: total !== undefined ? parseFloat(total.toString()) : 'N/A' };
}
export default function ChartSuperAdmin() {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const startDateLastWeek = new Date();
  startDateLastWeek.setDate(startDateLastWeek.getDate() - 7);

  React.useEffect(() => {
    /**como hay dos estados que se renderizan en el dashboard para ventas al mismo tiempo
     * hay que estandarizar siempre usar el termino de búsqueda más largo que permite el backend y
     * hacer el filtrado aquí de los días que se requiera hacer el analísis
     * con esto se evita visualizar data erronea .
     */
    dispatch(getSalesRecordsThunk('mensual')); 
  }, []);

  const salesRecord : SALES_RECORDS[] = useSelector((state: RootState) => state?.getSalesRecords.sales);
  
  /** en caso de que el store tenga ventas que no sean de los últimos 7 días 
   * obligamos a filtrar las de las últimas semana siempre
   */
  const salesLast7Days = salesRecord.filter(sale => {
    const saleDate = new Date(sale.saleDate);
    return saleDate >= startDateLastWeek;
  });

  // Sumar las ventas por día
  const dailySales: Record<string, number> = salesLast7Days.reduce((acc, sale) => {
    const saleDate = DateTime.fromISO(sale.saleDate).setLocale('es').toFormat('cccc'); // Obtener el día de la semana
    acc[saleDate] = (acc[saleDate] || 0) + sale.amount;
    return acc;
  }, {});

  // Crear los datos para el gráfico
  const chartData = Object.keys(dailySales).map(day => createData(day, dailySales[day]));
  
  return (
    <React.Fragment>
      <Title> Reporte de ventas semanal </Title>
      {Object.keys(dailySales).length === 0 ? (
        <Typography variant="body2">
          Aún no hay reportes de ventas disponibles.
        </Typography>
      ) : (

        <ResponsiveContainer>
          <BarChart
            data={chartData}
            margin={{
              top: 16,
              right: 16,
              bottom: 0,
              left: 24,
            }}
          >
            <XAxis
              dataKey="time"
              stroke={theme.palette.text.secondary}
              style={theme.typography.body2}
            />
            <YAxis
              stroke={theme.palette.text.secondary}
              style={theme.typography.body2}
            />
            <Tooltip />
            <Bar
              dataKey="total"
              fill={theme.palette.primary.main}
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </React.Fragment>
  );
}
