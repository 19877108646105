import { TOOL_ITEM } from "../interfaces/interfaces"

export const HomeToolsItem = ({image, title, text}:TOOL_ITEM)=>{
    return(
        <div className="flex flex-col items-center w-3/12 sm:w-full sm:mb-14 md:w-full md:gap-2 md:mb-4">
            <div className="w-32 h-32 mb-2 bg-mainBlue rounded-full flex justify-center items-center slow-bounce animate-smallJump shadow-xl">
                <img className="fill-white w-6/12" src={image} alt="" />
            </div>
            <p className="text-center mt-2 font-semibold text-mainBlue">{title}</p>
            <p className="text-center mt-2 font-light w-10/12 text-lightGray">{text}</p>
        </div>
    )
}
