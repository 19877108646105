import React, { FormEvent, useState } from 'react'

import Title from '../atoms/Title';
import { Box, Button, Modal, TextField } from '@mui/material';

import { closeModal } from '../store/modal';
import { AppDispatch, RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { updateCategoryThunk } from '../store/categorias';

const style = {
  display: 'flex',
  gap: '10px',
  flexDirection: 'column',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,

};

const btnStyle = {
  minWidth: '100px',
  fontSize: '13px',
}

interface FormCategoriasUpdateProps {
  categoryID: string;
}

export const FormCategoriasUpdate = ({ categoryID }: FormCategoriasUpdateProps) => {
  const [categoryName, setCategoryName] = useState<string>('');

  const open = useSelector((state: RootState) => state.modal.openModal);
  const dispatch = useDispatch<AppDispatch>();

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const categoryNameSplited = event.target.value.split(' ').join('-');
    setCategoryName( categoryNameSplited );
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const useToResetForm = await dispatch(updateCategoryThunk(categoryID, categoryName))

    if (useToResetForm?.ok === true) {
      setCategoryName('');
      dispatch(closeModal());
    }
  }


  return (
    <>
      <Modal open={open} onClose={handleClose} aria-labelledby="Crear producto" aria-describedby="añade un producto a stock">
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', width: '100%' }}>
          <Box sx={style}>
            <Title>Editar categoría</Title>
            
            <TextField
              label="Nombre de la categoría"
              name="nombre"
              value={categoryName}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />

            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Button
                variant="outlined"
                color="error"
                onClick={handleClose}
                style={btnStyle}
              >
                Cancelar
              </Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                style={btnStyle}
              >
                Actualizar
              </Button>
            </Box>
          </Box>
        </form>
      </Modal>
    </>
  )
}
