import { createSlice } from '@reduxjs/toolkit';

export const updateCategorySlice = createSlice({
    name : "category-update",
    initialState : {
        status : 'initial-state',
        msg : null,
        nombre : null,
        BackendErrorMessage : null
    },
    reducers : {
        failedUpdateCategory: ( state, { payload } ) => {
            state.status = 'failed'; 
            state.msg = null;
            state.nombre = null;
            state.BackendErrorMessage = payload;
        },
        categoryUpdated: ( state, { payload } ) => {
            state.status = 'success'; // 'checking', 'not-authenticated', 'authenticated'
            state.msg = payload.msg;
            state.nombre = payload.nombre;
            state.BackendErrorMessage = null;
        },
        updatingCategory: ( state )=>{
            state.status = 'in-progress';
        }
    }
});

export const { failedUpdateCategory, categoryUpdated, updatingCategory } = updateCategorySlice.actions;