import { useState } from "react";

import { Link } from 'react-router-dom';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { NavSections } from "../interfaces/interfaces";

interface CollapseMenuProps {
  section: NavSections
}

const CollapseMenu = ({ section }: CollapseMenuProps) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <>
      <ListItemButton
        onClick={ handleClick }
        style={ {
          textDecoration: 'none',
          color: 'inherit',
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
        } }
      >
        <ListItemIcon>
          <section.icon />
        </ListItemIcon>
        <ListItemText primary={ section?.title } />
        { open ? <ExpandLess /> : <ExpandMore /> }
      </ListItemButton>
      <Collapse in={ open } timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {
            section?.subSection?.map((subsection, index) => (
              <ListItemButton
                key={ index }
                style={ {
                  marginLeft: '14px',
                  textDecoration: 'none',
                  color: 'inherit',
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
                } }
                component={ Link }
                to={ subsection?.path }
              >
                <ListItemIcon>
                  <subsection.icon />
                </ListItemIcon>
                <ListItemText primary={ subsection?.title } />
              </ListItemButton>
            ))
          }
        </List>
      </Collapse>
    </>
  )
}

export default CollapseMenu;