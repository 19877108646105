interface IDEAL_FOR_ITEM {
    title: string,
    icon: string,
    description : string
}
export const IdealForItem = ({ title, icon, description }: IDEAL_FOR_ITEM) => {
    return (
        <div className="flex flex-col items-center justify-center bg-white rounded-lg shadow-md p-6 transition-transform duration-300 ease-in-out transform hover:shadow-lg">
            <div className="text-5xl mb-4">{icon}</div>
            <p className="text-xl font-semibold mb-2">{title}</p>
            <p className="text-sm text-center">{description}</p>
        </div>
    );
}