import React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ClientsTable } from '../../atoms/ClientsTable';
import { ActivosUsuariosComponent } from '../../atoms/ActivosUsuariosComponent';
import { TenantsActivos } from '../../atoms/TenantsActivos';
import { FormReNewMembership } from '../../components/FormReNewMembership';

export const MutipleDashboards = () => {
  const role = useSelector((state: RootState) => state.auth.role);

  return (
    <>
      <FormReNewMembership />
      { role === 'ADMIN' && <ClientsTable /> }
      { role === 'SUPER_ADMIN' && <ActivosUsuariosComponent /> }
      { role === 'MANAGEMENT-SERVICE-ROLE' && <TenantsActivos /> }
      { role === 'ADMIN' && role === 'SUPER_ADMIN' && role === 'MANAGEMENT-SERVICE-ROLE' && <div>No se encontró un rol válido.</div> }
    </>
  )
}
