import LogoImage from '../img/LOGO.png';
export const HomeNavBar = ()=>{
    return (
        <div className="w-full h-20 top-0 sticky bg-mainBlue flex justify-between items-center shadow-xl z-20 px-6 md:px-10">
            <div className="flex items-center">
                <img src={LogoImage} alt="Logo" className="w-20 md:w-20 mr-4 sm:w-12" />
                <a
                    href="https://volcano-web-b2b6a3fa3d9b.herokuapp.com/#Home"
                    target="_blank"
                    className="text-white font-semibold mr-8 hover:text-lightBlue transition-colors duration-300"
                >
                    Volcano Web
                </a>
                <a
                    href="/plans"
                    className="text-white font-semibold mr-8 hover:text-lightBlue transition-colors duration-300"
                >
                    Planes
                </a>
            </div>
            <div>
                <a
                    href="/login"
                    className="text-white font-base bg-lightBlue px-6 py-2 rounded-full hover:bg-lightBlueHover transition-colors duration-300"
                >
                    Inicia Sesión
                </a>
            </div>
        </div>
    )
}
