import { ApiResponse } from '../../types/types';
import { AppDispatch } from '../store';
import { POST_PRODUCT } from '../../interfaces/interfaces';
import { createProductProvider, deleteProductsProvider, sellProductProvider, updateProductProvider } from './provider';
import {
  failedgettingProductos,
  getAllProductos,
  gettingProductos,
  getProductosProvider,
  getProductByIdProvider,
  getProductById,
  gettingProductById,
  failedGetProductById,
  failedCreateProduct,
  productCreated,
  creatingProduct,
  delitingProduct,
  deletedProductById,
  faileddeletedproducts,
  failedUpdateProduct,
  productUpdated,
  updatingProduct,
  productSold,
  sellingProduct,
  failedProductSale
} from './index'

export const getAllProductosThunk = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(gettingProductos())
    try {
      const res: ApiResponse = await getProductosProvider();

      if (res?.ok) {
        const { data } = res;
        dispatch(getAllProductos(data))
      } else {
        dispatch(failedgettingProductos(res?.error))        
      }
    } catch (error) {
      console.error(error);
      dispatch(failedgettingProductos(error))      
    }

  }
}

export const getProductByIdThunk = (uid: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(gettingProductById())
    try {
      const res: ApiResponse = await getProductByIdProvider(uid);

      if (res?.ok) {
        const { data } = res;
        dispatch(getProductById(data))
      } else {
        dispatch(failedGetProductById(res?.error))        
      }
    } catch (error) {
      console.error(error);
      dispatch(failedGetProductById(error))      
    }
  }
}


export const createProductThunk = (formState: POST_PRODUCT) => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: creatingProduct.type
    })
    try {
      const res: ApiResponse = await createProductProvider(formState);

      if (res?.ok) {
        const { data } = res;
        dispatch({
          type: productCreated.type,
          payload: data
        })
        return {
          ok: true
        }
      } else {
        dispatch({
          type: failedCreateProduct.type,
          payload: res?.error
        })
        return {
          ok: false
        }
      }
    } catch (error) {
      console.error(error);
      dispatch(failedCreateProduct(error))      
    }

  }
}

export const startDeleteProductsThunk = (uid: string) => {
  return async (dispatch: AppDispatch) => {

    dispatch(delitingProduct())
    try {
      const res: ApiResponse = await deleteProductsProvider(uid);

      if (res?.ok) {
        const { data } = res;

        dispatch({
          type: deletedProductById.type,
          payload: data
        })


      } else {
        dispatch({
          type: faileddeletedproducts,
          payload: res?.error
        })        
      }
    } catch (error) {
      console.error(error);
      dispatch(faileddeletedproducts(error))      
    }

  }
}

export const startProductUpdateThunk = ( formState : any, id : string) => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: updatingProduct?.type
    })

    try {
      const res : ApiResponse = await updateProductProvider(formState, id);

      if (res?.ok) {
        const { data } = res;
        dispatch({
          type: productUpdated?.type,
          payload : data
        })
        return {
          ok:true
        }
      } else {
        dispatch({
          type : failedUpdateProduct?.type,
          payload : res?.error
        })
        return {
          ok:false
        }
      }
    } 
    catch (error) {
        console.error(error);
        dispatch({
          type : failedUpdateProduct?.type,
          payload : error
        })        
    }
  }
}

export const sellProductThunk = (formState: any, amount: number) => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: sellingProduct?.type
    })

    try {
      const res: ApiResponse = await sellProductProvider(formState, amount);

      if (res?.ok) {
        const { data } = res;
        dispatch({
          type: productSold?.type,
          payload: data
        })

        return {
          ok: true
        }
      } else {
        dispatch({
          type: failedProductSale?.type,
          payload: res?.error
        })

        return {
          ok: false
        }
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: failedProductSale?.type,
        payload: error
      })
    }
  }
}