import { createSlice } from '@reduxjs/toolkit';
/**  Evento plan BASICO*/
/*interface INITIALSTATE {
    "nombre" : "Introducción a la programacion",
    "descripcion" : "Grupo 2B",
    "fecha_inicio" : "2023-10-29",
    "duracion":"1640",
    "ubicacion":"Frente a bodega ahorrera, Benito Juarez",
    "cupo_maximo" : "12"
}
 */

export const updateEventSlice = createSlice({
    name : "event-update",
    initialState : {
        status : 'initial-state',
        msg : null,
        nombre : null,
        descripcion : null,
        fecha_inicio : null,
        fecha_fin : null,
        cupo_maximo : null,
        BackendErrorMessage : null
    },
    reducers : {
        failedUpdateEvent: ( state, { payload } ) => {
            state.status = 'failed'; 
            state.msg = null;
            state.nombre = null;
            state.descripcion = null;
            state.fecha_inicio = null;
            state.fecha_fin = null;
            state.cupo_maximo = null;
            state.BackendErrorMessage = payload;
        },
        eventUpdated: ( state, { payload } ) => {
            state.status = 'success'; // 'checking', 'not-authenticated', 'authenticated'
            state.msg = payload.msg;
            state.nombre = payload.nombre;
            state.descripcion = payload.descripcion;
            state.fecha_inicio = payload.fecha_inicio;
            state.fecha_fin = payload.fecha_fin;
            state.cupo_maximo = payload.cupo_maximo;
            state.BackendErrorMessage = null;
        },
        updatingEvent : ( state )=>{
            state.status = 'in-progress';
        }
    }
});

export const { failedUpdateEvent,eventUpdated,updatingEvent } = updateEventSlice.actions;