import { NavigateFunction } from 'react-router-dom';

import axios, { AxiosResponse } from 'axios';
import { config } from '../../config'
import { ADMIN, CLIENTS, RENEW_MEMBERSHIP , POST_EDIT_USER, CHANGE_PASSWORD, CHANGE_LICENSE, RENEW_PLAN } from '../../interfaces/interfaces';
import { stagingController } from '../../utils/stagingController';
import { developmentConfig } from '../../developmentConfig';

let host = stagingController();

export const adminRegisterProvider = async (formState:ADMIN, navigate: any) => {
    try {
        let xToken = localStorage.getItem('token');
        const headers = {
            'x-token': xToken,
            'Content-Type': 'application/json'
        };

        const data = {
            ...formState
        }
        const response = await axios.post(`${(host?.localhost)? developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/api/user/administrador`, JSON.stringify(data), {
            headers: headers
        });

        if (response.status === 200 || response.status === 201) {            
            const responsee = response.data;
            return {
                ok: true,
                responsee
            };
        }

    } catch (error: any) {

        if (error.response.data && error.response.data.errors) {
            const errores = error.response.data.errors;
            const ErrorMessagee = errores.map((errorItem: any) => errorItem.msg);            
            return {
                ok: false,
                error: ErrorMessagee
            };
        }
    }
}

export const createClientProvider = async (formState :CLIENTS, navigate: any) => {
    try {
        let xToken = localStorage.getItem('token');
        const headers = {
            'x-token': xToken,
            'Content-Type': 'application/json'
        };

        const data = {
            ...formState
        }
        const response = await axios.post(`${(host?.localhost)?developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/api/user/newCliente`, JSON.stringify(data), {
            headers: headers
        });

        if (response.status === 200 || response.status === 201) {            
            const responsee = response?.data;
            return {
                ok: true,
                responsee
            };
        }

    } catch (error: any) {

        if (error.response?.data && error.response?.data?.errors) {
            const errores = error.response.data.errors;
            const ErrorMessagee = errores.map((errorItem: any) => errorItem.msg);            
            return {
                ok: false,
                error: ErrorMessagee
            };
        }
    }
}

export const updateUserProvider = async ( formState : POST_EDIT_USER ,navigate:any, id : string) => {
    try {
        let xToken = localStorage.getItem('token');
        const headers = {
            'x-token': xToken,
            'Content-Type': 'application/json'
        };

        const data = {
            ...formState
        }
        const response = await axios.put(`${(host?.localhost)?developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/api/user/edit/${id}`, JSON.stringify(data), {
            headers: headers
        });

        if (response?.status === 200 || response?.status === 201) {            
            const responsee = response?.data;
            return {
                ok: true,
                responsee
            };
        }

    } catch (error: any) {        
        if (error?.response?.data && error?.response?.data?.errors) {
            const errores = error.response.data.errors;
            const ErrorMessagee = errores.map((errorItem: any) => errorItem?.msg);            
            return {
                ok: false,
                error: ErrorMessagee
            };
        }else if(error.response?.data?.msg){
            return {
                ok: false,
                error : error.response?.data?.msg
            };
        } 
    }
}

export const renewMembershipProvider = async (membershipInfo: RENEW_MEMBERSHIP, userId: string) => {
    try {
        let xToken = localStorage.getItem('token');
        const headers = {
            'x-token': xToken,
            'Content-Type': 'application/json'
        };

        const data = {
            ...membershipInfo
        }

        const response = await axios.put(`${(host?.localhost)?developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER }/api/cliente-plan/${userId}/renovar`, JSON.stringify(data), {headers});

        if (response?.status === 200 || response?.status === 201) {            
            const responsee = response?.data;
            return {
                ok: true,
                responsee
            };
        }
    } catch (error: any) {        
        return {
            ok: false,
            error : error?.response?.data?.msg
        };
    }
}

export const changePasswordProvider = async (formState: CHANGE_PASSWORD, userId: string) => {
    try {
        let xToken = localStorage.getItem('token');
        const headers = {
            'x-token': xToken,
            'Content-Type': 'application/json'
        };

        const data = {
            ...formState
        }

        const response = await axios.put(`${(host?.localhost)?developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/api/user/${userId}/contrasena`, JSON.stringify(data), {headers});

        if (response?.status === 200 || response?.status === 201) {
            const responsee = response?.data;
            return {
                ok: true,
                responsee
            };
        }
    } catch (error: any) {
        console.error(error)
        return {
            ok: false,
            error : error?.response?.data?.msg
        };
    }
}

export const changeLicenseProvider = async (formState: CHANGE_LICENSE) => {
    try {
        let xToken = localStorage.getItem('token');
        const headers = {
            'x-token': xToken,
            'Content-Type': 'application/json'
        };

        const data = {
            ...formState
        }

        const response = await axios.put(`${(host?.localhost)?developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/api/plan/cambiar-plan`, JSON.stringify(data), {headers});

        if (response?.status === 200 || response?.status === 201) {
            const responsee = response?.data;
            return {
                ok: true,
                responsee
            };
        }
    } catch (error: any) {
        console.error(error)
        return {
            ok: false,
            error : error?.response?.data?.msg
        };
    }
}
export const renewTenantPlanProvider = async ( formState : RENEW_PLAN) => {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const data = {
            ...formState
        }
        const response = await axios.put(`${(host?.localhost)?developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/api/plan/renovar`, JSON.stringify(data), { headers });
        if (response?.status === 200 || response?.status === 201 || response?.status === 202 ) {            
            const responsee = response?.data;
            return {
                ok: true,
                responsee
            };
        }
    } catch (error) {
        console.log("ERROR EN LA PETICION:", error);

        if(error?.response?.data && typeof error?.response?.data?.error === 'string'){
            return {
                ok: false,
                error: error?.response?.data?.error,
            };
        }
        if (error?.response?.data && error?.response?.data?.errors) {
            const errores = error.response.data.errors;
            const ErrorMessagee = errores.map((errorItem: any) => errorItem.msg);
            return {
                ok: false,
                error: ErrorMessagee
            };
        }


        if(typeof error === "object"){
            let backendError = error?.response?.data?.msg;
            return {
                ok : false,
                error : backendError
            }
        }else {
            return {
                ok: false,
                error: 'Error desconocido'
            };
        }

    }
};


export const getAdminTrafficProvider = async (timeStamp:String)=>{
    let xToken = localStorage.getItem('token');
    const headers = {
        'x-token': xToken,
        'Content-Type': 'application/json',
    };
    return await axios.get(`${(host?.localhost)?developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/traffic/clientConcurrence/${timeStamp}`, { headers })
        .then((response:AxiosResponse) => {            
            const data = response?.data;            
            return {
                ok: true,
                data
            };
        })
        .catch((error) =>{            
            console.error(error)
            return {
                ok: false,
                error : error?.response?.data?.msg
            };
            
        }) 
}