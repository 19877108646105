import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { getSalesRecordsThunk } from '../store/sales/index';

function preventDefault(event: React.MouseEvent) {
    event.preventDefault();
}

export default function SalesSuperAdmin() {
    const dispatch = useDispatch<AppDispatch>();

    React.useEffect(() => {
        dispatch(getSalesRecordsThunk('mensual'));
    }, []);

    const salesRecord = useSelector((state: RootState) => state?.getSalesRecords);
    
    return (
        <React.Fragment>
            <Typography variant="h3" color="primary" sx={{ fontWeight: 500 }}>Ingresos en el mes</Typography>
            <Typography component="p" variant="h4" sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 600, marginTop: 2 }}>
                {/* { `$${salesRecord.totalMount.toFixed(2)}` } */}
                {
                    ( salesRecord.status === 'failed' ) ? salesRecord?.BackendErrorMessage :
                        (
                            (salesRecord && salesRecord?.totalMount !== undefined && salesRecord !== null) ?
                                `$${typeof salesRecord?.totalMount === 'number' ? salesRecord?.totalMount?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 'N/A'}` :
                                null
                        )
                }
            </Typography>
            {/* <Typography color="text.secondary" variant="h6" sx={{ flex: 1 ,display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                Productos vendidos
            </Typography>
            <Typography color="text.secondary" variant="h4" sx={{ flex: 1 ,display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                {
                    salesRecord?.numberOfProductsOrServicesSold
                }
            </Typography> */}
            <div>
                <Link
                    color="primary"
                    component={RouterLink}
                    to="/detalles"
                >
                    Detalles
                </Link>
            </div>
        </React.Fragment>
    );
}