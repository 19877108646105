import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { getDetailsInfo } from '../store/slides/Auth/index'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { DateTime } from 'luxon';

import Title from './Title';
import { Chip, Grid, Paper } from '@mui/material';
import { PaginationAtom } from './PaginationAtom';
import { useUsersActions } from '../customhooks/useUsersActions';
import { usePagination } from '../customhooks/usePagination';
import { SnackBar } from './SnackBar';
import { LoadingAtom } from './LoadingAtom';
import UserActionsMenu from './UserActionsMenu';
import { Notifications } from './Notifications';
/**
 * pretende visualizar usuarios y empleados inactivos a traves del planStatusActivo, estado
 * pero hasta que el backen maneje un borrado breve, esto será solo para clientes
 */
// import { usePagination } from '../customhooks/usePagination';
// import { PaginationAtom } from './PaginationAtom';
// import UserActionsMenu from './UserActionsMenu';
// import { LoadingAtom } from './LoadingAtom';
// import { useUsersActions } from '../customhooks/useUsersActions';
// import { SnackBar } from './SnackBar';

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}
const headerCellStyle = {
  fontWeight: 'bold',
  minWidth: 160,
}

export const InactivosUsuariosComponent = React.memo(() => {
  //pagination
  const { page, usersPerPage, handleChangePage, startIndex, endIndex } = usePagination()
  const { handlUserEdit, handleConfirmDelete, handleOpenModal } = useUsersActions()
  //globalState
  const detailsInfo = useSelector((state: RootState) => state?.detailsinfo);
  const backendError = useSelector((state: RootState) => state?.detailsinfo?.BackendErrorMessage);
  const usersHandler = useSelector((state: RootState) => state?.deletedUsers);
  const renewMembershipHandler = useSelector((state: RootState) => state?.renewMembership);
  
  let res = (detailsInfo.status === 'failed') ? <SnackBar status={detailsInfo?.status} severity='error' errorMsg={backendError} /> : (<TableRow><TableCell colSpan={10} align="center"><LoadingAtom /></TableCell></TableRow>)
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getDetailsInfo());
  }, [usersHandler])

  return (
    <React.Fragment>
      <Grid item xs={12} md={12} lg={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflowX: 'auto' }}>
          <Title>Usuarios Inactivos</Title>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell sx={headerCellStyle}>Uid</TableCell>
                <TableCell sx={headerCellStyle}>Nombre</TableCell>
                <TableCell sx={headerCellStyle}>Apellido materno</TableCell>
                <TableCell sx={headerCellStyle}>Apellido paterno</TableCell>
                <TableCell sx={headerCellStyle}>Rol</TableCell>
                <TableCell sx={headerCellStyle}>Fecha de inicio del plan</TableCell>
                <TableCell sx={headerCellStyle}>Fecha de expiración</TableCell>
                <TableCell sx={headerCellStyle}>Fecha de nacimiento</TableCell>
                <TableCell sx={headerCellStyle}>Estatus del usuario</TableCell>
                <TableCell sx={headerCellStyle}>Tipo de plan</TableCell>
                <TableCell sx={headerCellStyle}>Email</TableCell>
                <TableCell sx={headerCellStyle}>Monto del plan</TableCell>
                <TableCell sx={headerCellStyle}>Salario Mensual</TableCell>
                <TableCell sx={headerCellStyle}>Antiguedad</TableCell>
                <TableCell sx={headerCellStyle}>Dirección</TableCell>
                <TableCell sx={headerCellStyle} align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {detailsInfo?.status === 'success' && detailsInfo?.usuarios ? (
                detailsInfo?.usuarios.filter((user) => user?.planStatusActivo !== true ).slice(startIndex, endIndex).map((user) => (
                  <TableRow key={user.uid}>
                    <TableCell>{(user.role !== 'ADMIN') ? <Chip label={user?.uid} color="primary" variant="outlined" /> : <Chip label={user?.uid} color="secondary" variant="outlined" />}</TableCell>
                    <TableCell>{user.nombre}</TableCell>
                    <TableCell>{user.apellidoMaterno}</TableCell>
                    <TableCell>{user.apellidoPaterno}</TableCell>
                    <TableCell>
                      {(user?.role === 'ADMIN' || user?.role === 'EXPERTO_EN_EL_AREA') ? (
                        <Chip label={user.role.toUpperCase()} color="secondary" variant="outlined" />
                      ) : (
                        <Chip label={user.role.toUpperCase()} color="primary" variant="outlined" />
                      )}
                    </TableCell>
                    <TableCell>{
                      DateTime.fromISO(user?.fechaInicioPlan as string).setZone(user?.timeZone?.identifier).setLocale('es').toLocaleString({
                        year: 'numeric',
                        month: 'long',
                        day: '2-digit'
                      })
                    }</TableCell>
                    <TableCell>{                      
                      DateTime.fromISO(user?.fechaExpiracion as string).setZone(user?.timeZone?.identifier).setLocale('es').toLocaleString({
                        year: 'numeric',
                        month: 'long',
                        day: '2-digit'
                      })
                    }</TableCell>
                    <TableCell>{
                      DateTime.fromISO(user?.fechaNacimiento as string).setZone(user?.timeZone?.identifier).setLocale('es').toLocaleString({
                        year: 'numeric',
                        month: 'long',
                        day: '2-digit'
                      })
                    }</TableCell>
                    <TableCell>{(user.planStatusActivo !== true) && <Chip label='PENDIENTE DE PAGO' color="error" variant="outlined" />}</TableCell>
                    <TableCell>{(user.planMensualTipo !== undefined) ? `${user.planMensualTipo}` : 'No aplica'}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{(user.planMensualMonto !== undefined) ? `$ ${user.planMensualMonto}` : 'No aplica'}</TableCell>
                    <TableCell>{(user.salarioMensual !== undefined) ? `$ ${user.salarioMensual}` : 'No aplica'}</TableCell>
                    <TableCell>{(user.antiguedad !== undefined) ? `${user?.antiguedad} años` : 'No aplica'}</TableCell>
                    <TableCell>{user.direccion}</TableCell>
                    <TableCell align="right">
                      <UserActionsMenu
                        onEdit={() => handlUserEdit(user?.uid as string , user?.role as string)}
                        onDelete={() => handleConfirmDelete(user?.uid)}
                        onRenewMembership={() => handleOpenModal(user?.uid)}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : res}
            </TableBody>
          </Table>
          <PaginationAtom
            count={Math.ceil(detailsInfo?.usuarios.filter(user => (user?.planStatusActivo === false || user.estado === false)).length / usersPerPage)}
            page={page}
            onChange={handleChangePage}
            color="primary"
            shape="rounded"
            style={{ marginTop: '10px' }}
          />
        </Paper>
      </Grid>

      <Notifications handler={renewMembershipHandler} actionName='membresía' actionType='Renovando' />
      <Notifications handler={usersHandler} actionName='usuario' actionType='Borrando' />
      
    </React.Fragment>
  );
})
