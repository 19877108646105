import React, { useEffect, useMemo } from 'react'
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { SNACKBAR } from '../interfaces/interfaces';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export const SnackBar = React.memo(({ status, severity, errorMsg, login }: SNACKBAR) => {
    // const clientUsers = useSelector((state: RootState) => state.getClients);
    const [open, setOpen] = React.useState(false);
    const [hasBeenShown, setHasBeenShown] = React.useState(false);

    if (login === 'authenticated' && hasBeenShown!==true) {
        setOpen(true);
        setHasBeenShown(true);
    } 
    useEffect(() => {
        /*evita que el status 'authenticated' se muestre en el snack de autenticación(success) cada vez
       que se renderiza el componente */
        if (status!== 'authenticated'&&errorMsg) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [status, errorMsg]);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    return (
        <>
            <Stack>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                        {errorMsg}
                    </Alert>
                </Snackbar>
            </Stack>
        </>
    );
})