import { createSlice } from '@reduxjs/toolkit';
import { SUPER_ADMIN } from '../../interfaces/interfaces';

export const managementServiceSlice = createSlice({
    name : "managementService",
    initialState : {
        status : 'normal', 
        msg : null,
        usuarios : [] as SUPER_ADMIN[],
        BackendErrorMessage : null
    },
    reducers : {
        failedManagement: ( state, { payload } ) => {
            state.status = 'failed'; 
            state.usuarios = [];
            state.BackendErrorMessage = payload;
        },
        getManagementSuccess: ( state, { payload } ) => {
            state.status = 'success';
            state.usuarios = payload.usuarios;
            state.BackendErrorMessage = null;
        },
        inProgressManagement : ( state )=>{
            state.status = 'in-progress'
        }
    }
});

export const { failedManagement, getManagementSuccess, inProgressManagement } = managementServiceSlice.actions;