import React from 'react'
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
  } from 'recharts';
  
export interface STACKED_AREA_CHART {
    data : any
}
export const StackedAreaChart = ({ data } : STACKED_AREA_CHART) => {
    return (
        <ResponsiveContainer width="100%" height={400}>
            <AreaChart
                width={500}
                height={400}
                data={data}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area type="monotone" dataKey="amount" stackId="1" stroke="#8884d8" fill="#8884d8" />
            </AreaChart>
        </ResponsiveContainer>
    )
}
