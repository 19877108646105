import { createSlice } from '@reduxjs/toolkit';
import { PRODUCTOS } from '../../../interfaces/interfaces';



export const modalSlice = createSlice({
    name : "modal",
    initialState : {
        status : 'initial', 
        openModal : false,
        modalType : null,
        errorModal : null,
    },
    reducers: {
        openModal: (state, action) => {
          state.status = 'Open';
          state.openModal = true;
          state.modalType = action?.payload;
        },
        closeModal: (state) => {
          state.status = 'Closed';
          state.openModal = false;
          state.modalType = null;
        },
    },
});

export const { openModal, closeModal } = modalSlice.actions;