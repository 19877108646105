import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper'
import TableContainer from '@mui/material/TableContainer';
import { Chip } from '@mui/material';

import { useUsersActions } from '../customhooks/useUsersActions';
import { Delete, EditAttributes } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../store/store';
import { SnackBar } from '../atoms/SnackBar';
import { LoadingAtom } from '../atoms/LoadingAtom';
import { getAllcategoriasThunk } from '../store/categorias/index';
import Title from '../atoms/Title';
import CategoriasActions from '../atoms/CategoriasActions';
import { CreateButton } from '../atoms/CreateButton';

import { openModal } from '../store/modal';
import { FormCategoriasUpdate } from './FormCategoriasUpdate';
import { Notifications } from '../atoms/Notifications';
import { CATEGORIAS } from '../interfaces/interfaces';

const headerCellStyle = {
  fontWeight: 'bold',
  //minWidth: 30,
}
export const Categorias = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { createCategory, handleEditCategory, deleteCategory } = useUsersActions()
  //globalState
  const categories_data = useSelector((state: RootState) => state?.categories);
  const backendError = useSelector((state: RootState) => state?.categories?.BackendErrorMessage);
  const categoryHandler = useSelector((state: RootState) => state?.postCategory);

  const [idCategory, setIdCategory] = useState<string>('');

  //response failed
  let res = (categories_data.status === 'failed') ? <SnackBar status={categories_data?.status} severity='error' errorMsg={backendError} /> : (<TableRow><TableCell colSpan={10} align="center"><LoadingAtom /></TableCell></TableRow>)
  const categorysHandler = useSelector((state: RootState) => state?.deleteCategory);
  const categoryHandlerUpdate = useSelector((state: RootState) => state?.updateCategory);


  const handleOpenModal = (idCategory: string) => {
    setIdCategory(idCategory);
    dispatch(openModal());
  }

  useEffect(() => {
    dispatch(getAllcategoriasThunk());
  }, [categorysHandler.status === 'success', categoryHandler.status === 'success', categoryHandlerUpdate.status === 'success'])

  return (
    <>
      <CreateButton name='Categora' onClick={() => createCategory()} />
      <FormCategoriasUpdate categoryID={idCategory} />
      
      <Grid item xs={12} md={12} lg={12} sx={{ width: '100%!important' }}>
        <Paper sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: 440,
          overflowX: 'auto',
          overflowY: 'auto'
        }}>
          <TableContainer>
            <Title>Categorias</Title>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  <TableCell sx={headerCellStyle}>ID</TableCell>
                  <TableCell sx={headerCellStyle}>Nombre</TableCell>
                  <TableCell sx={headerCellStyle}>Estado</TableCell>
                  <TableCell sx={headerCellStyle} align="right">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  categories_data.status === 'success' ? (
                    categories_data.categorias.map((categoria : CATEGORIAS) => (
                      <TableRow key={categoria._id}>
                        <TableCell>{categoria._id}</TableCell>
                        <TableCell>{categoria.nombre}</TableCell>
                        <TableCell>{categoria.estado ? <Chip label = "Activo" color = "success" variant="outlined"/> : <Chip label = 'Eliminada' style={{ color : '#ff0000 1px solid' }} variant="outlined"/>}</TableCell>
                        <TableCell align="right">
                        <CategoriasActions 
                          onEdit={() => handleOpenModal(categoria._id)} 
                          onDelete={()=>{
                            if (categoria._id === undefined) return;
                            deleteCategory(categoria._id)
                            dispatch(getAllcategoriasThunk())
                          }}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : res
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      
      <Notifications handler={categorysHandler} actionName='categoría' actionType='Eliminando' />
      <Notifications handler={categoryHandler} actionName='categoría' actionType='Creando' />
      <Notifications handler={categoryHandlerUpdate} actionName='categoría' actionType='Actualizando' />
    </>
  );
}
