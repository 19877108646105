import { createSlice } from '@reduxjs/toolkit';
import { DETAILS_INFO } from '../../../interfaces/interfaces';



export const deleteUsersSlice = createSlice({
    name : "clientes",
    initialState : {
        status : 'normal', 
        msg : null,
        clientes : [] as DETAILS_INFO[],
        BackendErrorMessage : null
    },
    reducers : {
        faileddeletedusers: ( state, { payload } ) => {
            state.status = 'failed'; 
            state.clientes = [];
            state.BackendErrorMessage = payload;
        },
        deletedUserById: ( state, { payload } ) => {
            state.status = 'success'; 
            state.msg = payload.msg; 
            state.clientes = payload.clientes;
            state.BackendErrorMessage = null;
        },
        delitingUser : ( state )=>{
            state.status = 'in-progress'
        }
    }
});

export const { faileddeletedusers,deletedUserById,delitingUser} = deleteUsersSlice.actions;