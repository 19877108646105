import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { getClients } from '../store/slides/Auth/index'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { DateTime } from 'luxon';

import Title from './Title';
import { usePagination } from '../customhooks/usePagination';
import { useUsersActions } from '../customhooks/useUsersActions';
import UserActionsMenu from './UserActionsMenu';
import { LoadingAtom } from './LoadingAtom';
import { PaginationAtom } from './PaginationAtom';
import { Chip, Grid, Paper } from '@mui/material';
import { SnackBar } from './SnackBar';
import { saveUserId } from '../store/usuarios';
import { openModal } from '../store/modal';
import { Notifications } from './Notifications';


const headerCellStyle = {
  fontWeight: 'bold',
  minWidth: 180,
}

export const InactivosClientesAtom = React.memo(() => {
  //pagination
  const { page, usersPerPage, handleChangePage, startIndex, endIndex } = usePagination()
  const { handlUserEdit, handleConfirmDelete, handleOpenModal } = useUsersActions()
  //globalState
  const renewMembershipHandler = useSelector((state: RootState) => state?.renewMembership)
  const clientUsers = useSelector((state: RootState) => state.getClients);
  const loading = useSelector((state: RootState) => state?.getClients?.status === 'in-progress')
  const usersHandler = useSelector((state: RootState) => state?.deletedUsers);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getClients())
  }, [usersHandler])

  const jsx = (loading) && (<TableRow><TableCell colSpan={10} align="center"><LoadingAtom /></TableCell></TableRow>)

  return (
    <>
      <Grid item xs={12} md={6} lg={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflowX: 'auto' }}>
          <Title>Clientes inactivos</Title>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={headerCellStyle}>Uid</TableCell>
                <TableCell sx={headerCellStyle}>Nombre del cliente</TableCell>
                <TableCell sx={headerCellStyle}>Apellido materno</TableCell>
                <TableCell sx={headerCellStyle}>Apellido paterno</TableCell>
                <TableCell sx={headerCellStyle}>Estado</TableCell>
                <TableCell sx={headerCellStyle}>Fecha de nacimiento</TableCell>
                <TableCell sx={headerCellStyle}>Tipo de plan</TableCell>
                <TableCell sx={headerCellStyle}>Monto</TableCell>
                <TableCell sx={headerCellStyle}>Email</TableCell>
                <TableCell sx={headerCellStyle}>Direccion</TableCell>
                <TableCell sx={headerCellStyle} align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clientUsers?.status === 'success' && clientUsers?.usuarios ? (
                clientUsers?.usuarios.filter((user) => user?.planStatusActivo === false).slice(startIndex, endIndex).map((user) => (
                  <TableRow key={user.uid}>
                    <TableCell>{<Chip label={user?.uid} color="error" variant="outlined" />}</TableCell>
                    <TableCell>{user.nombre}</TableCell>
                    <TableCell>{user.apellidoMaterno}</TableCell>
                    <TableCell>{user.apellidoPaterno}</TableCell>
                    <TableCell>{(user.planStatusActivo === false) ? <Chip label='VENCIDO' color="error" variant="outlined" /> : <Chip label='ACTIVO' color="success" variant="outlined" />}</TableCell>
                    <TableCell>{
                      <Chip label={
                        DateTime.fromISO(user?.fechaNacimiento).setZone(user?.timeZone?.identifier).setLocale('es').toLocaleString({
                          year: 'numeric',
                          month: 'long',
                          day: '2-digit'
                        })
                      }
                        color="success" variant="outlined" />
                    }</TableCell>
                    <TableCell>{user.planMensualTipo}</TableCell>
                    <TableCell>{`$${user.planMensualMonto}`}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{`${user.direccion}`}</TableCell>
                    <TableCell align="right">
                      <UserActionsMenu
                        onEdit={() => handlUserEdit(user?.uid as string , user?.role as string)}
                        onDelete={() => handleConfirmDelete(user?.uid as string)}
                        onRenewMembership={() => handleOpenModal(user?.uid as string)}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : jsx}
            </TableBody>
          </Table>        
          <PaginationAtom
            count={Math.ceil(clientUsers?.usuarios.filter(user => (user?.planStatusActivo === false || user.estado === false)).length / usersPerPage)}
            page={page}
            onChange={handleChangePage}
            color="primary"
            shape="rounded"
            style={{ marginTop: '10px' }}
          />
        </Paper>
      </Grid>
      
      <Notifications handler={usersHandler} actionName='usuario' actionType='Borrando' />
      <Notifications handler={renewMembershipHandler} actionName='membresía' actionType='Renovando' />
    </>
  )
})
