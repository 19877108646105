import { createSlice } from '@reduxjs/toolkit';



export const updateProductSlice = createSlice({
    name : "product-update",
    initialState : {
        status : 'initial-state',
        msg : null,
        nombre : null,
        categoria : null,
        descripcion : null,
        disponible : null,
        stock : null,
        BackendErrorMessage: null
    },
    reducers : {
        failedUpdateProduct: ( state, { payload } ) => {
            state.status = 'failed'; 
            state.msg = null;
            state.nombre = null;
            state.categoria = null;
            state.descripcion = null;
            state.disponible = null;
            state.stock = null;
        },
        productUpdated: ( state, { payload } ) => {
            state.status = 'success'; // 'checking', 'not-authenticated', 'authenticated'
            state.msg = payload.msg;
            state.nombre = payload.nombre;
            state.categoria = payload.categoria;
            state.descripcion = payload.descripcion;
            state.disponible = payload.disponible;
            state.stock = payload.stock;
        },
        updatingProduct : ( state )=>{
            state.status = 'in-progress';
        }
    }
});

export const { failedUpdateProduct,productUpdated,updatingProduct } = updateProductSlice.actions;