import React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../store';

import { RegisterClients } from '../components/RegisterClients';
import { RegisterClientsAdmins } from '../components/RegisterClientsAdmins';
import { RegisterFormTenants } from '../components/RegisterFormTenants';

// jala el formlario dentro de un div.

export const RegisterScreen = () => {
  const role = useSelector((state: RootState) => state.auth.role);
  if (role === 'ADMIN') {
    return <RegisterClients />;
  } else if (role === 'SUPER_ADMIN') {
    return <RegisterClientsAdmins/>;
  } else if (role === 'MANAGEMENT-SERVICE-ROLE') {
    return <RegisterFormTenants />;
  } else {
    return <div>No se ha seleccionado un rol válido.</div>;
  }
}
