export const HomeTutorial = () => {
    return (
        <div>
            <div className='w-full mt-32'>
                <div className='flex justify-start bg-white'>
                    <p className='text-mainBlue font-bold text-4xl sm:text-3xl'>Tutorial volcano admin</p>
                </div>
                <div className='bg-lightGray w-full ' style={{ height: 'calc(100vw / (16/9))' }}>                    
                    <iframe
                        className="w-full h-full"                         
                        src="https://www.youtube.com/embed/D-vMRwij8Io?si=4a70SYIL6LzYGZKQ?autoplay=1" 
                        title="YouTube video player" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; gyroscope; picture-in-picture; web-share" 
                        allowFullScreen
                    >
                    </iframe>
                </div>
                <div className='w-full flex justify-center'>
                    <a href="/registrar" className="bg-mainBlue px-10 py-2 mt-4 text-white font-semibold rounded-sm">Comenzar</a>
                </div>
            </div>
        </div>
    );
}