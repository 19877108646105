import React, { useState, useEffect } from 'react';
import './App.css';
import {
  RouterProvider,
} from "react-router-dom";
import { Provider, useSelector } from 'react-redux';
import { router } from './routes/Routes';
import { privateRoutes } from './routes/PrivateRouter'
import { RootState, store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { login } from './store/slides/authSlice'

function App() {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.status === 'authenticated'
  );
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      {(isAuthenticated)?<RouterProvider router={privateRoutes}/>:<RouterProvider router={router} />}
      </PersistGate>
    </Provider>
  );
}

export default App;
