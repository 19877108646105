import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { getDetailsInfo } from '../store/slides/Auth/index'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { DateTime } from 'luxon';

import Title from './Title';
import { Chip, Grid, Paper } from '@mui/material';

import { usePagination } from '../customhooks/usePagination';
import { PaginationAtom } from './PaginationAtom';
import UserActionsMenu from './UserActionsMenu';
import { LoadingAtom } from './LoadingAtom';
import { useUsersActions } from '../customhooks/useUsersActions';
import { SnackBar } from './SnackBar';
import { Notifications } from './Notifications';
import { DETAILS_INFO } from '../interfaces/interfaces';

function preventDefault(event: React.MouseEvent) {
    event.preventDefault();
}
const headerCellStyle = {
    fontWeight: 'bold',
    minWidth: 150,
}

export const ActivosUsuariosComponent = React.memo(() => {
    //pagination
    const { page, usersPerPage, handleChangePage, startIndex, endIndex } = usePagination()
    const { handlUserEdit, handleConfirmDelete, handleOpenModal } = useUsersActions()
    //globalState
    const renewMembershipHandler = useSelector((state: RootState) => state?.renewMembership)
    const detailsInfo = useSelector((state: RootState) => state?.detailsinfo);
    const backendError = useSelector((state: RootState) => state?.detailsinfo?.BackendErrorMessage);
    const handleEditUsr = useSelector((state: RootState) => state?.editUser);
    //response failed
    let res = (detailsInfo.status === 'failed') ? <SnackBar status={detailsInfo?.status} severity='error' errorMsg={backendError} /> : (<TableRow><TableCell colSpan={10} align="center"><LoadingAtom /></TableCell></TableRow>)
    const usersHandler = useSelector((state: RootState) => state?.deletedUsers);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(getDetailsInfo());
    }, [])

    return (
        <React.Fragment>
            <Title>Usuarios activos</Title>
            <Table size="medium">
                <TableHead>
                    <TableRow>
                        <TableCell sx={headerCellStyle}>Uid</TableCell>
                        <TableCell sx={headerCellStyle}>Nombre</TableCell>
                        <TableCell sx={headerCellStyle}>Apellido materno</TableCell>
                        <TableCell sx={headerCellStyle}>Apellido paterno</TableCell>
                        <TableCell sx={headerCellStyle}>Rol del usuario</TableCell>
                        <TableCell sx={headerCellStyle}>Fecha de inicio del plan</TableCell>
                        <TableCell sx={headerCellStyle}>Fecha de nacimiento</TableCell>
                        <TableCell sx={headerCellStyle}>Estatus</TableCell>
                        <TableCell sx={headerCellStyle}>Tipo de plan</TableCell>
                        <TableCell sx={headerCellStyle}>Email</TableCell>
                        <TableCell sx={headerCellStyle}>Monto</TableCell>
                        <TableCell sx={headerCellStyle}>Salario Mensual</TableCell>
                        <TableCell sx={headerCellStyle}>Antiguedad</TableCell>
                        <TableCell sx={headerCellStyle}>Direccion</TableCell>
                        <TableCell sx={headerCellStyle} align="right">Acciones</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {detailsInfo?.status === 'success' && detailsInfo?.usuarios ? (
                        detailsInfo?.usuarios.slice(startIndex, endIndex).filter((user : DETAILS_INFO) => (user?.planStatusActivo === true && user?.estado === true)).map((user : DETAILS_INFO) => (
                            <TableRow key={user.uid}>
                                <TableCell>{(user.role !== 'ADMIN') ? <Chip label={user.uid} color="primary" variant="outlined" /> : <Chip label={user.uid} color="secondary" variant="outlined" />}</TableCell>
                                <TableCell>{user.nombre}</TableCell>
                                <TableCell>{user.apellidoMaterno}</TableCell>
                                <TableCell>{user.apellidoPaterno}</TableCell>
                                <TableCell>{(user.role !== 'ADMIN') ? <Chip label='CLIENTE' color="primary" variant="outlined" /> : <Chip label='EMPLEADO' color="secondary" variant="outlined" />}</TableCell>
                                <TableCell>{
                                    DateTime.fromISO(user?.fechaInicioPlan as string).setZone(user?.timeZone?.identifier).setLocale('es').toLocaleString({
                                        year: 'numeric',
                                        month: 'long',
                                        day: '2-digit'
                                    })
                                }</TableCell>
                                <TableCell>{
                                    DateTime.fromISO(user?.fechaNacimiento).setZone(user?.timeZone?.identifier).setLocale('es').toLocaleString({
                                        year: 'numeric',
                                        month: 'long',
                                        day: '2-digit'
                                    })
                                }</TableCell>
                                <TableCell>{(user.estado !== false) ? <Chip label='ACTIVO' color="success" variant="outlined" /> : <Chip label='ELIMINADO' style={{ color: '#ff0000', border: '#ff0000 1px solid' }} variant="outlined" />}</TableCell>
                                <TableCell>{(user.planMensualTipo !== undefined) ? `${user.planMensualTipo}` : 'No aplica'}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{(user.planMensualMonto !== undefined) ? `$ ${user.planMensualMonto}` : 'No aplica'}</TableCell>
                                <TableCell>{(user.salarioMensual !== undefined) ? `$ ${user.salarioMensual}` : 'No aplica'}</TableCell>
                                <TableCell>{(user.antiguedad !== undefined) ? `${user?.antiguedad} años` : 'No aplica'}</TableCell>
                                <TableCell>{user.direccion}</TableCell>
                                <TableCell align="right">
                                    <UserActionsMenu
                                        onEdit={() => handlUserEdit( user?.uid as string , user?.role as string)}
                                        onDelete={() => handleConfirmDelete(user?.uid as string)}
                                        onRenewMembership={() => handleOpenModal(user?.uid as string)}
                                    />
                                </TableCell>
                            </TableRow>
                        ))
                    ): res }
                </TableBody>
            </Table>
            {/* <Pagination count={Math.ceil(clientUsers.usuarios.length / usersPerPage)} page={page} onChange={handleChangePage} color="primary" shape="rounded" style={{ marginTop: '10px'}}/> */}
            <PaginationAtom count={Math.ceil(detailsInfo?.usuarios?.length / usersPerPage)} page={page} onChange={handleChangePage} color="primary" shape="rounded" style={{ marginTop: '10px' }} />
            
            <Notifications handler={usersHandler} actionName='usuario' actionType='Borrando' />
            <Notifications handler={renewMembershipHandler} actionName='membresía' actionType='Renovando' />
            <Notifications handler={ handleEditUsr } actionName='editarUsuario' actionType='errores' />
        </React.Fragment>
    );
})
