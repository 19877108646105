import { createSlice } from '@reduxjs/toolkit';
import { CATEGORIAS } from '../../../interfaces/interfaces';

export const deleteCategorySlice = createSlice({
  name : "categorias",
  initialState : {
    status : 'normal', 
    msg : null,
    categorias : [] as CATEGORIAS[],
    BackendErrorMessage : null
  },
  reducers : {
    faileddeleted: ( state, { payload } ) => {
      state.status = 'failed'; 
      state.categorias = [];
      state.BackendErrorMessage = payload;
    },
    deleteCategoryById: ( state, { payload } ) => {
      state.status = 'success'; 
      state.msg = payload.msg; 
      state.categorias = payload.categorias;
      state.BackendErrorMessage = null;
    },
    delitingCategory : ( state )=>{
      state.status = 'in-progress'
    }
  }
});

export const { faileddeleted, deleteCategoryById, delitingCategory } = deleteCategorySlice.actions;