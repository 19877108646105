import {
  CategoryOutlined,
  FindInPageRounded,
  InventoryOutlined,
  NoAccountsRounded,
  PersonAddAlt1Rounded,
  SettingsOutlined,
  RecentActors,
  Security,
  Groups,
  PermMedia,
  QueryStats,
  Assistant,
  Business,
  Dashboard,
  People,
  LibraryBooksOutlined,
  ThreeP,
} from '@mui/icons-material';

import { getTimeZoneFromUser } from '../utils/timeZoneParser';
import { FormState, NavSections } from '../interfaces/interfaces';
import type { MesesKeys } from '../types/types';

const timeZoneData = getTimeZoneFromUser();

export const fieldsSuperAdmin = {
  nombreEmpresa: '',
  email: '',
  phoneNumber: '',
  direccion: '',
  fechaNacimiento: '',
  timeZone: {
    identifier: timeZoneData.userTimeZone,
    offset: timeZoneData.userOffset
  },
}

export const fieldsAdmin = {
  nombre: '',
  apellidoPaterno: '',
  apellidoMaterno: '',
  email: '',
  phoneNumber: '',
  direccion: '',
  salarioMensual: '',
  fechaNacimiento: '',
  timeZone: {
    identifier: timeZoneData.userTimeZone,
    offset: timeZoneData.userOffset
  },
}

export const initialFormConfig: Record<string, FormState> = {
  "ADMIN": fieldsAdmin,
  "EXPERTO_EN_EL_AREA": fieldsAdmin,
  "SUPER_ADMIN": fieldsSuperAdmin,
  "MANAGEMENT-SERVICE-ROLE": fieldsSuperAdmin
};

export const adminMainSections: NavSections[] = [
  {
    title: 'Dashboard',
    icon: Dashboard,
    path: '/dashboard',
  },
  {
    title: 'Eventos',
    icon: LibraryBooksOutlined,
    path: '/user/eventos',
  },
  {
    title: 'Clientes',
    icon: People,
    path: '/user/clientes',
  },
  {
    title: 'Inactivos',
    icon: NoAccountsRounded,
    path: '/user/inactivos',
  },
  {
    title: 'Buscar',
    icon: FindInPageRounded,
    path: '/search',
  },
  {
    title: 'Registro',
    icon: PersonAddAlt1Rounded,
    path: '/user/registro',
  },
  {
    title: 'Empresa',
    icon: Business,
    path: '/configuracion',
    subSection: [
      {
        title: 'Contratos',
        icon: RecentActors,
        path: '/contratos',
      },
      {
        title: 'Alertas de seguridad',
        icon: Security,
        path: '/security',
      },
      {
        title: 'General',
        icon: ThreeP,
        path: '/general'
      },
      {
        title: 'Grupo de empleados',
        icon: Groups,
        path: '/employees'
      },
      {
        title: 'Asistente artificial',
        icon: Assistant,
        path: '/assistant'
      }
    ]
  }
]

export const secondSections: NavSections[] = [
  {
    title: 'Categorias',
    icon: CategoryOutlined,
    path: '/user/categorias',
  },
  {
    title: 'Productos',
    icon: InventoryOutlined,
    path: '/user/productos',
  },
  {
    title: 'Configuraciones',
    icon: SettingsOutlined,
    path: '/user/configuraciones',
  }
]

export const superAdminMainSections: NavSections[] = [
  {
    title: 'Dashboard',
    icon: Dashboard,
    path: '/dashboard',
  },
  {
    title: 'Eventos',
    icon: LibraryBooksOutlined,
    path: '/user/eventos',
  },
  {
    title: 'Usuarios',
    icon: People,
    path: '/user/clientes',
  },
  {
    title: 'Inactivos',
    icon: NoAccountsRounded,
    path: '/user/inactivos',
  },
  {
    title: 'Buscar',
    icon: FindInPageRounded,
    path: '/search',
  },
  {
    title: 'Registro',
    icon: PersonAddAlt1Rounded,
    path: '/user/registro',
  },
  {
    title: 'Mi empresa',
    icon: Business,
    path: '/configuracion',
    subSection: [
      {
        title: 'Contratos',
        icon: RecentActors,
        path: '/contratos',
      },
      {
        title: 'Alertas de seguridad',
        icon: Security,
        path: '/security',
      },
      {
        title: 'General',
        icon: ThreeP,
        path: '/general'
      },
      {
        title: 'Grupo de empleados',
        icon: Groups,
        path: '/employees'
      },
      {
        title: 'Asistente artificial',
        icon: Assistant,
        path: '/assistant'
      }
    ]
  }
]

export const expertoMainSections: NavSections[] = [
  {
    title: 'Dashboard',
    icon: Dashboard,
    path: '/dashboard',
  },
  {
    title: 'Eventos',
    icon: LibraryBooksOutlined,
    path: '/user/eventos',
  },
  {
    title: 'Clientes',
    icon: People,
    path: '/user/clientes',
  },
  {
    title: 'Analítica de progreso',
    icon: QueryStats,
    path: '/analytics',
  },
  {
    title: 'Multimedia',
    icon: PermMedia,
    path: '/multimedia'
  }
]

export const numeroAMes: Record<MesesKeys, string> = {
  '1': 'Enero',
  '2': 'Febrero',
  '3': 'Marzo',
  '4': 'Abril',
  '5': 'Mayo',
  '6': 'Junio',
  '7': 'Julio',
  '8': 'Agosto',
  '9': 'Septiembre',
  '10': 'Octubre',
  '11': 'Noviembre',
  '12': 'Diciembre'
};
