import { useState } from 'react';
import { FormEventosAddAnUserProps } from '../interfaces/interfaces';

import Title from '../atoms/Title';
import { Box, Button, Modal, TextField } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { closeModal } from '../store/modal';
import { config } from '../config';
import { addAnUserToEventThunk } from '../store/eventos';
import { useForm } from '../customhooks/useForm';

const style = {
display: 'flex',
gap: '10px',
flexDirection: 'column',
position: 'absolute' as 'absolute',
top: '50%',
left: '50%',
transform: 'translate(-50%, -50%)',
width: 400,
bgcolor: 'background.paper',
border: '0px solid #000',
boxShadow: 24,
p: 4,

};

const btnStyle = {
minWidth: '100px',
fontSize: '13px',
}

export const FormEventosAddAnUser = ({ modalType, eventId }: FormEventosAddAnUserProps) => {
  const open = useSelector((state: RootState) => state?.modal?.openModal);
  const modaltype = useSelector((state: RootState) => state?.modal?.modalType)
  const dispatch = useDispatch<AppDispatch>();
  const { onInputChange , onResetForm , formState} = useForm({
    user_id : '',
  });
  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const resetForm = await dispatch(addAnUserToEventThunk(eventId, formState?.user_id as string))

    if (resetForm?.ok === true) {      
      dispatch(closeModal());
      onResetForm();
    }
  }

  return (
    <>
    {
      (modaltype === modalType && config.AllOWED_MODAL_TYPE.includes( config.MODAL_TYPE_ADD_USER )) &&
      <Modal open={open} onClose={handleClose} aria-labelledby="Usuario nuevo" aria-describedby="añade un usuario a un evento">
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', width: '100%' }}>
          <Box sx={style}>
            <Title>Añadir usuario al evento</Title>
            
            <TextField
              label="ID del usuario"
              name="user_id"
              value={formState?._id}
              onChange={onInputChange}
              fullWidth
              margin="normal"
              required
            />

            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Button
                variant="outlined"
                color="error"
                onClick={handleClose}
                style={btnStyle}
              >
                Cancelar
              </Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                style={btnStyle}
              >
                Registrar
              </Button>
            </Box>
          </Box>
        </form>
      </Modal>
    }
    </>
  )
}
