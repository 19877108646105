import React from 'react'
import { Grid, Paper } from '@mui/material';
import { SnackBar } from '../atoms/SnackBar';

import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { MultiUsersHandler } from './MultiUsersHandler';

export const Clientes = () => {
  const status = useSelector((state: RootState) => state?.getClients?.status);
  const errorMsg = useSelector((state: RootState) => state?.getClients?.BackendErrorMessage);
  return (
    <>
      <SnackBar errorMsg={(errorMsg!==undefined && errorMsg !== null)?errorMsg:''} status= {status} severity='error'/>
      <Grid item xs={12} md={12} lg={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflowX: 'auto' }}>
          <MultiUsersHandler/>
        </Paper>
      </Grid>
    </>
  )
}
