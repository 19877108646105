import { useSelector } from "react-redux";
import { RootState } from "../store";
import { Grid, Paper, Typography, useTheme } from "@mui/material";
import InfoTableAdmin from "../components/InfoTableAdmin";
import Title from "./Title";
import { Line, LineChart, ResponsiveContainer, XAxis, YAxis, Tooltip, CartesianGrid, Legend, BarChart, Label, LabelList, Bar } from "recharts";
import { numeroAMes } from "../constants/ConstantsFlexSolutionManager";
import { MesesKeys } from "../types/types";

const boxStyle = {
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'auto',
  minHeight: '370px',
  overflowY: 'auto'
}

const SalesDetailsAdmin = () => {
  const theme = useTheme();
  
  const data = useSelector((state: RootState) => state?.getUserAdminTrafficReportSlide?.traffic);
  const dataChart = [
    {
      name: 'Últimos 6 meses',
      visitas: Number(data?.mostFrequentVisitor?.numberOfUserTrafficPerSixMonth),
      usuario: data?.mostFrequentVisitor?.userData ? `${data?.mostFrequentVisitor?.userData?.nombre} ${data?.mostFrequentVisitor?.userData?.apellidoPaterno}` : null
    },
    {
      name: 'Último mes',
      visitas: Number(data?.mostFrequentVisitorLastMonth?.numberOfUserTrafficPerMonth),
      usuario: data?.mostFrequentVisitorLastMonth?.userData ? `${data?.mostFrequentVisitorLastMonth?.userData?.nombre} ${data?.mostFrequentVisitorLastMonth?.userData?.apellidoPaterno}` : null
    },
    {
      name: 'Última semana',
      visitas: Number(data?.mostFrequentVisitorLastWeek?.numberOfUserTrafficPerWeek),
      usuario: data?.mostFrequentVisitorLastWeek?.userData ? `${data?.mostFrequentVisitorLastWeek?.userData?.nombre} ${data?.mostFrequentVisitorLastWeek?.userData?.apellidoPaterno}` : null
    }
  ];

  return (
    <Grid container spacing={ 2 } sx={ { marginLeft: 0.5 } }>
      <Grid item marginTop={ 2 } xs={ 12 } md={ 12 } lg={ 12 }>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
          style={{width: '100%'}}
        >
          <Title>Visitas destacadas por usuario</Title>
          <ResponsiveContainer width="100%" height={ 300 }>
            <BarChart data={ dataChart } margin={ { top: 30, right: 30, left: 20, bottom: 5 } }>
              <XAxis
                dataKey="name"
                stroke={ theme.palette.text.secondary }
                style={ theme.typography.body2 }
              />
              <YAxis
                stroke={ theme.palette.text.secondary }
                style={ theme.typography.body2 }
              />
              <Tooltip formatter={ (value, name, props) => [value, props.payload.usuario ? `Visitas por ${props.payload.usuario}` : 'Sin datos'] } />
              <Legend />
              <Bar dataKey="visitas" fill={ theme.palette.primary.main } barSize={ 100 }>
                <LabelList name="Visitas" dataKey="visitas" position="top" />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Paper>
      </Grid>

      <Grid item marginTop={ 2 } xs={ 12 } md={ 6 } lg={ 4 }>
        <Paper sx={ boxStyle } style={ { width: '100%' } }>
          <Title>Vistante más frecuente en los últimos 6 meses</Title>
          {
            data?.mostFrequentVisitor?.userData ?
              <>
                <InfoTableAdmin user={ data?.mostFrequentVisitor?.userData } />
              </>
              : <Typography variant="body2">Aún no hay reportes de visitas disponibles.</Typography>
          }
        </Paper>
      </Grid>

      <Grid item marginTop={ 2 } xs={ 12 } md={ 6 } lg={ 4 }>
        <Paper sx={ boxStyle } style={ { width: '100%' } }>
          <Title>Vistante más frecuente en el último mes</Title>
          {
            data?.mostFrequentVisitorLastMonth?.userData ?
              <>
                <InfoTableAdmin user={ data?.mostFrequentVisitorLastMonth?.userData } />
              </>
              : <Typography variant="body2">Aún no hay reportes de visitas disponibles.</Typography>
          }
        </Paper>
      </Grid>

      <Grid item marginTop={ 2 } xs={ 12 } md={ 6 } lg={ 4 }>
        <Paper sx={ boxStyle } style={ { width: '100%' } }>
          <Title>Vistante más frecuente en la última semana</Title>
          {
            data?.mostFrequentVisitorLastWeek?.userData ?
              <>
                <InfoTableAdmin user={ data?.mostFrequentVisitorLastWeek?.userData } />
              </>
              : <Typography variant="body2">Aún no hay reportes de visitas disponibles.</Typography>
          }
        </Paper>
      </Grid>
    </Grid>
  )
}

export default SalesDetailsAdmin;