import { createSlice } from '@reduxjs/toolkit';
import { SUPER_ADMIN } from '../../interfaces/interfaces';


export const allUsersSlice = createSlice({
    name : "getAllUsers",
    initialState : {
        status : 'normal', 
        msg : null,
        usuarios : [] as SUPER_ADMIN[],
        BackendErrorMessage : null
    },
    reducers : {
        failedAllUsers: ( state, { payload } ) => {
            state.status = 'failed'; //'checking', 'not-authenticated' , 'authenticated'
            state.usuarios = [];
            state.BackendErrorMessage = payload;
        },
        getAllUsers: ( state, { payload } ) => {
            state.status = 'success'; //'checking', 'not-authenticated' , 'authenticated'
            state.usuarios = payload.usuarios;
            state.BackendErrorMessage = null;
        },
        inprogress : ( state )=>{
            state.status = 'in-progress...'
        }
    }
});

export const { failedAllUsers, getAllUsers, inprogress } = allUsersSlice.actions;