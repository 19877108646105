import React, { useEffect, useState } from 'react';

import { openModal } from '../store/modal';
import { useUsersActions } from '../customhooks/useUsersActions';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { getAllProductosThunk, getProductByIdThunk } from '../store/productos/index'
import { getAllcategoriasThunk } from '../store/categorias';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Title from '../atoms/Title';

import { usePagination } from '../customhooks/usePagination';
import { PaginationAtom } from '../atoms/PaginationAtom';
import ProductosActions from '../atoms/ProductosActions';
import { LoadingAtom } from '../atoms/LoadingAtom';
import { SnackBar } from '../atoms/SnackBar';
import { Chip, Grid, Paper } from '@mui/material';
import { CreateButton } from '../atoms/CreateButton';
import CreateProductModal from '../atoms/ProductoModal';
import { Notifications } from '../atoms/Notifications';
import { config } from '../config';
import { FormProductUpdate } from './FormProductUpdate';
import ModalSellProduct from './ModalSellProduct';

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

const headerCellStyle = {
  fontWeight: 'bold',
  minWidth: 180,
}

export const Productos = React.memo(() => {
  const [uidProduct, setUidProduct] = useState('')

  //pagination
  const { page, usersPerPage, handleChangePage, startIndex, endIndex } = usePagination()
  const { handleEditProduct, ProductDelete, handleSellProduct } = useUsersActions()
  //globalState
  const getProductos = useSelector((state: RootState) => state?.productos);
  const backendError = useSelector((state: RootState) => state?.productos?.BackendErrorMessage);
  const productHandler = useSelector((state: RootState) => state?.postProduct);
  const deleteProductsHandler = useSelector((state: RootState) => state?.deleteProduct);
  const sellProductHandler = useSelector((state: RootState) => state?.sellProduct);
  const handleBackendErrorsAboutProductUpate = useSelector((state: RootState) => state?.updateProduct);
  //response failed
  let res = (getProductos.status === 'failed') ? <SnackBar status={getProductos?.status} severity='error' errorMsg={backendError} /> : (<TableRow><TableCell colSpan={10} align="center"><LoadingAtom /></TableCell></TableRow>)
  const dispatch = useDispatch<AppDispatch>();

  const handleOpenModal = () => {
    dispatch({
      type: openModal.type,
      payload: config.MODAL_TYPE_CREATE_PRODUCT
    });
  };

  const getProductById = async (uidProduct: string) => {
    await dispatch(getProductByIdThunk(uidProduct))
  }

  const handleProductEdit = async (uidProduct: string) => {
    await getProductById(uidProduct)
    .then(() => {
      setUidProduct(uidProduct)
      handleEditProduct()
    })
  }

  const handleProductSale = async (uidProduct: string) => {
    await getProductById(uidProduct)
    .then(() => {
      setUidProduct(uidProduct)
      handleSellProduct()
    })
  }

  useEffect(() => {
    dispatch(getAllProductosThunk());
    dispatch(getAllcategoriasThunk());
  }, [ productHandler.status === "success" , deleteProductsHandler.status === "success", sellProductHandler.status === "success"])

  return (
    <React.Fragment>
      <CreateButton name='Producto' onClick={handleOpenModal} />
      <CreateProductModal modalType={config.MODAL_TYPE_CREATE_PRODUCT} />
      { uidProduct && <ModalSellProduct modalType={config.MODAL_TYPE_SELL_PRODUCT} /> }
      { uidProduct && <FormProductUpdate uidProduct={uidProduct} modalType={config.MODAL_TYPE_EDIT_PRODUCT} /> }

      <Grid item xs={12} md={12} lg={12} >
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflowX: 'auto' }}>
          <Title>Productos</Title>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={headerCellStyle}>ID</TableCell>
                <TableCell sx={headerCellStyle}>Nombre</TableCell>
                <TableCell sx={headerCellStyle}>Precio</TableCell>
                <TableCell sx={headerCellStyle}>Categoria</TableCell>
                <TableCell sx={headerCellStyle}>Descripcion</TableCell>
                <TableCell sx={headerCellStyle}>Disponible</TableCell>
                <TableCell sx={headerCellStyle}>Cantidad en Stock</TableCell>
                <TableCell sx={headerCellStyle} align="right">Opciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getProductos && getProductos?.status === 'success' ? (
                getProductos.productos?.slice(startIndex, endIndex).map((producto) => (
                  <TableRow key={producto?._id}>
                    <TableCell>{producto?._id}</TableCell>
                    <TableCell>{producto?.nombre}</TableCell>
                    <TableCell>{`$ ${producto?.precio}`}</TableCell>
                    <TableCell>{producto?.categoria?.nombre}</TableCell>
                    <TableCell>{producto?.descripcion}</TableCell>
                    <TableCell>{producto?.disponible && producto?.stock >  0 ? <Chip label="Existente" color="success" variant="outlined" /> : <Chip label='Agotado' style={{ color: '#ff0000', border: '#ff0000 1px solid' }} variant="outlined" />}</TableCell>
                    <TableCell>{`${producto?.stock} En existencia`}</TableCell>
                    <TableCell align="right">
                      <ProductosActions
                        onEdit={() => handleProductEdit(producto?._id)}
                        onDelete={() => {
                          if (producto?._id) {
                            ProductDelete(producto?._id);
                            dispatch(getAllProductosThunk());
                          }
                        }}
                        onSell={ () => handleProductSale(producto?._id)}
                        canSell={producto?.disponible === false || producto?.stock <= 0}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : res}
            </TableBody>
          </Table>
          {/* <Pagination count={Math.ceil(clientUsers.usuarios.length / usersPerPage)} page={page} onChange={handleChangePage} color="primary" shape="rounded" style={{ marginTop: '10px'}}/> */}
          <PaginationAtom count={Math.ceil(getProductos.productos?.length / usersPerPage)} page={page} onChange={handleChangePage} color="primary" shape="rounded" style={{ marginTop: '10px' }} />
        </Paper>
      </Grid>
      {
        (productHandler.status === 'success') && <SnackBar severity='success' errorMsg={productHandler.msg}></SnackBar>
      }
      {
        /** clientes delete */
        (deleteProductsHandler.status === 'failed') && <SnackBar severity='error' errorMsg={deleteProductsHandler.BackendErrorMessage}></SnackBar>
      }
      {
        /** clientes delete */
        (deleteProductsHandler.status === 'in-progress') && <SnackBar severity='info' errorMsg='Borrando producto, apreciamos tu paciencia...'></SnackBar>
      }
      {
        /** clientes delete */
        (deleteProductsHandler.status === 'success') && <SnackBar severity='success' errorMsg={deleteProductsHandler.msg}></SnackBar>
      }      
      <Notifications handler={handleBackendErrorsAboutProductUpate} actionName='producto' actionType='Actualizando'/>
      <Notifications handler={sellProductHandler} actionName='producto' actionType='Vendiendo'/>
    </React.Fragment>
  );
})