import { ApiResponse } from "../../types/types";
import { POST_EVENTO_BASICO } from "../../interfaces/interfaces";

import { AppDispatch } from "../store";
import { gettingEvents, getAllEvents,failedgettingEvents } from './slides/index'
import { addAnUserToEventProvider, createEventProvider } from './provider';

import {
  getAllEventsProvider,
  updateEventProvider,
  faileddeleted,
  deletedEventById,
  delitingEvent,
  deleteEventProvider,
  failedUpdateEvent,
  eventUpdated,
  updatingEvent,
  failedCreateEvent,
  eventCreated,
  creatingEvent,
  addingUser,
  userAdded,
  failedAddUser
} from './index'


export const getAllEventsThunk =()=>{
    return async (dispatch: AppDispatch) => {
        dispatch(gettingEvents())
        try {
          const res : ApiResponse = await getAllEventsProvider();
    
          if (res?.ok) {
            const { data } = res;
            dispatch(getAllEvents(data))
          }else{
            dispatch(failedgettingEvents(res?.error))             
          }
        } catch (error) {
          console.error(error);
          dispatch(failedgettingEvents(error))          
        }
        
      }
}

export const startDeleteEventThunk=(uid:string)=>{
  return async (dispatch: AppDispatch) => {
    dispatch(delitingEvent())
    try {
      const res : ApiResponse = await deleteEventProvider(uid);

      if (res?.ok) {
        const { data } = res;
        dispatch(deletedEventById(data))
        return {
          ok:true
        }
      }else{
        dispatch({
          type : failedgettingEvents,
          payload : res?.error
        })        
        return {
          ok:false
        }
      }
    } catch (error) {
      console.error(error);
      dispatch(faileddeleted(error))      
    }
    
  }
}

export const startEventUpdateThunk =( formState : any, navigate: any,uidEvent :string|null) => {
  return async (dispatch: AppDispatch) => {
    dispatch(updatingEvent())
    try {
      const res = await updateEventProvider(formState, navigate,uidEvent);

      if (res?.ok) {
        const { responsee } = res;
        dispatch({
          type: eventUpdated.type,
          payload: responsee
        })
        navigate('/user/eventos');
        return {
          ok:true
        }
      } else {
        dispatch({
          type: failedUpdateEvent,
          payload: res?.error
        })        
        return {
          ok:false
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
} 

export const createEventThunk =(formState : POST_EVENTO_BASICO)=>{
  return async (dispatch: AppDispatch) => {
      dispatch({
        type : creatingEvent.type
      })
      try {
        const res : ApiResponse = await createEventProvider(formState);
  
        if (res?.ok) {
          const { data } = res;
          dispatch({
            type: eventCreated.type,
            payload : data
          })
          return {
              ok : true
          }
        }else{
          dispatch({
            type : failedCreateEvent.type,
            payload : res?.error
          })
          return {
              ok : false
          }
        }
      } catch (error) {
        console.error(error);
        dispatch(failedCreateEvent(error))        
      }
      
    }
}

export const addAnUserToEventThunk = (eventId: string | undefined, userUid: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: addingUser?.type
    })

    try {
      const res: ApiResponse = await addAnUserToEventProvider(eventId, userUid);

      if (res?.ok) {
        const { data } = res;
        dispatch({
          type: userAdded?.type,
          payload: data
        })
        return {
          ok: true
        }
      } else {
        dispatch({
          type: failedAddUser?.type,
          payload: res?.error
        })
        return {
          ok: false
        }
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: failedAddUser?.type,
        payload: error
      })      
    }
  }
}