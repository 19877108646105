import React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';

import Chart  from '../components/Chart'
import ChartSuperAdmin from '../atoms/ChartSuperAdmin';
export const ChartsScreen = () => {
  //const roles = clientUsers.usuarios.map((user) => user.role);
  const role = useSelector((state: RootState) => state.auth.role);
  if (role === 'ADMIN' || role === 'EXPERTO_EN_EL_AREA') {
    return <Chart/>;
  } else if (role === 'MANAGEMENT-SERVICE-ROLE' || role === 'SUPER_ADMIN') {
    return <ChartSuperAdmin />
  } else {
    return <div>No se ha seleccionado un rol válido.</div>;
  }
}
