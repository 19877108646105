import { AppDispatch } from "../store";
import { getCategorias,
    getCategoriaById, 
    getAllCategoriasSlice,
    getCategoriesByIdSlice,
    gettingCategories,
    getAllCategorias,
    failedgettingCategorias,
    failedgettingCategoriasById,
    getSpecificCategoria,
    gettingCategoryById,
    createCategoryProvider,
    deleteCategoryProvider,
    updateCategoryProvider,
    deleteCategoryById,
    faileddeleted,
    delitingCategory,
    failedUpdateCategory,
    categoryUpdated,
    updatingCategory,
    failedCreateCategory,
    categoryCreated,
    creatingCategory
} from './index';
import { AxiosResponse } from 'axios';
import { POST_CATEGORY } from "../../interfaces/interfaces";
// import { getCategorias, createCategoryProvider } from './provider';
//import { gettingCategories } from "./slides";

// El type especifica cómo luce la info que recimos de la respuesta del provider
type ApiResponse = {
    ok: boolean;
    data?: AxiosResponse;
    // response?: AxiosResponse<{}, string>[];
    error?: any;
  }; 

export const getAllcategoriasThunk =()=>{
    return async (dispatch: AppDispatch) => {
        dispatch(gettingCategories())
        try {
          const res : ApiResponse = await getCategorias();
    
          if (res?.ok) {
            const { data } = res;
            dispatch(getAllCategorias(data))
          }else{
            dispatch(failedgettingCategorias(res?.error))            
          }
        } catch (error) {
          console.error(error);
          dispatch(failedgettingCategorias(error))          
        }
        
      }
}

export const getCategoriaThunkById =(id :string)=>{
    return async (dispatch: AppDispatch) => {
        dispatch(gettingCategoryById())
        try {
          const res : ApiResponse = await getCategoriaById(id);
    
          if (res?.ok) {
            const { data } = res;
            dispatch(getSpecificCategoria(data))
          }else{
            dispatch(failedgettingCategoriasById(res?.error))            
          }
        } catch (error) {
          console.error(error);
          dispatch(failedgettingCategoriasById(error))          
        }
        
      }
}

export const createCategoryThunk =(nombre : POST_CATEGORY)=>{
  return async (dispatch: AppDispatch) => {
      dispatch({
        type : creatingCategory.type
      })
      try {
        const res : ApiResponse = await createCategoryProvider(nombre);
  
        if (res?.ok) {
          const { data } = res;
          dispatch({
            type: categoryCreated.type,
            payload : data
          })
        }else{
          dispatch({
            type : failedCreateCategory.type,
            payload : res?.error
          })            
        }
      } catch (error) {
        console.error(error);
        dispatch(failedCreateCategory(error))        
      }
      
    }
}

export const deleteCategoryThunk = (id :string) => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type : delitingCategory?.type
    })
    try {
      const res : ApiResponse = await deleteCategoryProvider(id);

      if (res?.ok) {
        const { data } = res;
        dispatch({
          type: deleteCategoryById?.type,
          payload : data
        })
      }else{
        dispatch({
          type : faileddeleted?.type,
          payload : res?.error
        })    
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type : faileddeleted?.type,
        payload : error
      })      
    }
    
  }
}

export const updateCategoryThunk = (id :string, nombre :string) => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type : updatingCategory?.type
    })
    try {
      const res : ApiResponse = await updateCategoryProvider(id, nombre);

      if (res?.ok) {
        const { data } = res;
        dispatch({
          type: categoryUpdated?.type,
          payload : data
        })
        return {
          ok:true
        }
      }else{
        dispatch({
          type : failedUpdateCategory?.type,
          payload : res?.error
        })
        return {
          ok:false
        }
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type : failedUpdateCategory?.type,
        payload : error
      })
    }
    
  }
}
