import { ApiResponse } from "../../types/types";
import { AppDispatch } from "../store";
import { deleteUsersProvider } from "./provider";
import { deletedUserById, delitingUser, faileddeletedusers } from "./slides/deleteUsersSlice";



export const startDeleteUsersThunk=(uid:string)=>{
  return async (dispatch: AppDispatch) => {
  
    dispatch(delitingUser())
    try {
      const res : ApiResponse = await deleteUsersProvider(uid);

      if (res?.ok) {
        const { data } = res;
        
        dispatch({
          type : deletedUserById.type,
          payload : data
        }) 

      
      }else{
        dispatch({
          type : faileddeletedusers,
          payload:res?.error
        })         
      }
    } catch (error) {
      console.error(error);
      dispatch(faileddeletedusers(error))      
    }
    
  }
}