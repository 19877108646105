
import { createSlice } from '@reduxjs/toolkit';
import { SALES_RECORDS } from '../../../interfaces/interfaces';



export const getSalesRecordsSlide = createSlice({
    name : "salesRecord",
    initialState : {
        status : 'initial', 
        msg : null,
        total : null,
        numberOfProductsOrServicesSold : null,
        totalMount : null,
        sales : [] as SALES_RECORDS[],
        BackendErrorMessage : null
    },
    reducers : {
        failedgettingRecords: ( state, { payload } ) => {
            state.status = 'failed'; 
            state.msg = payload?.error;
            state.total = null;
            state.numberOfProductsOrServicesSold = null;
            state.totalMount = null;
            state.sales = [];
            state.BackendErrorMessage = payload;
        },
        getAllSalesRecords: ( state, { payload } ) => {
            state.status = 'success'; 
            state.msg = payload?.msg; 
            state.total = payload?.total;
            state.numberOfProductsOrServicesSold = payload?.numberOfProductsOrServicesSold;
            state.totalMount = payload?.totalMount;
            state.sales = payload?.sales;
            state.BackendErrorMessage = null;
        },
        gettingRecords : ( state )=>{
            state.status = 'in-progress'
        }
    }
});

export const { failedgettingRecords,getAllSalesRecords,gettingRecords} = getSalesRecordsSlide.actions;