import * as React from 'react';
import { useSelector } from 'react-redux';

import { Link as RouterLink } from 'react-router-dom';
import { Box, Grid, Link, Typography } from '@mui/material';

import { RootState } from '../store';
import { numeroAMes } from '../constants/ConstantsFlexSolutionManager';
import { MesesKeys } from '../types/types';

import Title from './Title';
import { LoadingAtom } from './LoadingAtom';


export default function Sales() {
  const data = useSelector((state: RootState) => state?.getUserAdminTrafficReportSlide?.traffic);
  const month = data?.busiestMonth && numeroAMes[data?.busiestMonth.toString() as MesesKeys]

  return (
    <>
      { data?.averageNumberOfVisitsPerMonth === undefined ? (
        <LoadingAtom />
      ) : (
        <>
          <Title>Promedio visitas mensual</Title>
          <Grid container spacing={ 2 } p={1}>
            <Grid item xs={ 12 } sm={ 6 }>
              <Typography component="p" variant="h5">
                { data?.averageNumberOfVisitsPerMonth.toFixed(2) }
              </Typography>
              <Typography color="text.secondary">
                Visitantes por mes
              </Typography>
            </Grid>
            <Grid item xs={ 12 } sm={ 6 }>
                <Typography component="p" variant="h5">
                { month }
              </Typography>
              <Typography color="text.secondary">
                Mes más visitado
              </Typography>
            </Grid>
          </Grid>
          <Box p={1} display='flex' height='100%' alignItems='flex-end' justifyContent='flex-end'>
            <Link
              color="primary"
              component={ RouterLink }
              to="/detallesadmin"  // Ruta del componente al que quieres navegar
            >
              Detalles
            </Link>
          </Box>
        </>
      ) }
    </>
  );
}