/**
 * include all the refinery process from the user data 
 */

import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { POST_EDIT_USER } from "../interfaces/interfaces";
import { RootState } from "../store";
import { getTimeZoneFromProduct, getTimeZoneFromUser } from "../utils/timeZoneParser";
/**
 * 
 * @returns refinery función que permite la refinería de datos sobre getClientes y detailsInfo
 * @returns data objeto que contine la data ya parseada del usuario que 
 * se quiere editar
 * @returns initialFormConfig object > Contiene el estado inicial de la data qué está
 * permitida editar, dependiendo el role del usuario.
 */
export const useRefinery = () => {
    const { id, role } = useParams();
    const timeZoneData = getTimeZoneFromUser();
    const refinery = useCallback((users: any[]): any | null => {
        if (!users) {
            return null;
        }

        const user = users.find((usr: any) => usr?.uid === id);

        if (user) {
            return {
                nombre: user?.nombre,
                apellidoPaterno: user?.apellidoPaterno,
                apellidoMaterno: user?.apellidoMaterno,
                direccion: user?.direccion,
                email: user?.email,
                fechaInicioPlan: user?.fechaInicioPlan,
                fechaNacimiento: user?.fechaNacimiento,
                role: user?.role,
                pagoDeInscripcion: user?.pagoDeInscripcion,
                planMensualTipo: user?.planMensualTipo,
                planMensualMonto: user?.planMensualMonto,
                salarioMensual: user?.salarioMensual,
                nombreEmpresa: user?.nombreEmpresa,
                phoneNumber: user?.phoneNumber
            };
        }

        return null;
    }, []);

    const initialFormConfig = {
        CLIENTE: {
            nombre: '',
            apellidoPaterno: '',
            apellidoMaterno: '',
            email: undefined,
            phoneNumber: '',
            direccion: '',
            fechaNacimiento: '',
            pagoDeInscripcion: '',
            planMensualMonto: '',
            timeZone: {
                identifier: timeZoneData.userTimeZone,
                offset: timeZoneData.userOffset
            },
        },
        ADMIN: {
            nombre: '',
            apellidoPaterno: '',
            apellidoMaterno: '',
            email: '',
            phoneNumber: '',
            direccion: '',
            salarioMensual: '',
            fechaNacimiento: '',
            timeZone: {
                identifier: timeZoneData.userTimeZone,
                offset: timeZoneData.userOffset
            },
        },
        EXPERTO_EN_EL_AREA: {
            nombre: '',
            apellidoPaterno: '',
            apellidoMaterno: '',
            email: '',
            phoneNumber: '',
            direccion: '',
            salarioMensual: '',
            fechaNacimiento: '',
            timeZone: {
                identifier: timeZoneData.userTimeZone,
                offset: timeZoneData.userOffset
            },
        },
        SUPER_ADMIN: {
            nombreEmpresa: '',
            email: '',
            phoneNumber: '',
            direccion: '',
            fechaNacimiento: '',
            timeZone: {
                identifier: timeZoneData.userTimeZone,
                offset: timeZoneData.userOffset
            },
        }
    };

    const timeZoneDataProduct = getTimeZoneFromProduct();
    const refineryProduct = useCallback((products: any[]): any | null => {
        if (!products) {
            return null;
        }

        const product = products.find((product: any) => product?.uid === id);

        if (product) {
            return {
                nombre: product?.nombre,
                categoria: product?.categoria,
                descripcion: product?.descripcion,
                disponible: product?.disponible,
                stock: product?.stock,

            };
        }

        return null;
    }, []);

    const initialFormProductConfig = {
        PRODUCT: {
            nombre: '',
            categoria: '',
            descripcion: '',
            disponible: '',
            stock: '',
        }
    }

    return {
        refinery,
        initialFormConfig,
        refineryProduct,
        initialFormProductConfig
    }
}
