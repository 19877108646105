import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { config } from '../config'
import { useEffect } from 'react';
import { stagingController } from '../utils/stagingController';
import { developmentConfig } from '../developmentConfig';

export const Copyright = (props: any) => {
  let volcanoWebUrl;

  useEffect(() => {
    let host = stagingController()
    host?.localhost ? volcanoWebUrl = developmentConfig?.REACT_APP_DEV_VOLCANOTECH_URL : volcanoWebUrl = config?.REACT_APP_PROD_VOLCANOTECH_URL
  }, [])

  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link style={{ textDecoration: 'none' }} color="inherit" href={`${volcanoWebUrl}`}>
        VolcanoTech
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}