import { FormEvent } from "react";
import type { FormEditConfigurationProps } from "../interfaces/interfaces"

import { Box, TextField, Button } from "@mui/material"

import { startUserEditThunk } from "../store/usuarios";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import { useNavigate } from "react-router-dom";

import { openModal } from "../store/modal";
import { config } from "../config";

const FormEditConfiguration = ({ form, email, inputs, uid, onResetForm, onInputChange }: FormEditConfigurationProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const formatDateString = (dateString: string) => {
    if (!dateString) return '';  // Manejar el caso en que la cadena sea nula o indefinida

    const date = new Date(dateString);
    const formattedDate = date.toISOString().split('T')[0];
    return formattedDate;
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (form.email === email) delete (form.email);
    const useToResetForm = await dispatch(startUserEditThunk(form, navigate, uid as string));
    if (useToResetForm?.ok === true) {
      onResetForm();
      dispatch({
        type: openModal?.type,
        payload: config.MODAL_TYPE_EDIT_USER
      });
    }
  };

  return (
    <form onSubmit={ (e) => { handleSubmit(e) } } style={ { display: 'flex', flexWrap: 'wrap', flexDirection: 'column', width: '100%' } }>
      <Box>
        {
          inputs.map((input, index) => {
            return (
              <Box display='flex' key={ index }>
                <TextField
                  fullWidth
                  label={ input.title }
                  name={ input.name }
                  value={ input.name === 'fechaNacimiento' ? formatDateString(form[input.name]) : form[input.name] || '' }
                  onChange={ onInputChange }
                  type={ input.name === 'fechaNacimiento' ? 'date' : 'text' }
                  variant="outlined"
                  style={ { margin: "10px 0" } }
                  key={ index }
                />
              </Box>
            )
          })
        }

        <Box display='flex' justifyContent='flex-end'>
          <Button
            color="primary"
            type="submit"
          >
            Actualizar
          </Button>
        </Box>
      </Box>
    </form>
  )
}

export default FormEditConfiguration