import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { getAllEventsThunk } from '../store/eventos/index'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { DateTime } from 'luxon';

import Title from '../atoms/Title';

import { usePagination } from '../customhooks/usePagination';
import { PaginationAtom } from '../atoms/PaginationAtom';
import EventosActions from '../atoms/EventosActions';

import { LoadingAtom } from '../atoms/LoadingAtom';
import { useUsersActions } from '../customhooks/useUsersActions';
import { SnackBar } from '../atoms/SnackBar';
import { Chip, Grid, Paper } from '@mui/material';
import { CreateButton } from '../atoms/CreateButton';
import { openModal } from '../store/modal';
import { FormEventos } from './FormEventos';
import { Notifications } from '../atoms/Notifications';
import { FormEventosAddAnUser } from './FormEventosAddAnUser';
import { config } from '../config';
import { EVENTS } from '../interfaces/interfaces';

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}
const headerCellStyle = {
  fontWeight: 'bold',
  minWidth: 180,
}

export const Eventos = React.memo(() => {
  const [eventId, setEventId] = useState<string | undefined>('');
  //pagination
  const { page, usersPerPage, handleChangePage, startIndex, endIndex } = usePagination();
  const { handleEventEdit, EventDelete, handleDelete, handleRegisterUser, RederiqDetailsEvents , createEvent} = useUsersActions();
  //globalState
  const getEventos = useSelector((state: RootState) => state?.eventos);
  const backendError = useSelector((state: RootState) => state?.eventos?.BackendErrorMessage);
  const handleBackendErrorsAboutEventUpate = useSelector((state: RootState) => state?.updateEvent);
  const eventHandler = useSelector((state: RootState) => state?.postEvent);
  const handlerUserAdd = useSelector((state: RootState) => state?.eventosUsuarios);
  //response failed
  let res = (getEventos.status === 'failed') ? <SnackBar status={getEventos?.status} severity='error' errorMsg={backendError} /> : (<TableRow><TableCell colSpan={10} align="center"><LoadingAtom /></TableCell></TableRow>)
  const eventsHandler = useSelector((state: RootState) => state.deletedEvent);
  const dispatch = useDispatch<AppDispatch>();

  const handlerClickRegister = (eventId: string | undefined) => {
    setEventId(eventId);
    handleRegisterUser(config.MODAL_TYPE_ADD_USER);
  }

  useEffect(() => {
    dispatch(getAllEventsThunk());
  }, [])

  return (
    <React.Fragment>
      <FormEventosAddAnUser modalType= { config.MODAL_TYPE_ADD_USER } eventId={eventId} />
      <CreateButton name='Evento' onClick={ createEvent } />
      {/* modal formEventos */}
      <FormEventos />
      <Grid item xs={12} md={12} lg={12} >
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflowX: 'auto' }}>
          <Title>Eventos</Title>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell sx={headerCellStyle}>Uid</TableCell>
                <TableCell sx={headerCellStyle}>Nombre</TableCell>
                <TableCell sx={headerCellStyle}>Descripcion</TableCell>
                <TableCell sx={headerCellStyle}>Fecha inicio</TableCell>
                <TableCell sx={headerCellStyle}>Fecha fin</TableCell>
                <TableCell sx={headerCellStyle}>Estado</TableCell>
                <TableCell sx={headerCellStyle}>Cupo máximo</TableCell>
                <TableCell sx={headerCellStyle}>Registrados</TableCell>
                <TableCell sx={headerCellStyle} align="right">Opciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getEventos && getEventos?.status === 'success' ? (
                getEventos.eventos.slice(startIndex, endIndex).filter((evento : EVENTS) => evento?.estado === true).map((evento : EVENTS) => (
                  <TableRow key={evento?._id}>
                    <TableCell>{(evento?._id) ? <Chip label={evento?._id} color="primary" variant="outlined" /> : <Chip label={evento?._id} color="secondary" variant="outlined" />}</TableCell>
                    <TableCell>{evento?.nombre}</TableCell>
                    <TableCell>{evento?.descripcion}</TableCell>
                    <TableCell>{
                      DateTime.fromISO(evento?.fecha_inicio).setZone(evento?.timeZone?.identifier).setLocale('es').toLocaleString({
                        year: 'numeric',
                        month: 'long',
                        day: '2-digit'
                      })  
                    }</TableCell>
                    <TableCell>{                      
                      DateTime.fromISO(evento?.fecha_fin).setZone(evento?.timeZone?.identifier).setLocale('es').toLocaleString({
                        year: 'numeric',
                        month: 'long',
                        day: '2-digit'
                      })  
                    }</TableCell>
                    <TableCell>{(evento?.estado !== false) ? <Chip label='ACTIVO' color="success" variant="outlined" /> : <Chip label='ELIMINADO' style={{ color: '#ff0000', border: '#ff0000 1px solid' }} variant="outlined" />}</TableCell>
                    <TableCell>{`${evento?.cupo_maximo} Personas`}</TableCell>
                    <TableCell>{`${evento?.participantes?.length} Registrados`}</TableCell>
                    <TableCell align="right">
                      <EventosActions
                        onEdit={() => handleEventEdit(evento?._id)}
                        onDelete={() => {
                          if (evento?._id) {
                            EventDelete(evento._id);
                            dispatch(getAllEventsThunk());
                          }
                        }}
                        addAnUser={() => handlerClickRegister(evento?._id)}
                        details={() => RederiqDetailsEvents(evento?._id)}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : res}
            </TableBody>
          </Table>
          {/* <Pagination count={Math.ceil(clientUsers.usuarios.length / usersPerPage)} page={page} onChange={handleChangePage} color="primary" shape="rounded" style={{ marginTop: '10px'}}/> */}
          <PaginationAtom count={Math.ceil(getEventos.eventos.length / usersPerPage)} page={page} onChange={handleChangePage} color="primary" shape="rounded" style={{ marginTop: '10px' }} />
        </Paper>
      </Grid>      
      <Notifications handler={eventsHandler} actionName='evento' actionType='Borrando' />
      <Notifications handler={eventHandler} actionName='evento' actionType='Registrando' />
      <Notifications handler={handleBackendErrorsAboutEventUpate} actionName='evento' actionType='Actualizando' />
      <Notifications handler={ handlerUserAdd } actionName='evento' actionType='Registrar usuario' />
    </React.Fragment>
  );
})