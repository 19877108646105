import React, { FormEvent, useState, useEffect, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import _ from 'lodash';

import { useForm } from '../customhooks/useForm';
import { FormControlLabel, Paper, Switch } from '@mui/material';
import { POST_EDIT_USER } from '../interfaces/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../store/store';

import Title from '../atoms/Title';
import { SnackBar } from '../atoms/SnackBar';
import { getTimeZoneFromUser } from '../utils/timeZoneParser';
import { startUserEditThunk } from '../store/usuarios/index';
import { useFormStateValidation } from '../customhooks/useFormStateValidation';
import { useRefinery } from '../customhooks/useRefinery';


const paperStyle = {
  margin: 'auto auto auto 30px',
  borderRadius: '10px',
  width: '100%',
  marginTop: '25px',
};
const textFieldStyle = {
  marginBottom: '20px',
  marginTop: '10px',
  width: '100%'
};

export const FormUserEdit = React.memo(() => {
  //toggle 
  const [personalInfoEnabled, setPersonalInfoEnabled] = useState(false);
  const [contactInfoEnabled, setContactInfoEnabled] = useState(false);
  const [planDetailsEnabled, setPlanDetailsEnabled] = useState(false);

  const handleTogglePersonalInfo = () => {
    setPersonalInfoEnabled(!personalInfoEnabled);
  };

  const handleToggleContactInfo = () => {
    setContactInfoEnabled(!contactInfoEnabled);
  };

  const handleTogglePlanDetails = () => {
    setPlanDetailsEnabled(!planDetailsEnabled);
  };


  const timeZoneData = getTimeZoneFromUser();
  const { id, role } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const handleBackendErrorsAboutUserEdit = useSelector((state: RootState) => state?.editUser);
  const usrRole = useSelector((state: RootState) => state?.auth?.role);

  const usrData = useSelector((state: RootState) => state?.detailsinfo?.usuarios);
  const clientUsers = useSelector((state: RootState) => state?.getClients.usuarios);
  const mgmtServiceRole = useSelector((state : RootState) => state?.managementService?.usuarios );

  const { refinery , initialFormConfig } = useRefinery();
  const [refinedData, setRefinedData] = useState(null);
  
  //initial form config
  let userRoleToEdit: keyof typeof initialFormConfig | undefined | string;
  if (!role) {
    alert('No se pudo encontrar el rol del usuario que intentas editar. Vuelve a intentarlo más tarde');

  }
  userRoleToEdit = role;  
  const initialFormData: any = userRoleToEdit
    ? { ...initialFormConfig[userRoleToEdit] }
    : {};

  if (timeZoneData) {
    initialFormData.timeZone = {
      identifier: timeZoneData.userTimeZone,
      offset: timeZoneData.userOffset
    };
  }
  const { onInputChange, formState, onResetForm } = useForm(initialFormData);
  const { error, fieldFormatError, dateBorn, dateBornError, errorNumber, handleEmail, dateValidation, validateNumber , gralError } = useFormStateValidation(formState);
  
  useEffect(() => {    
    const getRefinedData = () => {
      if ( usrRole === 'SUPER_ADMIN' ) {
        return refinery(usrData);
      } else if ( usrRole === 'ADMIN' ){
        return refinery( clientUsers );
      }else if( usrRole === 'MANAGEMENT-SERVICE-ROLE' ){
        return refinery( mgmtServiceRole );
      }else {
        return null;
      }
    };

    const newData = getRefinedData();
    setRefinedData(newData);
  }, [usrRole, usrData, clientUsers, refinery]);

  
  useEffect(() => {
    const email = formState?.email
    const dateBorn = formState?.fechaNacimiento    
    validateNumber( formState?.planMensualMonto as string, 'planMensualMonto');
    validateNumber( formState?.pagoDeInscripcion as string, 'pagoDeInscripcion' );
    validateNumber( formState?.salarioMensual as string, 'salarioMensual');
    validateNumber( formState?.phoneNumber as any, 'phoneNumber');
    
    handleEmail(email as string)
    dateValidation(dateBorn as string)
  }, [ formState.email, formState.fechaNacimiento, formState?.planMensualMonto, formState?.pagoDeInscripcion , formState?.phoneNumber , formState?.salarioMensual ]);

  
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    
    // Filtra los campos de acuerdo a los toggles activados
    const formDataToSend = { ...formState };
    if(usrRole === 'MANAGEMENT-SERVICE-ROLE' && contactInfoEnabled){
      delete formDataToSend?.nombreEmpresa;
      //delete formDataToSend?.fechaNacimiento;
    }
    if (!personalInfoEnabled) {
      delete formDataToSend.nombre;
      delete formDataToSend.apellidoPaterno;
      delete formDataToSend.apellidoMaterno;
    }
    if (!contactInfoEnabled) {
      delete formDataToSend.direccion;
      delete formDataToSend.fechaNacimiento;
      delete formDataToSend.email;
      delete formDataToSend.phoneNumber;      
    }
    if (!planDetailsEnabled) {
      delete formDataToSend.pagoDeInscripcion;
      delete formDataToSend.planMensualMonto;
      delete formDataToSend.salarioMensual;
      delete formDataToSend.fechaInicioPlan;      
    }
    
    // Envía los datos actualizados al backend    
    handleEmail(formDataToSend.email as string);    
    const useToResetForm = await dispatch(startUserEditThunk(formDataToSend, navigate, id as string));
    if (useToResetForm?.ok === true) {
      onResetForm();
    }
  };
  
  return (
    <Paper style={paperStyle}>
      <Container maxWidth="lg">
        <Box mt={3}>
          <Title>Seleccione sobre que apartado desea editar</Title>
          <form onSubmit={(e) => handleSubmit(e)} style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', width: '100%' }}>
            <FormControlLabel
              control={<Switch checked={personalInfoEnabled} onChange={handleTogglePersonalInfo} />}
              label="Información Personal"
            />
            {personalInfoEnabled && (
              <>
                {/* Campos de información personal */}
                <TextField
                  fullWidth
                  label="usuario uid"
                  name="_id"
                  value={id}
                  onChange={onInputChange}
                  variant="outlined"
                  // placeholder={(uidEvent !== undefined)&&uidEvent}
                  placeholder={id}
                  style={textFieldStyle}
                  required
                />
                {
                  (role === "SUPER_ADMIN") && <>
                    <TextField
                      fullWidth
                      label="Nombre de la Empresa"
                      name="nombreEmpresa"
                      value={formState?.nombreEmpresa}
                      onChange={onInputChange}
                      variant="outlined"
                      type="text"
                      style={textFieldStyle}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={refinedData?.nombreEmpresa}
                    />
                  </>
                }
                {
                  (role === "CLIENTE" || role === "ADMIN") && <>
                    <TextField
                      fullWidth
                      label={role === 'CLIENTE' ? "Nombre del usuario" : "Nombre del empleado"}
                      name='nombre'
                      value={formState?.nombre}
                      onChange={onInputChange}
                      variant="outlined"
                      style={textFieldStyle}
                      placeholder={refinedData?.nombre as string}
                    />
                    <TextField
                      fullWidth
                      label="Apellido paterno"
                      name='apellidoPaterno'
                      value={formState?.apellidoPaterno}
                      onChange={onInputChange}
                      variant="outlined"
                      style={textFieldStyle}
                      placeholder={refinedData?.apellidoPaterno}
                    />
                    <TextField
                      fullWidth
                      label="Apellido materno"
                      name='apellidoMaterno'
                      value={formState?.apellidoMaterno}
                      onChange={onInputChange}
                      variant="outlined"
                      style={textFieldStyle}
                      placeholder={refinedData?.apellidoMaterno}
                    />
                  </>
                }
              </>
            )}

            <FormControlLabel
              control={<Switch checked={contactInfoEnabled} onChange={handleToggleContactInfo} />}
              label="Datos de Contacto"
            />
            {contactInfoEnabled && (
              <>
                {/* Campos de datos de contacto */}
                <TextField
                  fullWidth
                  label="Direcciòn"
                  name="direccion"
                  value={formState.direccion}
                  onChange={onInputChange}
                  variant="outlined"
                  type="text"
                  multiline
                  rows={2}
                  style={textFieldStyle}
                  placeholder={refinedData?.direccion}
                />
                <TextField
                  fullWidth
                  label="Fecha de nacimiento"
                  name="fechaNacimiento"
                  value={formState?.fechaNacimiento}
                  onChange={onInputChange}
                  variant="outlined"
                  type="date"
                  style={textFieldStyle}
                  error={dateBornError}
                  helperText={
                    dateBornError && dateBorn
                  }
                  InputLabelProps={{
                    shrink: true, // Ajusta la posición de la etiqueta
                  }}
                  placeholder={refinedData?.fechaNacimiento}
                />
                <TextField
                  fullWidth
                  label="email"
                  name="email"
                  value={formState?.email}
                  onChange={onInputChange}
                  variant="outlined"
                  style={textFieldStyle}
                  placeholder={refinedData?.email}
                  error={fieldFormatError}
                  helperText={
                    fieldFormatError && error
                  }
                />
                <TextField
                  fullWidth
                  label="Número de teléfono"
                  name='phoneNumber'
                  value={formState?.phoneNumber}
                  onChange={onInputChange}
                  variant="outlined"
                  style={textFieldStyle}
                  type="number"
                  placeholder={refinedData?.phoneNumber}                  
                  error={errorNumber?.phoneNumber?.error}
                  helperText={errorNumber?.phoneNumber?.textMessage}
                />
              </>
            )}

            <FormControlLabel
              control={<Switch checked={planDetailsEnabled} onChange={handleTogglePlanDetails} />}
              label="Detalles del Plan"
            />
            { planDetailsEnabled && (
              <>
                {/* Campos de detalles del plan */}
                {
                  (role === "CLIENTE") && <>
                    <TextField
                      fullWidth
                      label="Pago De Inscripción"
                      name='pagoDeInscripcion'
                      value={formState?.pagoDeInscripcion}
                      onChange={onInputChange}
                      variant="outlined"
                      style={textFieldStyle}
                      type="number"
                      placeholder={refinedData?.pagoDeInscripcion}                                              
                      error={ errorNumber?.pagoDeInscripcion?.error}
                      helperText={errorNumber?.pagoDeInscripcion?.textMessage}
                      required 
                    />
                    <TextField
                      fullWidth
                      label=" $ Monto de plan"
                      name='planMensualMonto'
                      value={formState?.planMensualMonto}
                      onChange={onInputChange}
                      variant="outlined"
                      style={textFieldStyle}
                      type="number"                      
                      placeholder={refinedData?.planMensualMonto}                                      
                      error={errorNumber?.planMensualMonto?.error}
                      helperText={errorNumber?.planMensualMonto?.textMessage}
                      required
                    />
                  </>
                }
                {
                  (role === "ADMIN" || role === "EXPERTO_EN_EL_AREA") && <>
                    <TextField
                      fullWidth
                      label=" Salario Mensual"
                      name='salarioMensual'
                      value={formState?.salarioMensual}
                      onChange={onInputChange}
                      variant="outlined"
                      style={textFieldStyle}
                      type="number"
                      placeholder={refinedData?.salarioMensual}
                      error={errorNumber?.salarioMensual?.error}
                      helperText={errorNumber?.salarioMensual?.textMessage}
                    />
                  </>
                }
                {/* {
                  (role === "SUPER_ADMIN") && <>
                    <TextField
                      fullWidth
                      label="Fecha de Inicio del plan"
                      name="fechaInicioPlan"
                      value={formState?.fechaInicioPlan}
                      onChange={onInputChange}
                      variant="outlined"
                      type="date"
                      style={textFieldStyle}
                      InputLabelProps={{
                        shrink: true, // Ajusta la posición de la etiqueta
                      }}
                      placeholder={refinedData?.fechaInicioPlan}
                    />
                  </>
                } */}
              </>
            )}

            <Button type="submit" variant="contained" color="primary" size="large" fullWidth style={textFieldStyle} disabled = { gralError.includes(true) }>
              Actualizar
            </Button>
          </form>
        </Box>
      </Container>
      {
        (handleBackendErrorsAboutUserEdit.status === 'in-progress') && <SnackBar severity='info' errorMsg='Actualizando usuario'></SnackBar>
      }
      {
        (handleBackendErrorsAboutUserEdit.status === 'failed') && <SnackBar severity='error' errorMsg={handleBackendErrorsAboutUserEdit?.BackendErrorMessage}></SnackBar>
      }
    </Paper>
  );
})

