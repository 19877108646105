import { createSlice } from '@reduxjs/toolkit';

export const postProductSlide = createSlice({
    name : "postProduct",
    initialState : {
        status : 'initial-state',
        msg : null,
        nombre : null,
        categoria: null,
        descripcion:null,
        precio:null,
        stock:null,
        BackendErrorMessage : null
    },
    reducers : {
        failedCreateProduct: ( state, { payload } ) => {
            state.status = 'failed'; 
            state.msg = null;
            state.nombre = null;
            state.categoria = null;
            state.descripcion = null;
            state.precio = null;
            state.stock = null;
            state.BackendErrorMessage = payload;
        },
        productCreated: ( state, { payload } ) => {
            state.status = 'success';
            state.msg = payload.msg;
            state.nombre = payload.nombre;
            state.categoria = payload.categoria;
            state.descripcion = payload.descripcion;
            state.precio = payload.precio;
            state.stock = payload.stock;
            state.BackendErrorMessage = null;
        },
        creatingProduct : ( state )=>{
            state.status = 'in-progress';
        }
    }
});

export const { failedCreateProduct,productCreated,creatingProduct } = postProductSlide.actions;