import Logo from '../img/LOGO.png';
import { PlanCard } from './PlanCard';
import cruz from '../img/cruz.png';
import { config } from '../config';

export const Plans = () => {
    return (
        <div className="w-full h-full p-10 bg-white" >
            <div className='bg-mainBlue w-2/12 p-3'>
                <a href="/">
                    <img src={Logo} alt="" className='w-full' />
                </a>
            </div>
            <div className='w-full mt-5'>
                <p className='font-bold text-5xl text-mainBlue'>Planes Volcano Admin</p>
                <p className='text-lightGray text-xl'>Conoce cada uno de nuestros diferentes planes y los beneficios que te ofrecen <br /> para la gestión de tu negocio.</p>
            </div>

            <div className='flex justify-between mt-4 md:flex-col sm:flex-col'>
                <div className="flex w-3/12 sm:w-full md:w-full md:mb-10 sm:mb-10">
                    <div className="border-lightBlue border flex flex-col items-center p-10 bg-white">
                        <p className='text-mainBlue font-bold mt-4 text-lg'>PLAN BÁSICO</p>
                        <p className='text-mainBlue mt-4 text-lg'>$ { config?.USER_BASICO_COST } { config?.CURRENCY}/mensual </p>
                        <p className='text-mainBlue text-lg'>+</p>
                        <p className='text-mainBlue text-lg'>Inscripción a volcano flex admin</p>
                        <p className='text-center text-lightGray font-bold mt-4 text-lg'>Seguimiento y control de clientes y empleados<span className='text-lightGray font-light'> (registrar, eliminar, actualizar)</span></p>
                        <p className='text-center text-lightGray font-bold mt-4 text-lg'>Plataforma multi role <span className='text-lightGray font-light'>(Tu como usuario maestro puedes crear otros usuario tipo ADMIN ( empleado ), EXPERTO_EN_EL_AREA (Entrenador o profesor) , CLIENTE (usuario asistente al gym o escuela).)</span></p>
                        <p className='text-center text-lightGray font-bold mt-4 text-lg'>Servicio de notificaciones vía email y en el sistema<span className='text-lightGray font-light'>(planes próximos a vencer, registro de clientes, facturas, nuevos clientes, etc.)</span></p>
                        <p className='text-center text-lightGray font-bold mt-4 text-lg'>Seguridad en cuentas <span className='text-lightGray font-light'>(Reporte vía email de anomalías detectadas sobre intentos de acceso a las cuentas de los usuarios.)</span></p>
                        <p className='text-center text-lightGray font-bold mt-4 text-lg'>Análisis de datos básico <span className='text-lightGray font-light'>(Observa los ingresos mensuales, semanales y semestrales sobre membresías de clientes o venta de productos y servicios de tu empresa.)</span></p>
                        <p className='text-center text-lightGray font-bold mt-4 text-lg'>Servicio de búsqueda múltiple <span className='text-lightGray font-light'>(Con nuestro servicio de búsqueda. Encuentra y ten siempre a la mano cualquier producto , servicio , categoría , evento o usuario de tu empresa.)</span></p>
                        <p className='text-center text-lightGray font-bold mt-4 text-lg'> Creación de eventos o sesiones de entrenamiento<span className='text-lightGray font-light'>(Permite a tus empleado o empresa crear eventos. Registrar asistentes al evento y notificar horas antes del evento para que nunca se les olvide.)</span></p>
                        <button className='bg-mainBlue rounded-sm px-3 py-2 mt-4 w-8/12' onClick={() => window.location.href = '/registrar'}>
                            <p className='text-white font-semibold text-lg'>Contratar</p>
                        </button>
                    </div>
                </div>
                <div className="w-3/12 sm:w-full md:w-full md:mb-10 sm:mb-10">
                    <div className="border-lightBlue border flex flex-col items-center p-10 opacity-85 relative">
                        <p className='text-mainBlue font-bold mt-4 text-lg'>PLAN ESENCIAL</p>
                        <p className='text-mainBlue mt-4 text-lg'>$ { config?.USER_ESENCIAL_COST } {config?.CURRENCY}/mensual</p>
                        <p className='text-center text-lightGray font-bold mt-4 text-lg'>Incluye todos los beneficios del plan básico</p>
                        <img src={cruz} alt="" className='mt-5' />
                        <p className='text-center text-lightGray font-bold mt-4 text-lg'>Avisos <span className='text-lightGray font-light'>(Crea y envía notificaciones a tus clientes en tiempo real sobre algún evento o suceso específico)</span></p>
                        <p className='text-center text-lightGray font-bold mt-4 text-lg'>Fidelización de marca</p>
                        <button className='bg-mainBlue rounded-sm px-3 py-2 mt-4 w-8/12'>
                            <p className='text-white font-semibold text-lg'>Contratar</p>
                        </button>
                        <div className='absolute bg-grey-400 opacity-80 flex justify-center items-center w-full h-full top-0'>
                            <p className='text-center text-mainBlue font-bold text-lg'>Por el momento este plan no se encuentra disponible!</p>
                        </div>
                    </div>
                </div>
                <div className="w-3/12 sm:w-full md:w-full md:mb-10 sm:mb-10">
                    <div className="border-lightBlue border flex flex-col items-center p-10 relative">
                        <p className='text-mainBlue font-bold mt-4 text-lg'> PLAN ELITE </p>
                        <p className='text-mainBlue mt-4 text-lg'>$ { config?.USER_ELITE_COST } {config?.CURRENCY}/mensual</p>
                        <p className='text-center text-lightGray font-bold mt-4 text-lg'>Incluye todos los beneficios del plan esencial</p>
                        <img src={cruz} alt="" className='mt-5' />
                        <p className='text-center text-lightGray font-bold mt-4 text-lg'>Integración de inteligencia artificial <span className='text-lightGray font-light'>(Lleva tu negocio al siguiente nivel. Conectamos toda la información de tu negocio con tu propia IA para que trabaje para ti.)</span></p>
                        <button className='bg-mainBlue rounded-sm px-3 py-2 mt-4 w-8/12'>
                            <p className='text-white font-semibold text-lg'>Contratar</p>
                        </button>
                        <div className='absolute bg-grey-400 opacity-80 flex justify-center items-center w-full h-full top-0'>
                            <p className='text-center text-mainBlue font-bold text-lg'>Por el momento este plan no se encuentra disponible!</p>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}
