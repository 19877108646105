import { Avatar, Box } from "@mui/material"
import { DETAILS_INFO } from "../interfaces/interfaces"
import { InfoLabelWithTitle } from "../atoms/InfoLabelWithTitle"
import PersonIcon from '@mui/icons-material/Person';

interface InfoTableAdminProps {
  user: DETAILS_INFO | undefined
}

const InfoTableAdmin = ({ user }: InfoTableAdminProps) => {
  return (
    <Box sx={ { display: 'flex', flexDirection: 'column' } }>
      <Avatar
        alt="User icon"
        src={user?.photoURL}
        sx={ { width: 70, height: 70, alignSelf: 'center', marginTop: '10px' } }
      >
        <PersonIcon sx={ { width: 40, height: 40 } } />
      </Avatar>

      <Box sx={{ padding: '20px' }}>
        <InfoLabelWithTitle title='Nombre' value={ user?.nombre } />
        <InfoLabelWithTitle title='Apellido materno' value={ user?.apellidoMaterno } />
        <InfoLabelWithTitle title='Apellido paterno' value={ user?.apellidoPaterno } />
        <InfoLabelWithTitle title='Email' value={ user?.email } />
        <InfoLabelWithTitle title='Número teléfonico' value={ user?.phoneNumber } />
      </Box>
    </Box>
  )
}

export default InfoTableAdmin