import React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';

import ChartSuperAdmin from '../atoms/ChartSuperAdmin';
import Sales from '../atoms/Sales';
import SalesSuperAdmin from '../atoms/SalesSuperAdmin';
export const SalesScreen = () => {
  //const roles = clientUsers.usuarios.map((user) => user.role);
  const role = useSelector((state: RootState) => state.auth.role);
  if (role === 'ADMIN' || role === 'EXPERTO_EN_EL_AREA') {
    return <Sales/>;
  } else if (role === 'MANAGEMENT-SERVICE-ROLE' || role === 'SUPER_ADMIN') {
    return <SalesSuperAdmin />
  } else {
    return <div>No se ha seleccionado un rol válido.</div>;
  }
}
