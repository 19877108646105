import React, { ChangeEvent, useState, FormEvent, useEffect } from 'react'
import { stagingController } from '../utils/stagingController';
import { developmentConfig } from '../developmentConfig';
import { config } from '../config';

let host = stagingController();
let httpserver;
host?.localhost ? httpserver = developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : httpserver=config?.REACT_APP_PRODUCTIONSERVER

export const useFormValidation = () => {
  const fechaActual = new Date().toISOString().split('T')[0];
  // console.warn(fechaActual);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [emailAdress, setEmailAdress] = useState(''); //campo que tipea usuario
  const [isValidEmailAdress, setIsValidEmailAdress] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [date,setDate] = useState<string>('');
  const [direccion, setDireccion ]=useState('');
  const [selectedRole, setSelectedRole] = useState('SUPER_ADMIN');
  const [selectedLicencia, setSelectedLicencia] = useState('BASICO');
  const [selectedPlanMensualTipo, setSelectedPlanMensualTipo] = useState('ANUAL');
  const [ tenantName, setTenantName ] = useState('');
  const [databaseName, setDatabaseName ] = useState('');

  const handleUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  }
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
  const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };
  const handleEmailAdressChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailAdress(e.target.value);
  }
  const handleDateChange =(e: ChangeEvent<HTMLInputElement>)=>{
    //setDate(e.target.value)
    const nuevaFechaIngresada = e.target.value;
    setDate(nuevaFechaIngresada)
    if (nuevaFechaIngresada > fechaActual) {
      
      // alert('Tu año de nacimiento no puede ser mayor a la fecha actual.');
      setErrorMessage('Tu fecha de nacimiento no puede ser ni el día actual , ni tampoco el año actual .')
      setTimeout(() => {
        setErrorMessage('');
      }, 6000);
      setDate('');
    }else{
      setDate(nuevaFechaIngresada)
    }
  }
  const handleDireccionChange = (e: ChangeEvent<HTMLInputElement>)=> {
    setDireccion(e.target.value)
  }
  const handleRoleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedRole(e.target.value);
  };

  const handleLicenciaChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedLicencia(e.target.value);
  };

  const handlePlanMensualTipoChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedPlanMensualTipo(e.target.value);
  };
  const handledatabaseNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDatabaseName(e.target.value);
  };
  const handleTenantNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTenantName(e.target.value);
  };
  const validateEmailAdress = (e: FormEvent<HTMLFormElement>) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if(emailRegex.test(emailAdress)){
      setIsValidEmailAdress(true);
    }else{
      console.log("El valor ingresado no parece ser una contraseña")
    }
  };

  return {
    handleUsernameChange,
    username,
    setUsername,
    handlePasswordChange,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    handleConfirmPasswordChange,
    errorMessage,
    setErrorMessage,
    showErrorMessage,
    setShowErrorMessage,
    emailAdress,
    setEmailAdress,
    handleEmailAdressChange, 
    validateEmailAdress,
    isValidEmailAdress,
    setIsValidEmailAdress,
    date,
    setDate,
    handleDateChange,
    direccion,
    handleDireccionChange,
    handleRoleChange,
    handleLicenciaChange,
    handlePlanMensualTipoChange,
    selectedRole,
    selectedLicencia,
    selectedPlanMensualTipo,
    handledatabaseNameChange,
    handleTenantNameChange,
    databaseName,
    tenantName
  }
}
