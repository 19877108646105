import { ImportContactsSharp } from '@mui/icons-material';
import React from 'react'
import { ClientsTable } from '../atoms/ClientsTable';
import { ActivosUsuariosComponent } from '../atoms/ActivosUsuariosComponent';
import { ActivosTenantsManagementRole } from '../atoms/ActivosTenantsManagementRole';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

import { FormReNewMembership } from '../components/FormReNewMembership';


export const MultiUsersHandler = () => {
    const role = useSelector((state: RootState) => state.auth.role);

    return (
      <>
        <FormReNewMembership />
        {(role === 'ADMIN' || role === 'EXPERTO_EN_EL_AREA') && <ClientsTable />}
        {role === 'SUPER_ADMIN' && <ActivosUsuariosComponent/>}
        {role === 'MANAGEMENT-SERVICE-ROLE' && <ActivosTenantsManagementRole />}
        {(role !== 'ADMIN' && role !== 'SUPER_ADMIN' && role !== 'MANAGEMENT-SERVICE-ROLE' && role !== 'EXPERTO_EN_EL_AREA' ) && <div>No se ha seleccionado un rol válido.</div>}
      </>
    )
}
