import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { getClients } from '../store/slides/Auth/index'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { DateTime } from 'luxon';

import Title from './Title';

import { usePagination } from '../customhooks/usePagination';
import { PaginationAtom } from './PaginationAtom';
import UserActionsMenu from './UserActionsMenu';
import { LoadingAtom } from './LoadingAtom';
import { useUsersActions } from '../customhooks/useUsersActions';
import { SnackBar } from './SnackBar';
import { ActionConfirmationModal } from './ActionConfirmationModal';
import { Chip } from '@mui/material';
import { Notifications } from './Notifications';

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}
const headerCellStyle = {
  fontWeight: 'bold',
  minWidth: 180,
}

export const ClientsTable = React.memo(() => {
  //pagination
  const { page, usersPerPage, handleChangePage, startIndex, endIndex } = usePagination()
  const { handlUserEdit, handleConfirmDelete, handleOpenModal } = useUsersActions()
  //globalState
  const renewMembershipHandler = useSelector((state: RootState) => state?.renewMembership)
  const clientUsers = useSelector((state: RootState) => state?.getClients);
  const backendError = useSelector((state: RootState) => state?.getClients?.BackendErrorMessage);
  const handleEditUsr = useSelector((state: RootState) => state?.editUser);
  
  //response failed
  let res = (clientUsers.status === 'failed') ? <SnackBar status={clientUsers?.status} severity='error' errorMsg={backendError} /> : (<TableRow><TableCell colSpan={10} align="center"><LoadingAtom /></TableCell></TableRow>)
  const usersHandler = useSelector((state: RootState) => state?.deletedUsers);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getClients());
  }, [usersHandler]); // Observa cambios en usersHandler para actualizar la lista de usuarios

  useEffect(() => {
    dispatch(getClients());
  }, [])

  return (
    <React.Fragment>
      <Title>Clientes</Title>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={headerCellStyle}>Uid</TableCell>
            <TableCell sx={headerCellStyle}>Nombre</TableCell>
            <TableCell sx={headerCellStyle}>Apellido materno</TableCell>
            <TableCell sx={headerCellStyle}>Apellido paterno</TableCell>
            <TableCell sx={headerCellStyle}>Fecha de inicio del plan</TableCell>
            <TableCell sx={headerCellStyle}>Fecha de nacimiento</TableCell>
            <TableCell sx={headerCellStyle}>Rol de usuario</TableCell>
            <TableCell sx={headerCellStyle}> Tipo de plan </TableCell>
            <TableCell sx={headerCellStyle}>Monto del plan </TableCell>
            <TableCell sx={headerCellStyle}> Email </TableCell>
            <TableCell sx={headerCellStyle}>Direccion</TableCell>
            <TableCell sx={headerCellStyle} align="right">Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clientUsers?.status === 'success' && clientUsers?.usuarios ? (
            clientUsers?.usuarios.slice(startIndex, endIndex).filter((user) => user.planStatusActivo === true).map((user) => (
              <TableRow key={user.uid}>
                <TableCell>{<Chip label={user.uid} color="primary" variant="outlined" />}</TableCell>
                <TableCell>{user.nombre}</TableCell>
                <TableCell>{user.apellidoMaterno}</TableCell>
                <TableCell>{user.apellidoPaterno}</TableCell>
                <TableCell>{
                  DateTime.fromISO(user?.fechaInicioPlan as string).setZone(user?.timeZone?.identifier).setLocale('es').toLocaleString({
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit'
                  })
                }</TableCell>
                <TableCell>{
                  DateTime.fromISO(user?.fechaNacimiento).setZone(user?.timeZone?.identifier).setLocale('es').toLocaleString({
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit'
                  })
                }</TableCell>
                <TableCell>{<Chip label='CLIENTE' color="primary" variant="outlined" />}</TableCell>
                <TableCell>{user.planMensualTipo}</TableCell>
                <TableCell>{`$${user.planMensualMonto}`}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{`${user.direccion}`}</TableCell>
                <TableCell align="right">
                  <UserActionsMenu
                    onEdit={() => handlUserEdit(user?.uid as string, user?.role as string)}
                    onDelete={() => handleConfirmDelete(user?.uid as string)}
                    onRenewMembership={() => handleOpenModal(user?.uid as string)}
                  />
                </TableCell>
              </TableRow>
            ))
          ) : res}
        </TableBody>
      </Table>
      {/* <Pagination count={Math.ceil(clientUsers.usuarios.length / usersPerPage)} page={page} onChange={handleChangePage} color="primary" shape="rounded" style={{ marginTop: '10px'}}/> */}
      <PaginationAtom count={Math.ceil(clientUsers?.usuarios?.length / usersPerPage)} page={page} onChange={handleChangePage} color="primary" shape="rounded" style={{ marginTop: '10px' }} />

     
      <Notifications handler={usersHandler} actionName='usuario' actionType='Borrando' />
      <Notifications handler={renewMembershipHandler} actionName='usuario' actionType='Renovando' />
      <Notifications handler={ handleEditUsr } actionName='editarUsuario' actionType='errores' />
    </React.Fragment >
  );
})