import { createSlice } from '@reduxjs/toolkit';
import { DETAILS_INFO } from '../../interfaces/interfaces';

export const detailsInfoSlice = createSlice({
    name : "detailsInfo",
    initialState : {
        status : 'normal', 
        msg : null,
        usuarios : [] as DETAILS_INFO[],
        BackendErrorMessage : null
    },
    reducers : {
        failedDetails: ( state, { payload } ) => {
            state.status = 'failed'; 
            state.usuarios = [];
            state.BackendErrorMessage = payload;
        },
        getDetailsSuccess: ( state, { payload } ) => {
            state.status = 'success';
            state.usuarios = payload.usuarios;
            state.BackendErrorMessage = null;
        },
        inProgressDetails : ( state )=>{
            state.status = 'in-progress...'
        }
    }
});

export const { failedDetails, getDetailsSuccess, inProgressDetails } = detailsInfoSlice.actions;