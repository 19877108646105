import { useEffect, useMemo } from 'react';

import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import BusinessIcon from '@mui/icons-material/Business';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';

import { initialFormConfig } from "../constants/ConstantsFlexSolutionManager"

import { RootState } from "../store";
import { useSelector } from "react-redux";

export const useChooseForm = (role: string | null) => {
  const {
    nombre,
    nombreEmpresa,
    apellidoPaterno,
    apellidoMaterno,
    email,
    phoneNumber,
    direccion,
    salarioMensual,
    fechaNacimiento
  } = useSelector((state: RootState) => state.auth);

  const chooseForm = role ? initialFormConfig[role] : initialFormConfig.ADMIN;
  const isAdmin = role === "ADMIN" || role === "EXPERTO_EN_EL_AREA";

  const formattedFechaNacimiento = useMemo(() => {
    const fecha = new Date(fechaNacimiento ? fechaNacimiento : new Date());
    return fecha.toLocaleDateString('es-ES', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  }, [fechaNacimiento]);

  const filledForm = useMemo(() => {
    return isAdmin
      ? () => {
        chooseForm.nombre = nombre;
        chooseForm.apellidoPaterno = apellidoPaterno;
        chooseForm.apellidoMaterno = apellidoMaterno;
        chooseForm.email = email;
        chooseForm.phoneNumber = phoneNumber;
        chooseForm.direccion = direccion;
        chooseForm.salarioMensual = salarioMensual;
        chooseForm.fechaNacimiento = fechaNacimiento;
      }
      : () => {
        chooseForm.nombreEmpresa = nombreEmpresa;
        chooseForm.email = email;
        chooseForm.phoneNumber = phoneNumber;
        chooseForm.direccion = direccion;
        chooseForm.fechaNacimiento = fechaNacimiento;
      };
  }, [isAdmin, chooseForm, nombre, apellidoPaterno, apellidoMaterno, email, phoneNumber, direccion, salarioMensual, fechaNacimiento, nombreEmpresa]);


  useEffect(() => {
    filledForm();
  }, [filledForm]);

  const showInformationAdmin = [{
    icon: PersonIcon,
    title: "Nombre",
    name: "nombre",
    value: nombre,
  }, {
    icon: PersonIcon,
    title: "Apellido Paterno",
    name: "apellidoPaterno",
    value: apellidoPaterno
  }, {
    icon: PersonIcon,
    title: "Apellido Materno",
    name: "apellidoMaterno",
    value: apellidoMaterno
  }, {
    icon: EmailIcon,
    title: "Email",
    name: "email",
    value: email
  }, {
    icon: LocalPhoneIcon,
    title: "Teléfono",
    name: "phoneNumber",
    value: phoneNumber
  }, {
    icon: CalendarMonthIcon,
    title: "Fecha de Nacimiento",
    name: "fechaNacimiento",
    value: formattedFechaNacimiento
  }, {
    icon: EventRepeatIcon,
    title: "Dirección",
    name: "direccion",
    value: direccion
  }]

  const showInformationSuperAdmin = [
    {
      icon: BusinessIcon,
      title: "Nombre de la empresa",
      name: "nombreEmpresa",
      value: nombreEmpresa
    },
    {
      icon: EmailIcon,
      title: "Correo electrónico",
      name: "email",
      value: email
    },
    {
      icon: LocalPhoneIcon,
      title: "Número de teléfono",
      name: "phoneNumber",
      value: phoneNumber
    },
    {
      icon: CalendarMonthIcon,
      title: "Fecha de nacimiento",
      name: "fechaNacimiento",
      value: formattedFechaNacimiento
    },
    {
      icon: EventRepeatIcon,
      title: "Dirección",
      name: "direccion",
      value: direccion
    }
  ]

  const chooseShowInformation = isAdmin ? showInformationAdmin : showInformationSuperAdmin;

  return {
    chooseForm,
    chooseShowInformation
  }
}