import { ApiResponse } from "../../types/types";
import { AppDispatch } from "../store";
import { PAY_TENANT_SUSCRIPTION } from '../../interfaces/interfaces';
import { 
  getSalesRecordsProvider ,
  failedgettingRecords ,
  getAllSalesRecords ,
  gettingRecords ,
  startTenantPayment,
  failedPayTenant, 
  paymentSuccess, 
  paying,
} from './index'

export const getSalesRecordsThunk =( timeStamp : string )=>{
    return async (dispatch: AppDispatch) => {
        dispatch({
            type : gettingRecords.type
        })
        try {
          const res : ApiResponse = await getSalesRecordsProvider(timeStamp);
    
          if (res?.ok) {
            const { data } = res;
            dispatch({
                type : getAllSalesRecords.type,
                payload : data
            })
          }else{
            dispatch({
                type : failedgettingRecords.type,
                payload : res?.error
            })            
          }
        } catch (error) {
          console.error(error);
          dispatch({
              type : failedgettingRecords.type
          })          
        }
        
      }
} 

export const payTenantSuscription =( { id , licencia, planMensualTipo , custumerEmail , nombreEmpresa , origin } : PAY_TENANT_SUSCRIPTION )=>{
  return async (dispatch: AppDispatch) => {
      dispatch({
        type : paying.type
      })
      try {
        const res : ApiResponse = await startTenantPayment( { id , licencia, planMensualTipo , custumerEmail , nombreEmpresa , origin } );
  
        if (res?.ok) {
          const { data } = res;
          dispatch({
            type: paymentSuccess.type,
            payload : data
          })
          return {
              ok : true,
              securityAction3D : data
          }
        }else{
          dispatch({
            type : failedPayTenant.type,
            payload : res?.error
          })
          return {
              ok : false
          }
        }
      } catch (error) {
        console.error(error);
        dispatch(failedPayTenant(error))        
      }
      
    }
}