import React from 'react'
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { useTheme } from '@mui/material/styles';

export interface BAR_CHART {
    chartData : any,
    time : string,
    amount : string
}
export const BarChartt = ({ chartData , time , amount }:BAR_CHART) => {
    const theme = useTheme();
    return (
        <ResponsiveContainer>            
            <BarChart
                data={chartData}
                margin={{
                    top: 16,
                    right: 16,
                    bottom: 0,
                    left: 24,
                }}
            >
                <XAxis
                    dataKey={ time }
                    stroke={theme.palette.text.secondary}
                    style={theme.typography.body2}
                />
                <YAxis
                    stroke={theme.palette.text.secondary}
                    style={theme.typography.body2}
                />
                <Tooltip />
                <Bar
                    dataKey= { amount }
                    fill={theme.palette.primary.main}
                />
            </BarChart>
        </ResponsiveContainer>
    )
}
