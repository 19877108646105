import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { DateTime } from 'luxon';

import Title from '../atoms/Title';
import { usePagination } from '../customhooks/usePagination';
import { useUsersActions } from '../customhooks/useUsersActions';
import UserActionsMenu from '../atoms/UserActionsMenu';
import { LoadingAtom } from '../atoms/LoadingAtom';
import { PaginationAtom } from '../atoms/PaginationAtom';
import { Grid, Paper } from '@mui/material';
import { getManagementService } from '../store/slides/Auth/thunk';
import { Notifications } from '../atoms/Notifications';

const headerCellStyle = {
  fontWeight: 'bold',
  minWidth: 160,
}

export const InactivosTenantsUsers = React.memo(() => {
  //pagination
  const { page, usersPerPage, handleChangePage, startIndex, endIndex } = usePagination()
  const { handlUserEdit, handleOpenModal, handleConfirmDelete } = useUsersActions()
  //globalState
  const renewMembershipHandler = useSelector((state: RootState) => state?.renewMembership);
  const managementService = useSelector((state: RootState) => state?.managementService);
  const loading = useSelector((state: RootState) => state?.managementService?.status === 'in-progress')
  const usersHandler = useSelector((state: RootState) => state?.deletedUsers);
  const dispatch = useDispatch<AppDispatch>();
  
  useEffect(() => {
    dispatch(getManagementService())
  }, [usersHandler])

  const jsx = (loading) && (<TableRow><TableCell colSpan={10} align="center"><LoadingAtom /></TableCell></TableRow>)

  return (
    <React.Fragment>
      <Grid item xs={12} md={6} lg={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflowX: 'auto' }}>
          <Title>Tenants inactivos o eliminados</Title>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={headerCellStyle}>Uid</TableCell>
                <TableCell sx={headerCellStyle}>Nombre de la Empresa</TableCell>
                <TableCell sx={headerCellStyle}>Tenant Id</TableCell>
                <TableCell sx={headerCellStyle}>Rol</TableCell>
                <TableCell sx={headerCellStyle}>Fecha de inicio del plan</TableCell>
                <TableCell sx={headerCellStyle}>Fecha de expiración</TableCell>
                <TableCell sx={headerCellStyle}>Fecha de nacimiento</TableCell>
                <TableCell sx={headerCellStyle}>Estatus</TableCell>
                <TableCell sx={headerCellStyle}>Licencia</TableCell>
                <TableCell sx={headerCellStyle}>Email</TableCell>
                <TableCell sx={headerCellStyle}>Direccion</TableCell>
                <TableCell sx={headerCellStyle} align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {managementService.status === 'success' ? (
                managementService?.usuarios?.slice(startIndex, endIndex).filter((user) => (user.planStatusActivo === false || user.estado === false)).map((user) => (
                  <TableRow key={user.uid}>
                    <TableCell>{user.uid}</TableCell>
                    <TableCell>{user.nombreEmpresa}</TableCell>
                    <TableCell>{user.tenant}</TableCell>
                    <TableCell>{user.role}</TableCell>
                    <TableCell>{                      
                      DateTime.fromISO(user?.fechaInicioPlan as string).setZone(user?.timeZone?.identifier).setLocale('es').toLocaleString({
                        year: 'numeric',
                        month: 'long',
                        day: '2-digit'
                      })
                    }</TableCell>
                    <TableCell>{                      
                      DateTime.fromISO(user?.fechaExpiracion as string).setZone(user?.timeZone?.identifier).setLocale('es').toLocaleString({
                        year: 'numeric',
                        month: 'long',
                        day: '2-digit'
                      })
                    }</TableCell>
                    <TableCell>{                      
                      DateTime.fromISO(user?.fechaNacimiento as string).setZone(user?.timeZone?.identifier).setLocale('es').toLocaleString({
                        year: 'numeric',
                        month: 'long',
                        day: '2-digit'
                      })
                    }</TableCell>
                    <TableCell>{(user.planStatusActivo === false) ? 'NO HA RENOVADO SU PLAN' : 'ACTIVO'}</TableCell>
                    {/* <TableCell>{(user.planMensualTipo !== undefined) ? `${user.planMensualTipo}` : 'No aplica'}</TableCell> */}
                    <TableCell>{user.licencia}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.direccion}</TableCell>
                    <TableCell align="right">
                      <UserActionsMenu
                        onEdit={() => handlUserEdit( user?.uid as string, user?.role as string )}
                        onDelete={() => handleConfirmDelete(user?.uid as string)}
                        onRenewMembership={() => handleOpenModal(user?.uid as string)}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : jsx}
            </TableBody>
          </Table>    
          <PaginationAtom
            count={Math.ceil(managementService.usuarios.filter(user => (user.planStatusActivo === false || user.estado === false)).length / usersPerPage)}
            page={page}
            onChange={handleChangePage}
            color="primary"
            shape="rounded"
            style={{ marginTop: '10px' }}
          />
        </Paper>
      </Grid>
      
      <Notifications handler={usersHandler} actionName='usuario' actionType='Borrando' />
      <Notifications handler={renewMembershipHandler} actionName='membresía' actionType='Renovando' />

    </React.Fragment>
  )
})
