import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { CREATEBUTTON } from '../interfaces/interfaces';

export const CreateButton = ({ name, onClick }: CREATEBUTTON) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
      <Button
          onClick={onClick}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          sx={{ mt: 3 }}
        >
          Crear {name}
      </Button>
    </Box>
  )
}