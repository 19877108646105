import React from 'react'

import { createSlice } from '@reduxjs/toolkit';

export const renewPlanSlice = createSlice({
    name: 'plan',
    initialState: {
        email: null,
        contrasena: null,
        planMensualTipo: null,
        BackendErrorMessage: null,
        msg: null,
        status :"initial"
    },
    reducers: {
        updatePlanFailed: (state, { payload }) => {
            state.status = "failed";
            state.email = null;
            state.contrasena = null;
            state.planMensualTipo = null;
            state.msg = null;
            state.BackendErrorMessage = payload;
        },
        updatePlanSuccess: (state, { payload }) => {
            state.status = "success";
            state.email = payload.email;
            state.contrasena = payload.contrasena;
            state.planMensualTipo = payload.planMensualTipo;
            state.msg = payload.msg;
            state.BackendErrorMessage = null;
        },
        updatingPlan: ( state ) => {
            state.status = "in-progress";
        },
    },
});

export const { updatePlanFailed, updatePlanSuccess , updatingPlan } = renewPlanSlice.actions;
