import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { requestPasswordReset } from '../store/slides/Auth';
import { AppDispatch } from '../store/store';
import { EMAIL_REQUEST_PASSWORD } from '../interfaces/interfaces';
import { Notifications } from '../atoms/Notifications';

export const ForgotPassword = () => {
  const dispatch = useDispatch<AppDispatch>();
  //const { status, msg, BackendErrorMessage } = useSelector((state: RootState) => state?.recoverPasswordSlice);

  const handlerForgotPasswordStatus = useSelector((state: RootState) => state?.recoverPasswordSlice);

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const email = formData.get('email') as string;
  
    dispatch(requestPasswordReset(email as any));
  };

  return (
    <div className="flex items-center justify-center h-screen bg-white">
      <div className="w-full max-w-md mx-auto p-8 bg-white">
        <h2 className="text-3xl text-center text-mainBlue font-bold mb-6">Recuperar Contraseña</h2>
        <form onSubmit={handleFormSubmit} className="space-y-4">
          <div className="flex flex-col space-y-1">
            <label htmlFor="email" className="text-sm font-semibold text-lightGray">Correo electrónico de recuperación</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="correo@ejemplo.com"
              className="w-full px-4 py-2 rounded border border-lightGray focus:outline-none focus:border-mainBlue"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-mainBlue text-white py-2 rounded hover:bg-opacity-90 transition duration-300"
            // disabled={ status === 'in-progress'}
          >
            {/* {(status === 'in-progress') ? 'Enviando...' : 'Enviar Correo de Restablecimiento'} */}
            Recuperar contraseña
          </button>          
        </form>
        <Notifications handler={handlerForgotPasswordStatus} actionType="forgotPassword" actionName="recoveryPassword"/>
      </div>
    </div>
  );
};
