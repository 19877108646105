//nueva implementaciòn 

import React, { useState } from 'react'
import emailValidator from 'email-validator';
import _ from 'lodash';
import { ERROR_OBJECT, FormState } from '../interfaces/interfaces';

import { DateTime, Duration } from "luxon";
import { ErrorState } from '../types/types';

import { validatePassword } from '../utils/validatefields';

/**
 * Hook personalizado que maneja la validación de formularios.
 * @param {Object} formState - Estado del formulario.
 * @returns {Object} - Objeto que contiene información sobre errores y funciones de validación.
 * @property {string} error - Captura errores en la dirección de correo electrónico.
 * @property {string} errorPassword - Captura errores en la contraseña.
 * @property {boolean} fieldFormatError - Indica si hay errores en el formato de la dirección de correo electrónico.
 * @property {boolean} passwordFormatError - Indica si hay errores en el formato de la contraseña.
 * @property {string} dateBorn - Mensaje de error si la fecha de nacimiento es futura.
 * @property {boolean} dateBornError - Indica si hay un error en la fecha de nacimiento (futura).
 * @property {Object} errorNumber - Estado de un campo de formulario con el nombre del campo, error: true y un mensaje de error.
 * @property {Function} handleEmail - Función para validar una dirección de correo electrónico.
 * @property {Function} handlePassword - Función para validar una contraseña.
 * @property {Function} dateValidation - Función para validar si una fecha es futura.
 * @property {Function} validateNumber - Función que valida la presencia de números negativos.
 * @property {boolean[]} gralError - Arreglo de booleanos que guarda todos los valores booleanos en true de todos los valores del estado mencionados anteriormente. Se utiliza para deshabilitar botones de envío en formularios.
 */

export const useFormStateValidation = (formState: FormState) => {
  const [gralError, setGralError] = useState<boolean[]>([]);
  const [error, setError] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [diffPass, setDiffPass] = useState('');
  const [passwordFormatError, setpasswordFormatError] = useState(false);
  const [comparePasswordError, setComparePasswordError] = useState(false);
  const [fieldFormatError, setfieldFormatError] = useState(false);
  const [dateBornError, setDateBornError] = useState(false);
  const [dateBorn, setDateBorn] = useState('');
  const [errorNumber, setErrorNumber] = useState<ErrorState>({
    pagoDeInscripcion: { field: '', error: false, textMessage: '' },
    planMensualMonto: { field: '', error: false, textMessage: '' },
    salarioMensual: { field: '', error: false, textMessage: '' },
    phoneNumber : { field : '' , error : false , textMessage : ''}
  });


  const updateGralError = () => {
    const newGralError: boolean[] = [
      fieldFormatError,
      passwordFormatError,
      dateBornError,
      ...Object.values(errorNumber).map(fieldError => fieldError.error)
    ];
    setGralError(newGralError);
  };

  const handleEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|mx|net|es|org|gov|edu|co|uk|fr|de|app|blog|guru)$/;

    if (email && email?.length > 0 && !emailValidator.validate(email)) {
      setfieldFormatError(true);
      setError('La dirección de correo electrónico no es válida !!! ');
      gralError.push( fieldFormatError )
    } else if (email && email?.length > 0 && !emailRegex.test(email)) {
      setfieldFormatError(true);
      setError('La dirección de correo electrónico no es válida !!!');      
    } else {
      setfieldFormatError(false);
      setError('');
    }
    updateGralError();
  }
  const handlePassword = (password: string) => {
    const passwordError = validatePassword(password);

    if (passwordError && password.length >1) {
      setpasswordFormatError(true);
      setErrorPassword(passwordError);
    } else {
      setpasswordFormatError(false);
      setErrorPassword('');
    }
    updateGralError();
  }

  const comparePassword = (password: string, confirmPassword: string) => {
    if (password !== confirmPassword) {
      setComparePasswordError(true);
      setDiffPass('Las contraseñas no coinciden');
    } else {
      setComparePasswordError(false);
    }
    updateGralError();
  }

  const dateValidation = (date: string) => {
    const fechaActual = DateTime.now();
    const fechaNacimientoObj = DateTime.fromISO(date);

    if (fechaNacimientoObj.isValid && fechaNacimientoObj.toISODate() === fechaActual.toISODate()) {
      setDateBornError(true);
      setDateBorn('La fecha de nacimiento no puede ser el dia de hoy')
      gralError.push( dateBornError );
    } else if (fechaNacimientoObj.isValid && fechaNacimientoObj > fechaActual) {
      setDateBornError(true);
      setDateBorn('La fecha de nacimiento no puede ser futura');
      gralError.push( dateBornError );
    } else {
      setDateBornError(false);
      setDateBorn('');
    }
    updateGralError();
  };

  /** 
   * @param number 
   * @param field 
   * Toma el estado anterior y actualiza el estado por cada campo sin quitar 
   * el estado anterior
   */
  const validateNumber = (number: string, field: string): void => {
    const parsedNumber = parseFloat(number);
    setErrorNumber(prevErrors => {
      const newErrors: ErrorState = { ...prevErrors };

      if (_.isNumber(parsedNumber) && parsedNumber < 0) {
        newErrors[field] = {
          field: field,
          error: true,
          textMessage: 'El número debe ser positivo'
        };
      } else {
        newErrors[field] = {
          field: field,
          error: false,
          textMessage: ''
        };
      }

      return newErrors;
    });
    updateGralError();
  };


  return {
    error,
    errorPassword,
    diffPass,
    comparePassword,
    comparePasswordError,
    fieldFormatError,
    passwordFormatError,
    dateBorn,
    dateBornError,
    errorNumber,
    handleEmail,
    handlePassword,
    dateValidation,
    validateNumber,
    gralError
  }
}
