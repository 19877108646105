import { createSlice } from '@reduxjs/toolkit';
import { PRODUCTOS } from '../../../interfaces/interfaces';



export const productosSlice = createSlice({
    name : "productos",
    initialState : {
        status : 'normal', 
        total : null,
        productos : [] as PRODUCTOS[],
        BackendErrorMessage : null
    },
    reducers : {
        failedgettingProductos: ( state, { payload } ) => {
            state.status = 'failed'; 
            state.productos = [];
            state.BackendErrorMessage = payload;
        },
        getAllProductos: ( state, { payload } ) => {
            state.status = 'success'; 
            state.total = payload.total;
            state.productos = payload.productos;
            state.BackendErrorMessage = null;
        },
        gettingProductos : ( state )=>{
            state.status = 'in-progres'
        }
    }
});

export const { failedgettingProductos,getAllProductos,gettingProductos} = productosSlice.actions;