/** this slide was created to save the term and section, both args are required to handle a search onto reserver
 * this slide works without thunk and provider same as modal moduel.
 */

 import { createSlice } from '@reduxjs/toolkit';
 
 export const saveTermAndSectionSlide = createSlice({
     name : "saveSearchTerm",
     initialState : {
         status : 'initial', 
         term : null,
         section : '',
     },
     reducers: {
         saveSearchArgs: (state,action) => {
           state.status = 'saved';
           state.term = action.payload.term;
           state.section = action.payload.section;
         },
         savingSearchTerms: (state) => {
           state.status = 'in-progress';
         },
     },
 });
 
 export const { saveSearchArgs, savingSearchTerms} = saveTermAndSectionSlide.actions;