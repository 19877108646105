import { useDispatch, useSelector } from 'react-redux';
import { ChangeEvent, useState, useEffect, FormEvent, HtmlHTMLAttributes, useMemo } from 'react'
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../store/store';

//REDUX TOOLKIT STORE
import { checkingAuthentication, startGoogleSignIn, startLoginWithEmailPassword } from '../store/slides/Auth/index';
//Material ui
import { Alert, Grid } from '@mui/material';
//components
import { ErrorMessage } from './ErrorMessage'
//CUSTOM HOOKS
import { useFormValidation } from '../customhooks/FormValidation';
import { useForm } from '../customhooks';

//styles
import '../styles/Auth.css'
import '../styles/components/auth.css'
import '../styles/BkgImg.css'
import '../styles/Signin.css'
import { BackendErrorHandler } from '../atoms/BackendErrorHandler';
import BkgImg from '../atoms/BkgImg';
import { Dots } from '../atoms/Dots';
import DotsImg from '../img/dots.svg'
import { useFormStateValidation } from '../customhooks/useFormStateValidation';

import mackbook from '../img/MacBook.svg';
import emailImage from '../img/email.svg';
import candado from '../img/candado.svg';

import LogoImage from '../img/LOGO.png';

import { SnackBar } from '../atoms/SnackBar';

import { Loader } from './Loader';

interface CookieOptions {
  expires?: number | Date;
  path?: string;
  domain?: string;
  secure?: boolean;
  sameSite?: 'Strict' | 'Lax' | 'None';
}


export const Auth: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { status, BackendErrorMessage } = useSelector((state: RootState) => state.auth)
  const handleRegister = useSelector((state: RootState) => state.register)
  const isCheckingAuthentication = useMemo(() => status === 'checking', [status]);
  const {
    errorMessage,
    setErrorMessage,
    showErrorMessage,
    setShowErrorMessage
  } = useFormValidation();

  const { formState,
    onInputChange
  } = useForm({ email: '', contrasena: '' }); //antes useForm({ email: '', contrasena: '' }, formValidations);
  const { handleEmail, handlePassword, error, fieldFormatError, passwordFormatError, errorPassword } = useFormStateValidation(formState);


  const [rememberPassword, setrememberPassword] = useState({
    email: '',
    password: ''
  })

  const handleRememberPassword = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (checked) {
      setrememberPassword({
        email: formState?.email,
        password: formState?.contrasena
      });
    } else {
      setrememberPassword({
        email: '',
        password: ''
      });
    }
  }

  useEffect(() => {
    /*NOTA : no usar este approach, usar el de la rama
    GS-75-RemembermeFunctionallity */
  }, [rememberPassword]);


  const logIn = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setErrorMessage('')
    setShowErrorMessage(false);
    const { email, contrasena } = formState;
    //handlePassword(contrasena as string);
    handleEmail(email as string);

    if (fieldFormatError) {
      setErrorMessage(error);
      setShowErrorMessage(true);
    }
    /*
    if (passwordFormatError) {
      setErrorMessage(errorPassword);
      setShowErrorMessage(true);
      return;
    } */
    dispatch(startLoginWithEmailPassword({ email: email as string, contrasena: contrasena as string }, navigate))
  }


  const onGoogleSignIn = (event: FormEvent<HTMLFormElement>): void => {
    dispatch(startGoogleSignIn())
  }

  return (
    <div className='flex pt-28 pb-20 pl-10 pr-10 overflow-x-hidden max-w-[2000px] sm:pt-20 md:pt-20 relative bg-white'>
      <div className='w-6/12 flex flex-col sm:w-full md:w-full'>
        <a href='/' className='bg-mainBlue w-3/12 p-4 mb-5'>
          <img src={LogoImage} className='' alt="" />
        </a>
        <p className='text-mainBlue font-bold text-5xl sm:text-4xl'>Reinventamos la experiencia en la administración de tu negocio</p>
        <p className='text-lightGray font-semibold mt-5 text-xl'>Inicia sesión con la información de tu cuenta</p>
        <div className='flex flex-col mt-10 w-10/12'>
          <div className='flex items-center mb-8 border-b border-lightBlue pb-1'>
            <img src={emailImage} alt="" className='mr-2' />
            <input
              className='w-full outline-0 placeholder:italic placeholder:text-sm'
              type="text"
              placeholder='Dirección de correo'
              name='email'
              onChange={onInputChange}
            />
          </div>
          <div className='flex items-center border-b border-lightBlue pb-1'>
            <img src={candado} alt="" className='mr-2' />
            <input
              className='w-full outline-0 placeholder:italic placeholder:text-sm'
              type="password"
              placeholder='Contraseña'
              name='contrasena'
              //value= { `${(rememberPassword?.email && rememberPassword?.email.length > 2) && rememberPassword?.email}`}
              onChange={onInputChange}
            />
          </div>
        </div>
        <button className='bg-mainBlue w-10/12 mt-8 py-2' onClick={logIn}>
          <p className='text-white font-semibold'>Iniciar Sesión</p>
        </button>
        <div className='flex justify-between w-10/12 mt-3 sm:flex-col'>
          {/* <div className='flex'>
            <input
              type="checkbox"
              className='mr-2'
              onChange={handleRememberPassword}
            />
            <p className='text-lightGray'>Recordarme</p>
          </div> */}
          <div>
            <p className='text-lightGray'>No tienes una cuenta? <a href='/registrar' className='text-mainBlue hover:cursor-pointer'>Regístrate</a></p>
          </div>
        </div>
        <div className='flex justify-end w-10/12 mt-3 sm:flex-col'>
          <div>
            <p className='text-lightGray'>Olvidaste tu contraseña? <a href='/forgotPassword' className='text-mainBlue hover:cursor-pointer'>Recuperar</a></p>
          </div>
          <div>
            <p className='text-lightGray'>Ya tienes una cuenta? <a href='/renewPlan' className='text-mainBlue hover:cursor-pointer'>Renovar plan</a></p>
          </div>
        </div>
        {showErrorMessage ? (
          <Alert severity="error" className='mt-3'>{errorMessage}</Alert>
        ) : null}

        {BackendErrorMessage ? (
          <Alert severity="error" className='mt-3'>{BackendErrorMessage}</Alert>
        ) : null}


      </div>

      <div className='w-6/12 flex items-center sm:hidden md:hidden'>
        <img src={mackbook} className='w-full ml-52' alt="" />
      </div>

      {status === 'checkingCredentials' ? (
        <Loader loaderText="Iniciando Sesión" />
      ) : null}



      {handleRegister.status === 'usuario-registrado' ? (
        <SnackBar severity='success' errorMsg='Cuenta creada exitosamente!' />
      ) : null}
    </div>
  )
}
