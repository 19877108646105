/** this slide was created to save the ID, this argument is required to handle a renew membership onto reserver
 * this slide works without thunk and provider same as modal moduel.
 */

 import { createSlice } from '@reduxjs/toolkit';
 
 export const saveUserIdSlide = createSlice({
     name : "saveUserId",
     initialState : {
         status : 'initial', 
         userId : null,
     },
     reducers: {
         saveUserId: (state,action) => {
           state.status = 'success';
           state.userId = action.payload.userId;
         },
         savingUserId: (state) => {
           state.status = 'in-progress';
         },
     },
 });
 
 export const { saveUserId, savingUserId} = saveUserIdSlide.actions;