import { HomeToolsItem } from "./HomeToolsItem";
import seguimiento from '../img/seguimiento.svg';
import gestion from '../img/gestion.svg';
import analisis from '../img/analisis.svg';
import seguridad from '../img/seguridad.png';

export const HomeTools = ()=>{
    return(
        <div className="mt-20 flex sm:flex-col sm:items-center md:grid md:grid-cols-2 md:grid-rows-2">

            <HomeToolsItem
                image={seguimiento}
                title="Seguimiento y control de clientes"
                text="Controla el acceso de tus clientes a tu negocio y recibe notificaciones de planes vencidos y próximos a vencer"
            />

            <HomeToolsItem
                image={gestion}
                title="Gestión y control de empleados"
                text="Permite a tus empleados la creación de sesiones de entrenamiento o eventos para tus clientes"
            />

            <HomeToolsItem
                image={analisis}
                title="Análisis de datos"
                text="Toma decisiones a partir de todo lo que pasa en tu negocio"
            />
            <HomeToolsItem
                image={seguridad}
                title="Seguridad"
                text="Tu información está segura con nosotros. Toda la comunicación está cifrada bajo los mejores entándares de seguridad en el mercado."
            />
        </div>
    )
} 