import { useEffect, useRef } from 'react';

import lottie from 'lottie-web';
import { Grid } from '@mui/material';

import Title from './Title';
import animationData from '../img/lottieAnimation.json'

const CommingSoon = () => {
  const animationContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current!,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData
    });

    return () => anim.destroy();
  }, []);


  return (
    <Grid
      item
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      gap='30px'
      minHeight='75vh'
      textAlign='center'
      width='100%'
    >
      <div ref={ animationContainer } style={ { width: '50%', } }></div>
      <Title>Estamos preparando algo especial para ti. ¡Espéralo pronto!</Title>
    </Grid>
  )
}

export default CommingSoon