import { createSlice } from '@reduxjs/toolkit';
import { CATEGORIAS } from '../../../interfaces/interfaces';



export const getAllCategoriasSlice = createSlice({
    name : "categorias",
    initialState : {
        status : 'normal', 
        total : null,
        categorias : [] as CATEGORIAS[],
        BackendErrorMessage : null
    },
    reducers : {
        failedgettingCategorias: ( state, { payload } ) => {
            state.status = 'failed'; 
            state.categorias = [];
            state.BackendErrorMessage = payload;
        },
        getAllCategorias: ( state, { payload } ) => {
            state.status = 'success'; 
            state.total = payload.total;
            state.categorias = payload.categorias;
            state.BackendErrorMessage = null;
        },
        gettingCategories : ( state )=>{
            state.status = 'in-progress...'
        }
    }
});

export const { failedgettingCategorias,getAllCategorias,gettingCategories} = getAllCategoriasSlice.actions;