
import axios, { AxiosResponse } from 'axios';
import { config } from '../../config';
import { stagingController } from '../../utils/stagingController';
import { developmentConfig } from '../../developmentConfig';

let host = stagingController();

export const deleteUsersProvider= async(uid:string) => {
    let xToken = localStorage.getItem('token');
    const headers = {
        'x-token': xToken,
        'Content-Type': 'application/json',
    };
    return await axios.delete(`${(host?.localhost)?developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/api/user/${uid}`, { headers })
        .then((response:AxiosResponse) => {        
            const data = response?.data;
            return {
                ok: true,
                data
            };
        })
        .catch((error) =>{            
            if(error.response?.data.error){
                return {
                    ok: false,
                    error : error.response?.data.error
                };
            }else if(error.response?.data?.msg){
                return {
                    ok: false,
                    error : error.response?.data?.msg
                };
            }            
        }) 
}