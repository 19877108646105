import { SnackBar } from "./SnackBar"
import { NOTIFICATIONS_PROPS } from "../interfaces/interfaces"

export const Notifications = ({ handler, actionType, actionName }: NOTIFICATIONS_PROPS) => {
  const msg = actionType === 'Buscando' ? 'Buscando, apreciamos tu paciencia...' : `${actionType} tu ${actionName}, apreciamos tu paciencia...`
  return (
    <>
      {(handler?.status === 'failed') && <SnackBar severity='error' errorMsg={typeof handler?.BackendErrorMessage === 'object' ? 'Error desconocido' : handler?.BackendErrorMessage}></SnackBar>}
      {(handler?.status === 'in-progress') && <SnackBar severity='info' errorMsg={msg}></SnackBar>}
      {(handler?.status === 'success') && <SnackBar severity='success' errorMsg={handler?.msg}></SnackBar>}
    </>
  )
}