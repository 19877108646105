
import axios, { AxiosResponse } from 'axios';
import { config } from '../../config';
import { EVENTS, POST_EVENTO_BASICO } from '../../interfaces/interfaces';
import { stagingController } from '../../utils/stagingController';
import { developmentConfig } from '../../developmentConfig';

let host = stagingController()

export const getAllEventsProvider = async ()=>{
    let xToken = localStorage.getItem('token');
    const headers = {
        'x-token': xToken,
        'Content-Type': 'application/json',
    };
    return await axios.get(`${(host?.localhost)?developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/api/eventos/all`, { headers })
        .then((response:AxiosResponse) => {            
            const data = response?.data;
            return {
                ok: true,
                data
            };
        })
        .catch((error) =>{            
            console.error(error)
            return {
                ok: false,
                error : error?.response?.data?.msg
            };
            
        }) 
}

export const deleteEventProvider= async(uid:string) => {
    let xToken = localStorage.getItem('token');
    const headers = {
        'x-token': xToken,
        'Content-Type': 'application/json',
    };
    return await axios.delete(`${(host?.localhost)?developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/api/eventos/${uid}`, { headers })
        .then((response:AxiosResponse) => {            
            const data = response.data;
            return {
                ok: true,
                data
            };
        })
        .catch((error) =>{            
            console.error(error)
            return {
                ok: false,
                error : error.response.data.msg
            };
            
        }) 
}
export const updateEventProvider = async (formState :any, navigate: any,uidEvent :string|null) => {
    try {
        let xToken = localStorage.getItem('token');
        const headers = {
            'x-token': xToken,
            'Content-Type': 'application/json'
        };

        const data = {
            ...formState
        }
        const response = await axios.put(`${(host?.localhost)?developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/api/eventos/${uidEvent}`, JSON.stringify(data), {
            headers: headers
        });

        if (response.status === 200 || response.status === 201) {            
            const responsee = response.data;
            return {
                ok: true,
                responsee
            };
        }

    } catch (error: any) {

        if (error.response.data && error.response.data.errors) {
            const errores = error.response.data.errors;
            const ErrorMessagee = errores.map((errorItem: any) => errorItem.msg);            
            return {
                ok: false,
                error: ErrorMessagee
            };
        }
    }
}

export const createEventProvider = async (formState : POST_EVENTO_BASICO)=>{
    let xToken = localStorage.getItem('token');
    const headers = {
        'x-token': xToken,
        'Content-Type': 'application/json',
    };
    const data = {
        ...formState
    }
    return await axios.post(`${(host?.localhost)?developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/api/eventos/crear`, JSON.stringify(data),{ headers })
        .then((response:AxiosResponse) => {            
            const data = response.data;
            return {
                ok: true,
                data
            };
        })
        .catch((error) =>{            
            if (error?.response?.data && error?.response?.data?.errors) {
                const errores = error?.response?.data?.errors;
                const ErrorMessagee = errores.map((errorItem: any) => errorItem.msg);                
                return {
                    ok: false,
                    error: ErrorMessagee
                };
            }            
        }) 
}

export const addAnUserToEventProvider = async (eventId: string | undefined, user_id: string) => {
    let xToken = localStorage.getItem('token');
    const headers = {
        'x-token': xToken,
        'Content-Type': 'application/json',
    };

    const data = {
        user_id
    }

    return await axios.post(`${(host?.localhost)?developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/api/eventos/registro/${eventId}`, JSON.stringify(data),{ headers })
        .then((response:AxiosResponse) => {            
            const data = response.data;
            return {
                ok: true,
                data
            };
        })
        .catch((error) =>{            
            console.error(error)
            return {
                ok: false,
                error : error?.response?.data?.msg
            };
        })
}