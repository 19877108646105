import { createSlice } from '@reduxjs/toolkit';
import { PRODUCTOS } from '../../../interfaces/interfaces';



export const deleteProductsSlice = createSlice({
    name : "productos",
    initialState : {
        status : 'normal', 
        msg : null,
        productos : [] as PRODUCTOS[],
        BackendErrorMessage : null
    },
    reducers : {
        faileddeletedproducts: ( state, { payload } ) => {
            state.status = 'failed'; 
            state.productos = [];
            state.BackendErrorMessage = payload;
        },
        
        deletedProductById: ( state, { payload } ) => {
            state.status = 'success'; 
            state.msg = payload.msg; 
            state.productos = payload.productos;
            state.BackendErrorMessage = null;
        },
        delitingProduct : ( state )=>{
            state.status = 'in-progress'
        }
    }
});

export const { faileddeletedproducts,deletedProductById,delitingProduct} = deleteProductsSlice.actions;