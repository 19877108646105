import type { INFOGRID_PROPS } from "../interfaces/interfaces"

import { Grid } from "@mui/material";
import { InfoLabelWithTitle } from "../atoms/InfoLabelWithTitle";

const InfoGrid = ({ form }: INFOGRID_PROPS) => {
  return (
    <Grid container spacing={ 2 } paddingLeft='40px'>
      {
        form.map((form: any, index: any) => {
          return (
            <Grid item display='flex' gap={ 1 } key={ index } xs={ 12 } sm={ 12 } lg={ 6 }>
              <form.icon />
              <InfoLabelWithTitle title={ form.title } value={ form.value } />
            </Grid>
          )
        })
      }
    </Grid>
  )
}

export default InfoGrid;