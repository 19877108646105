import * as React from 'react';

import { Link } from "react-router-dom";

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

import {
  adminMainSections,
  secondSections,
  superAdminMainSections,
  expertoMainSections
} from '../constants/ConstantsFlexSolutionManager';
import CollapseMenu from '../components/CollapseMenu';

export const adminSection = (
  <React.Fragment>
    {
      adminMainSections?.map((section, index) => (
        section?.subSection === undefined ?
          <ListItemButton
            key={ index }
            style={ {
              textDecoration: 'none',
              color: 'inherit',
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
            } }
            component={ Link }
            to={ section?.path }
          >
            <ListItemIcon>
              <section.icon />
            </ListItemIcon>
            <ListItemText primary={ section?.title } />
          </ListItemButton>
          : <CollapseMenu section={ section } />
      ))
    }
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Store
    </ListSubheader>
    {
      secondSections?.map((section, index) => (
        section?.subSection === undefined ?
          <ListItemButton
            key={ index }
            style={ {
              textDecoration: 'none',
              color: 'inherit',
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
            } }
            component={ Link }
            to={ section?.path }
          >
            <ListItemIcon>
              <section.icon />
            </ListItemIcon>
            <ListItemText primary={ section?.title } />
          </ListItemButton>
          : <CollapseMenu section={ section } />
      ))
    }
  </React.Fragment>
);

export const superAdminSection = (
  <React.Fragment>
    {
      superAdminMainSections?.map((section, index) => (
        section?.subSection === undefined ?
          <ListItemButton
            key={ index }
            style={ {
              textDecoration: 'none',
              color: 'inherit',
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
            } }
            component={ Link }
            to={ section?.path }
          >
            <ListItemIcon>
              <section.icon />
            </ListItemIcon>
            <ListItemText primary={ section?.title } />
          </ListItemButton>
          : <CollapseMenu section={ section } />
      ))
    }
  </React.Fragment>
)

export const expertoSection = (
  <React.Fragment>
    {
      expertoMainSections?.map((section, index) => (
        section?.subSection === undefined ?
          <ListItemButton
            key={ index }
            style={ {
              textDecoration: 'none',
              color: 'inherit',
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
            } }
            component={ Link }
            to={ section?.path }
          >
            <ListItemIcon>
              <section.icon />
            </ListItemIcon>
            <ListItemText primary={ section?.title } />
          </ListItemButton>
          : <CollapseMenu section={ section } />
      ))
    }
  </React.Fragment>
)