import { createSlice } from '@reduxjs/toolkit';

/*interface INITIALSTATE {
   uid : string,
  nombre : string,
  apellidoPaterno : string,
  apellidoMaterno : string,
  tenant : string,
  email : string,
  fechaIngreso : string,    
  fechaNacimiento : string,
  direccion : string,
  salarioMensual : string,
  planStatusActivo : boolean,
  licencia : string,
  antiguedad : string,
  estado : boolean, // si aun existe el usuario, si no se ha borrado
  role : string,
  photoURL : string,
  img ?: string,
}  
 */

export const adminRegisterSlice = createSlice({
    name : "admin-register",
    initialState : {
        status : 'un-register', //'checking', 'not-authenticated' , 'authenticated'
        uid : null,
        nombre : null,
        apellidoPaterno : null,
        apellidoMaterno : null,
        email : null,
        contrasena : null,
        fechaIngreso : null,
        fechaNacimiento : null,
        direccion : null,
        salarioMensual :null,
        planStatusActivo : null,
        antiguedad : null,
        estado:null,
        role : null,
        photoURL : null,
        BackendErrorMessage : null
    },
    reducers : {
        failedRegister: ( state, { payload } ) => {
            state.status = 'un-register-user'; 
            state.uid = null;
            state.nombre = null;
            state.apellidoPaterno = null;
            state.apellidoMaterno = null;
            state.email = null;
            state.contrasena = null;
            state.fechaIngreso = null;
            state.fechaNacimiento = null;
            state.direccion = null;
            state.salarioMensual = null;
            state.planStatusActivo = null;
            state.antiguedad = null;
            state.estado = null;
            state.role = null;
            state.photoURL = null;
            state.BackendErrorMessage = payload;
        },
        adminRegistered: ( state, { payload } ) => {
            state.status = 'usuario-registrado'; 
            state.uid = payload.uid;
            state.nombre = payload.nombre;
            state.apellidoPaterno = payload.apellidoPaterno;
            state.apellidoMaterno = payload.apellidoMaterno;
            state.email = payload.email;
            state.contrasena = payload.contrasena;
            state.fechaIngreso = payload.fechaIngreso;
            state.fechaNacimiento = payload.fechaNacimiento;
            state.direccion = payload.direccion;
            state.salarioMensual = payload.salarioMensual;
            state.planStatusActivo = payload.planStatusActivo;
            state.antiguedad = payload.antiguedad;
            state.estado = payload.estado;
            state.role = payload.role;
            state.photoURL = payload.photoURL;
            state.BackendErrorMessage = null;
        },
        registering : ( state )=>{
            state.status = 'registering';
        }
    }
});

export const { failedRegister,adminRegistered,registering } = adminRegisterSlice.actions;