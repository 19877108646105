import React, { FormEvent, useEffect, useState } from "react";

import { loadStripe } from '@stripe/stripe-js';

import Tooltip from '@mui/material/Tooltip';

import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { payTenantSuscription } from '../store/sales/index';
import { config } from "../config";
import { calculateUsrPlanCost } from '../utils/walletUtil';
import { TenantPlan } from '../types/types';

import  '../styles/components/luzPalpitanteEfecto.css';
import { paste } from "@testing-library/user-event/dist/paste";
import { Password } from "@mui/icons-material";

const stripePromise = loadStripe(config?.REACT_APP_PUBLISHABLE_STRIPE_KEY as string)
interface STRIPE_INTERFACE {
  custumerEmail : string, 
  licencia : string, 
  planMensualTipo: string, 
  nombreEmpresa : string, 
  password : string, 
  nuevoRegistro : boolean
}

const CheckoutForm = ({ custumerEmail, licencia, planMensualTipo, nombreEmpresa, password, nuevoRegistro }: STRIPE_INTERFACE ) => {
  const tenantPay = useSelector((state: RootState) => state?.tenantPay);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch<AppDispatch>();
  const { amount, amountWithoutInscription } = calculateUsrPlanCost(licencia, planMensualTipo);

  const userPlanChooised: TenantPlan = {
    MENSUAL: 1,
    SEMESTRAL: 6,
    ANUAL: 12
  }
  const handleClick = async (event: any) => {
    event.preventDefault();
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement)
      });

      if (paymentMethod) {
        const { id } = paymentMethod;
        let origin = nuevoRegistro ? config?.REQUEST_ORIGIN_REGISTER : config?.REQUEST_ORIGIN_RENEW
        const res = await dispatch(payTenantSuscription({ id, licencia, planMensualTipo, custumerEmail, nombreEmpresa , origin}));
        if( res?.ok && res?.securityAction3D?.requiresAction ){
          const { requiresAction , paymentIntentClientSecret} = res?.securityAction3D;
          if( requiresAction ){
            const { paymentIntent, error } = await stripe?.confirmCardPayment(paymentIntentClientSecret, {
              payment_method: {
                card: elements?.getElement(CardElement)
              }
            });
            if (error) {
              console.log("CONFIRM PAYMENT ERROR:");
            } else if (paymentIntent.status === 'succeeded') {
              console.log("PAYMENT SUCCEEDED:");
            }

          } else {
            console.log("NO ACTION REQUIRED");
          }         
        }
      } else {
        console.log("PAYMENT METHOD ERROR:");                
      }
    } catch (error) {
      console.log(`STRIPE ERROR`)
    }
  };

  return (
    <div>
      <h4 className="text-lg font-semibold text-gray-800 mb-3" > Resumen de pago </h4>
      <div className="mb-3">
        {nuevoRegistro ? (
          <p className="text-sm text-gray-600 mb-1">Única cuota de inscripción: $ {config?.INSCRIPTION_PAYMENT}
            <Tooltip title="La cuota de inscripción es un cargo único para acceder a nuestro servicio. Cubre los costos iniciales y nos permite ofrecerte una excelente experiencia."
              arrow
              sx={{ bgcolor: '#0D47A1', color: '#fff', fontSize: 14 }}
            >
              <span style={{ cursor: 'pointer', textDecoration: 'underline' }}> (?) </span>
            </Tooltip>
          </p>
        ):null}
        <p className="text-sm text-gray-600 mb-1">Adquisición del plan {licencia} * {userPlanChooised[planMensualTipo]} Meses: $ {amountWithoutInscription}</p>
      </div>
      {nuevoRegistro ? (
        <p className="text-lg font-semibold text-gray-800 mb-3">TOTAL: $ {amount} {config?.CURRENCY}</p>
      ):(
        <p className="text-lg font-semibold text-gray-800 mb-3">TOTAL: $ {amountWithoutInscription} {config?.CURRENCY}</p>
      )}
      
      <CardElement
        className="my-4"
        options={{
          style: {
            base: {
              padding: '3px',
              color: '#707070',
            },
            complete: {
              iconColor: 'green',
              color: 'green',
            }
          }
        }} />      
      <button
        className="bg-mainBlue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-3 disabled:opacity-75 disabled:pointer-events-none"
        onClick={handleClick}
        disabled={(custumerEmail?.trim().length < 2 || licencia?.trim().length < 2 || planMensualTipo?.trim().length < 2 || password?.trim().length < 2 || tenantPay?.status === 'success') ? true : false}
      >
        Procesar pago
      </button>
    </div>
  );
}

export const StripePaymentCard = ({ custumerEmail, licencia, planMensualTipo, nombreEmpresa, password , nuevoRegistro}: STRIPE_INTERFACE ) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        custumerEmail={custumerEmail}
        licencia={licencia}
        planMensualTipo={planMensualTipo}
        nombreEmpresa={nombreEmpresa}
        password={password}
        nuevoRegistro={nuevoRegistro}
      />
    </Elements>
  );
}