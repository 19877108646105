import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { usePagination } from '../customhooks/usePagination';
import { useUsersActions } from '../customhooks/useUsersActions';
import { SnackBar } from '../atoms/SnackBar';
import Title from '../atoms/Title';
import { PaginationAtom } from '../atoms/PaginationAtom';
import { LoadingAtom } from '../atoms/LoadingAtom';
import SearchBar from '../atoms/SearchBar';
import { Chip, Grid, Paper } from '@mui/material';
import { searchThunk } from '../store/search/thunk';
import CategoriasActions from '../atoms/CategoriasActions';



function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}
const headerCellStyle = {
  fontWeight: 'bold',
  minWidth: 180,
}

export const SearchCategory = React.memo(() => {
  //pagination
  const { page, usersPerPage, handleChangePage, startIndex, endIndex } = usePagination()
  const { handleEditProduct, handleDelete } = useUsersActions();
  //globalState
  const searchResults = useSelector((state: RootState) => state?.searchResults);
  const backendError = useSelector((state: RootState) => state?.searchResults?.BackendErrorMessage);
  const { term, section } = useSelector((state: RootState) => state?.saveSearchTerm);
  let res = (searchResults.status === 'failed') ? <SnackBar status={searchResults?.status} severity='error' errorMsg={backendError} /> : (<TableRow><TableCell colSpan={10} align="center"><LoadingAtom /></TableCell></TableRow>)

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(searchThunk(section, term || ''));
  }, [term, section])

  return (
    <React.Fragment>
      <Grid item xs={12} md={12} lg={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflowX: 'auto', borderRadius: '0', marginBottom: '8px' }}>
          <Title>Buscando sobre Categorias</Title>
          <SearchBar />
          {/* <div style={{width:'100%'}}>Total de resultados : {searchResults.totalResults}</div> */}
        </Paper>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={headerCellStyle}>Categoría Uid</TableCell>
                <TableCell sx={headerCellStyle}>Nombre</TableCell>
                <TableCell sx={headerCellStyle}>Estado</TableCell>
                <TableCell sx={headerCellStyle} align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchResults.status === 'success' ? (
                searchResults.results.slice(startIndex, endIndex).map((category) => (
                  <TableRow key={category._id}>
                    <TableCell>{(!category?.estado) ? <Chip label={category?._id} color="error" variant="outlined" /> : <Chip label={category?._id} color="primary" variant="outlined" />}</TableCell>
                    <TableCell>{(!category?.estado) ? <Chip label={category?.nombre} color="error" variant="outlined" /> : category?.nombre}</TableCell>
                    <TableCell>{(!category?.estado) ? <Chip label='CATEGORÍA BORRADA' color="error" variant="outlined" /> : <Chip label='CATEGORÍA ACTIVA' color="success" variant="outlined" />}</TableCell>
                    <TableCell align="right">
                      <CategoriasActions
                        onEdit={() => { alert('Debes editar desde la ruta de categorías') }}
                        onDelete={() => { alert('Debes borrar una categoría desde la sección categoría en la barrada de navagación') }}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : res}
            </TableBody>
          </Table>
          {/* <Pagination count={Math.ceil(searchResults.results.length / usersPerPage)} page={page} onChange={handleChangePage} color="primary" shape="rounded" style={{ marginTop: '10px'}}/> */}
          <PaginationAtom count={Math.ceil(searchResults.results.length / usersPerPage)} page={page} onChange={handleChangePage} color="primary" shape="rounded" style={{ marginTop: '10px' }} />

          {
            /** resultados de búsqueda */
            (searchResults.status === 'failed') && <SnackBar severity='error' errorMsg={searchResults.BackendErrorMessage}></SnackBar>
          }
          {
            (searchResults.totalResults === 0) && <SnackBar severity='warning' errorMsg='No se econtraron resultados para esta búsqueda !'></SnackBar>
          }
          {
            (searchResults.status === 'in-progress') && <SnackBar severity='info' errorMsg='buscando, apreciamos tu paciencia...'></SnackBar>
          }
          {
            (searchResults.status === 'success') && <SnackBar severity='success' errorMsg={searchResults.msg}></SnackBar>
          }
        </Paper>
      </Grid>
    </React.Fragment >
  );
})