import { useState, FormEvent } from 'react';
import { useForm } from '../customhooks';
import { useUsersActions } from '../customhooks/useUsersActions';

import { Box, Button, IconButton, InputAdornment, TextField } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const FormChangePassword = ( { userId } : { userId: string | null }) => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);

  const { handlePasswordChange } = useUsersActions();
  const { formState, onInputChange, onResetForm } = useForm({
    nuevaContrasena: '',
    oldPassword: ''
  })

  const togglePasswordVisibility = (name: string) => {
    name === 'oldPassword' ? setShowOldPassword(!showOldPassword) : setShowNewPassword(!showNewPassword);
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    handlePasswordChange(formState as any, userId, onResetForm);
  }

  return (
    <form onSubmit={ (e) => { handleSubmit(e) } } style={ { display: 'flex', flexWrap: 'wrap', flexDirection: 'column', width: '100%' } }>
      <Box>
        <TextField
          label="Contraseña anterior"
          name="oldPassword"
          value={ formState.oldPassword }
          onChange={ onInputChange }
          fullWidth
          type={ showOldPassword ? 'text' : 'password' }
          margin="normal"
          required
          InputProps={ {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={ () => togglePasswordVisibility('oldPassword') } edge="end">
                  { showOldPassword ? <VisibilityIcon /> : <VisibilityOffIcon /> }
                </IconButton>
              </InputAdornment>
            ),
          } }
        />

        <TextField
          label="Contraseña nueva"
          name="nuevaContrasena"
          value={ formState.nuevaContrasena }
          onChange={ onInputChange }
          fullWidth
          type={ showNewPassword ? 'text' : 'password' }
          margin="normal"
          required
          InputProps={ {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={ () => togglePasswordVisibility('nuevaContrasena') } edge="end">
                  { showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon /> }
                </IconButton>
              </InputAdornment>
            ),
          } }
        />

        <Box display='flex' justifyContent='flex-end'>
          <Button
            color="primary"
            type='submit'
          >
            Actualizar
          </Button>
        </Box>
      </Box>
    </form>
  )
}

export default FormChangePassword;