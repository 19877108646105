import React from 'react'
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { useTheme } from '@mui/material/styles';

export interface PIE_CHART {
    chartData: { name: string; amount: number }[];
}
const DAYS_OF_WEEK = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {/* {`${(percent * 100).toFixed(0)}%`} */}            
            {DAYS_OF_WEEK[index]}
        </text>
    );
};
export const PieChartt = ({chartData}:PIE_CHART) => {
    const theme = useTheme();
    return (
        <ResponsiveContainer>            
            <PieChart>
                <Pie
                    dataKey="total"
                    data={chartData}
                    //data={data}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={80}
                    fill={theme.palette.primary.main}
                    //fill="#8884d8"
                    label={renderCustomizedLabel}
                >
                    {chartData.map((entry, index):any => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip />
            </PieChart>
        </ResponsiveContainer>
    )
}
