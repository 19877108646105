import React from 'react'
import { Pagination } from '@mui/material';
import { PAGINATION } from '../interfaces/interfaces';

export const PaginationAtom = ({count, page,onChange,color,shape,style}:PAGINATION) => {
  return (
    <>
        <Pagination count={count} page={page} onChange = {onChange} color={color} shape={shape} style = {style}/>
    </>
  )
}
