import { createSlice } from '@reduxjs/toolkit';

/*interface INITIALSTATE {
    status,
    uid
    nombreEmpresa,
    tenant,
    email,
    fechaNacimiento,
    direccion,
    planMensualTipo,
    licencia,
    role
}  
 */

export const Register = createSlice({
    name : "registrar",
    initialState : {
        status : 'sin-registrar', //'checking', 'not-authenticated' , 'authenticated'
        uid : null,
        nombreEmpresa : null,
        tenant : null,
        email : null,
        fechaNacimiento : null,
        direccion : null,
        planMensualTipo : null,
        licencia : null,
        role : null,
        photoURL : null,
        BackendErrorMessage : null
    },
    reducers : {
        registro: ( state, { payload } ) => {
            state.status = 'usuario-no-registrado'; 
            state.uid = null
            state.nombreEmpresa = null
            state.tenant = null
            state.email = null
            state.fechaNacimiento = null
            state.direccion = null
            state.planMensualTipo = null
            state.licencia = null
            state.role = null
            state.BackendErrorMessage = payload;
            // state.displayName = payload.displayName;
            // state.photoURL = payload.photoURL;
        },
        registrado: ( state, { payload } ) => {
            state.status = 'usuario-registrado'; // 'checking', 'not-authenticated', 'authenticated'
            state.uid = payload.uid;
            state.nombreEmpresa = payload.nombreEmpresa;
            state.tenant = payload.tenant;
            state.email = payload.email;
            state.fechaNacimiento = payload.fechaNacimiento;
            state.direccion = payload.direccion;
            state.planMensualTipo = payload.planMensualTipo;
            state.licencia = payload.licencia;
            state.role = payload.role; 
            state.BackendErrorMessage = null;
        },
        registrando : ( state )=>{
            state.status = 'registrando'
        }
    }
});

export const { registro,registrado,registrando } = Register.actions;