/**
 * permite la visualización de datos a tenants y roles de mantenimiento
 * En lugar de consultar múltiples veces al backend ( semanal,mensual,semestral ), lo que hace es
 * realizar una sola petición sobre el tipo de búsqueda más alto permitido, y en el front end refinar
 * el tipo de búsqueda que se desea. Es decir, lanzamos la consulata semestral, y aquí refinamos los datos traídos
 * por semana, mes, etc.
 */

import React, { useState } from 'react'
import { DateTime } from 'luxon';
import { Grid, Paper, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';

import Title from '../atoms/Title';
import { getSalesRecordsThunk } from '../store/sales/index';
import { SALES_RECORDS } from '../interfaces/interfaces';
import { PieChartt } from './PieChartt';
import { BarChartt } from './BarChartt';
import { ActiveShapePieChart } from './ActiveShapePieChart';
import { StackedAreaChart } from './StackedAreaChart';

const boxStyle = {
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto',
    minHeight: 350,
    overflowY: 'auto'
}

// const createData = (time: string, total?: number) => {
//     return { time, total: total !== undefined ? total.toString() : 'N/A' };
// }
const createData = (time: string, total?: number) => {
    return { time, total: total !== undefined ? parseFloat(total.toString()) : 'N/A' };
}

export const SaleDetails = () => {
    const [chartDataa, setChartData] = useState([]);
    const [weekly, setWeeklyChartData] = useState([]);
    const [ membresia , setMembresia ] = useState([]);
    const theme = useTheme();
    const dispatch = useDispatch<AppDispatch>();
    const salesRecord: SALES_RECORDS[] = useSelector((state: RootState) => state?.getSalesRecords?.sales);

    const startDateLastWeek = new Date();
    startDateLastWeek.setDate(startDateLastWeek.getDate() - 7);
    const startDateLasMonth = new Date();
    startDateLasMonth.setDate(startDateLasMonth.getDate() - 30);

    const filterSalesByDateRange = (sales: any, startDate: Date) => {
        return sales.filter(sale  => new Date(sale.saleDate) >= startDate);
    };

    React.useEffect(() => {
        dispatch(getSalesRecordsThunk('semestral'));
    }, []);

    React.useEffect(() => {        
        if (salesRecord) {
            const organizedData = organizeDataForChart(salesRecord);
            setChartData(organizedData);

            const orgDataByWeek = organizeDataForChartWeekly(salesRecord);
            setWeeklyChartData(orgDataByWeek)
            const membresiasData = organizeDataForMembresias(salesRecord)
            setMembresia(membresiasData);
            
        }        
    }, []);

    //math charts
    const dailySales: Record<string, number> = salesRecord.reduce((acc, sale) => {
        const saleDate = DateTime.fromISO(sale.saleDate).setLocale('es').toFormat('cccc'); // Obtener el día de la semana
        acc[saleDate] = (acc[saleDate] || 0) + sale?.amount;
        return acc;
    }, {});
    const monthlySales: Record<string, number> = salesRecord.reduce((acc, sale) => {
        const saleDate = DateTime.fromISO(sale.saleDate).setLocale('es').toFormat('LLLL yyyy').toUpperCase(); // Obtener mes y año
        acc[saleDate] = (acc[saleDate] || 0) + sale?.amount;
        return acc;
    }, {});

    //allow weeklySales details . Detalles mensuales por semana / semana 1, semana 2, semana 3 , etc...
    //Saca el número de semana del año
    const organizeDataForChartWeekly = (sales: any) => {
        // Filtrar ventas de las últimas 4 semanas
        const last4WeeksSales = sales.filter((sale: any) => {
            const saleDate = DateTime.fromISO(sale?.saleDate).toJSDate();
            const currentDate = DateTime.local().toJSDate();
            const fourWeeksAgo = DateTime.local().minus({ weeks: 4 }).toJSDate();
            return saleDate >= fourWeeksAgo;
        });
    
        // Organizar datos para el gráfico
        const weeklyData = last4WeeksSales.reduce((acc: any, sale: any) => {
            const saleWeek = DateTime.fromISO(sale?.saleDate).startOf('week');
            const weekKey = `Semana ${saleWeek.weekNumber}`;
    
            if (!acc[weekKey]) {
                acc[weekKey] = {
                    name: `Semana ${saleWeek.weekNumber}`,
                    amount: 0,
                    numberOfProducts: 0,
                    total: 0,
                };
            }
    
            acc[weekKey].amount += sale?.amount;
            acc[weekKey].numberOfProducts += sale?.numberOfProductsOrServicesSold || 0;
            acc[weekKey].total += sale?.totalMount || 0;
    
            return acc;
        }, {});
    
        const chartData = Object.values(weeklyData);
    
        return chartData;
    };
    

    const organizeDataForChart = (sales: any) => {
        const startDateLastWeek = new Date();
        startDateLastWeek.setDate(startDateLastWeek.getDate() - 30);
        const last4WeeksSales = filterSalesByDateRange(sales, startDateLastWeek);
        
        return last4WeeksSales.filter(sale => sale?.serviceIdentifier === 'VENTAS DE PRODUCTOS O SERVICIOS').map(sale => ({
            name: DateTime.fromISO(sale.saleDate).toLocaleString(DateTime.DATE_SHORT),
            amount: sale.amount,
        }));
    };
    const organizeDataForMembresias = (sales: any) => {
        // Filtrar ventas de las últimas 4 semanas
        const last4WeeksSales = sales.filter((sale: any) => {
            const saleDate = DateTime.fromISO(sale?.saleDate).toJSDate();
            const currentDate = DateTime.local().toJSDate();
            const fourWeeksAgo = DateTime.local().minus({ weeks: 4 }).toJSDate();
            return saleDate >= fourWeeksAgo;
        });
        
        // Organizar datos para el gráfico
        const refineryMembresiaData = last4WeeksSales.filter(sale => sale?.serviceIdentifier === 'MEMBRESIAS').map((sale: any) => ({
            name: DateTime.fromISO(sale?.saleDate).toLocaleString(DateTime.DATE_SHORT),
            amount: sale?.amount,
        }));

        return refineryMembresiaData;       
    };
    
    // Crear los datos para el gráfico
    const chartData = Object.keys(dailySales).map(day => createData(day, dailySales[day]));
    const sixMonthlyDataChart = Object.keys(monthlySales).map(month => createData(month, monthlySales[month]));

    return (
        <Grid container spacing={2} sx={{ marginLeft: 0.5 }}>
            <Grid item xs={12}>
                <Paper sx={boxStyle} style={{ width: '100%', height: '300px' }}>
                    <Title>Detalles Semestrales</Title>
                    {
                        Object.keys(dailySales).length === 0 ? (
                            <Typography variant="body2">
                                Aún no hay reportes de ventas disponibles.
                            </Typography>
                        ) :
                            (
                                <BarChartt chartData={sixMonthlyDataChart} time='time' amount='total' />
                            )
                    }
                </Paper>
            </Grid>

            {/* Cajas 50vw para detalles mensuales y semanales */}
            <Grid item xs={12} md={6}>
                <Paper sx={boxStyle} style={{ width: '100%', height: '100%' }}>                    
                    <Title> Últimas 4 semanas del año </Title>
                    {
                        Object.keys(dailySales).length === 0 ? (
                            <Typography variant="body2">
                                Aún no hay reportes de ventas disponibles.
                            </Typography>
                        ) :
                            (
                                <ActiveShapePieChart data={weekly} />
                            )
                    }
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper sx={boxStyle} style={{ width: '100%', height: '100%' }}>
                    <Title>Detalles Semanales</Title>
                    {
                        Object.keys(dailySales).length === 0 ? (
                            <Typography variant="body2">
                                Aún no hay reportes de ventas disponibles.
                            </Typography>
                        ) :
                            (
                                <PieChartt chartData={chartData as []} />
                            )
                    }

                    {/*  */}
                </Paper>
            </Grid>

            {/* Caja para detalles de ventas de productos y servicios */}
            <Grid item xs={12}>
                <Paper sx={boxStyle} style={{ width: '100%', height: '100%' }}>
                    <Title>Detalles de Ventas de Productos y Servicios</Title>
                    <StackedAreaChart data={chartDataa} />
                </Paper>
            </Grid>

            {/* Caja para detalles de ventas de membresías */}
            <Grid item xs={12}>
                <Paper sx={boxStyle}>
                    <Title>Detalles de Ventas de Membresías</Title>
                    <StackedAreaChart data={membresia} />
                </Paper>
            </Grid>
        </Grid>
    )
}
