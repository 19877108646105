import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { usePagination } from '../customhooks/usePagination';
import { useUsersActions } from '../customhooks/useUsersActions';
import { SnackBar } from '../atoms/SnackBar';
import Title from '../atoms/Title';
import { PaginationAtom } from '../atoms/PaginationAtom';
import { LoadingAtom } from '../atoms/LoadingAtom';
import SearchBar from '../atoms/SearchBar';
import { Chip, Grid, Paper } from '@mui/material';
import { searchThunk } from '../store/search/thunk';
import ProductosActions from '../atoms/ProductosActions';
import { config } from '../config';

import { CreateButton } from '../atoms/CreateButton';
import CreateProductModal from '../atoms/ProductoModal';
import ModalSellProduct from './ModalSellProduct';
import { getAllProductosThunk, getProductByIdThunk } from '../store/productos';
import { openModal } from '../store/modal';
import { FormProductUpdate } from './FormProductUpdate';
import { Notifications } from '../atoms/Notifications';
import { getAllcategoriasThunk } from '../store/categorias';

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}
const headerCellStyle = {
  fontWeight: 'bold',
  minWidth: 180,
}

export const SearchProduct = React.memo(() => {
  const [uidProduct, setUidProduct] = useState('')
  //pagination
  const { page, usersPerPage, handleChangePage, startIndex, endIndex } = usePagination()
  const { handleEditProduct, ProductDelete, handleSellProduct } = useUsersActions();
  const handleBackendErrorsAboutProductUpate = useSelector((state: RootState) => state?.updateProduct);
  //globalState
  const searchResults = useSelector((state: RootState) => state?.searchResults);
  const backendError = useSelector((state: RootState) => state?.searchResults?.BackendErrorMessage);
  const { term, section } = useSelector((state: RootState) => state?.saveSearchTerm);
  const productHandler = useSelector((state: RootState) => state?.postProduct);
  const deleteProductsHandler = useSelector((state: RootState) => state?.deleteProduct);
  const sellProductHandler = useSelector((state: RootState) => state?.sellProduct);
  //response failed
  let res = (searchResults.status === 'failed') ? <SnackBar status={searchResults?.status} severity='error' errorMsg={backendError} /> : (<TableRow><TableCell colSpan={10} align="center"><LoadingAtom /></TableCell></TableRow>)

  const dispatch = useDispatch<AppDispatch>();
  const handleOpenModal = () => {
    dispatch({
      type: openModal.type,
      payload: config.MODAL_TYPE_CREATE_PRODUCT
    });
  };

  const getProductById = async (uidProduct: string) => {
    await dispatch(getProductByIdThunk(uidProduct))
  }
  const handleProductEdit = async (uidProduct: string) => {
    await getProductById(uidProduct)
      .then(() => {
        setUidProduct(uidProduct)
        handleEditProduct()
      })
  }

  const handleProductSale = async (uidProduct: string) => {
    await getProductById(uidProduct)
      .then(() => {
        setUidProduct(uidProduct)
        handleSellProduct()
      })
  }

  useEffect(() => {
    dispatch(searchThunk(section, term || ''));
  }, [term, section, deleteProductsHandler.status === "success"])

  useEffect(() => {
    dispatch(getAllProductosThunk());
    dispatch(getAllcategoriasThunk());
  }, [productHandler.status === "success", deleteProductsHandler.status === "success", sellProductHandler.status === "success"])

  return (
    <React.Fragment>
      <CreateButton name='Producto' onClick={handleOpenModal} />
      <CreateProductModal modalType={config.MODAL_TYPE_CREATE_PRODUCT} />
      {uidProduct && <ModalSellProduct modalType={config.MODAL_TYPE_SELL_PRODUCT} />}
      {uidProduct && <FormProductUpdate uidProduct={uidProduct} modalType={config.MODAL_TYPE_EDIT_PRODUCT} />}
      <Grid item xs={12} md={12} lg={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflowX: 'auto', borderRadius: '0', marginBottom: '8px' }}>
          <Title>Buscando sobre productos</Title>
          <SearchBar />
          {/* <div style={{width:'100%'}}>Total de resultados : {searchResults.totalResults}</div> */}
        </Paper>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={headerCellStyle}>Producto Uid</TableCell>
                <TableCell sx={headerCellStyle}>Nombre</TableCell>
                <TableCell sx={headerCellStyle}>Precio</TableCell>
                <TableCell sx={headerCellStyle}>Categoría</TableCell>
                <TableCell sx={headerCellStyle}>Cantidad en stock</TableCell>
                <TableCell sx={headerCellStyle}>Estado</TableCell>
                <TableCell sx={headerCellStyle}>Descripción</TableCell>
                <TableCell sx={headerCellStyle} align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchResults.status === 'success' ? (
                searchResults.results.slice(startIndex, endIndex).filter((product) => !product?.estado).map((product) => (
                  <TableRow key={product._id}>
                    <TableCell>{(!product?.disponible) ? <Chip label={product?._id} color="error" variant="outlined" /> : <Chip label={product?._id} color="primary" variant="outlined" />}</TableCell>
                    <TableCell>{(!product?.disponible) ? <Chip label={product?.nombre} color="error" variant="outlined" /> : product?.nombre}</TableCell>
                    <TableCell>{(!product?.disponible) ? <Chip label={`$${product?.precio}`} color="error" variant="outlined" /> : `$${product?.precio}`}</TableCell>
                    <TableCell>{(!product?.disponible) ? <Chip label={product?.categoria?.nombre} color="error" variant="outlined" /> : product?.categoria?.nombre}</TableCell>
                    <TableCell>{(!product?.disponible) ? <Chip label={`${product?.stock} En existencia`} color="error" variant="outlined" /> : `${product?.stock} En existencia`}</TableCell>
                    <TableCell>
                      {product?.disponible ?
                        (product?.stock > 0 ?
                          <Chip label="Existente" color="success" variant="outlined" /> :
                          <Chip label="Agotado" style={{ color: '#ff0000', border: '#ff0000 1px solid' }} variant="outlined" />
                        ) :
                        <Chip label='PRODUCTO BORRADO' color="error" variant="outlined" />
                      }
                    </TableCell>
                    <TableCell>{(!product?.disponible) ? <Chip label={product?.descripcion} color="error" variant="outlined" /> : product?.descripcion}</TableCell>
                    <TableCell align="right">
                      <ProductosActions
                        onEdit={() => handleProductEdit(product?._id)}
                        onDelete={() => {
                          if (product?._id) {
                            ProductDelete(product?._id);
                            dispatch(getAllProductosThunk());
                          }
                        }}
                        onSell={() => handleProductSale(product?._id)}
                        canSell={product?.disponible === false || product?.stock <= 0}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : res}
            </TableBody>
          </Table>
          {/* <Pagination count={Math.ceil(searchResults.results.length / usersPerPage)} page={page} onChange={handleChangePage} color="primary" shape="rounded" style={{ marginTop: '10px'}}/> */}
          <PaginationAtom count={Math.ceil(searchResults.results.length / usersPerPage)} page={page} onChange={handleChangePage} color="primary" shape="rounded" style={{ marginTop: '10px' }} />
          {
            <Notifications handler={handleBackendErrorsAboutProductUpate} actionName='producto' actionType='Actualizando' />
          }
          {
            /** resultados de búsqueda */
            (searchResults.status === 'failed') && <SnackBar severity='error' errorMsg={searchResults.BackendErrorMessage}></SnackBar>
          }
          {
            (searchResults.totalResults === 0) && <SnackBar severity='warning' errorMsg='No se econtraron resultados para esta búsqueda !'></SnackBar>
          }
          {
            (searchResults.status === 'in-progress') && <SnackBar severity='info' errorMsg='buscando, apreciamos tu paciencia...'></SnackBar>
          }
          {
            (searchResults.status === 'success') && <SnackBar severity='success' errorMsg={searchResults.msg}></SnackBar>
          }
          {
            (productHandler.status === 'success') && <SnackBar severity='success' errorMsg={productHandler.msg}></SnackBar>
          }
          {
            /** clientes delete */
            (deleteProductsHandler.status === 'failed') && <SnackBar severity='error' errorMsg={deleteProductsHandler.BackendErrorMessage}></SnackBar>
          }
          {
            /** clientes delete */
            (deleteProductsHandler.status === 'in-progress') && <SnackBar severity='info' errorMsg='Borrando producto, apreciamos tu paciencia...'></SnackBar>
          }
          {
            /** clientes delete */
            (deleteProductsHandler.status === 'success') && <SnackBar severity='success' errorMsg={deleteProductsHandler.msg}></SnackBar>
          }
        </Paper>
      </Grid>
    </React.Fragment >
  );
})