import React from 'react'
import { IdealForItem } from '../atoms/idealForItem';

const categories = [
    { title: "Gimnasios", icon: "💪", description: "Gestiona empleados, clientes y progreso fácilmente." },
    { title: "Escuelas de baile", icon: "💃", description: "Seguimiento completo de clientes y clases." },
    { title: "Escuelas de música", icon: "🎶", description: "Almacena recursos de video y materiales de enseñanza." },
    { title: "Escuelas de canto", icon: "🎤", description: "Incrementa resultados en clientes y potencia las ventas." },
    { title: "Centros deportivos", icon: "🏟️", description: "Resuelve problemas de seguimiento de forma eficiente." },
    { title: "Centros de box", icon: "🥊", description: "Obtén visibilidad clara sobre el progreso de tu centro." },
    { title: "Centros de MMA", icon: "🥋", description: "Simplifica la administración y haz crecer tu negocio." },
    { title: "Centros de pilates", icon: "🧘", description: "Solución completa para la gestión de tu centro." },
    { title: "Centros de yoga", icon: "🧘‍♂️", description: "Fácil gestión de tu centro de yoga." },
    { title: "Centros de mecánica", icon: "🔧", description: "Administra membresías y progreso fácilmente." },
    { title: "Centros de crossfit", icon: "🏋️‍♂️", description: "Sistema completo todo en uno para entrenamiento." },
];

export const IdealForSection = () => {
    return (
        <div className="mt-20 overflow-hidden mb-6">
            <h2 className="text-4xl font-bold text-mainBlue mb-4">Ideal para</h2>
            <div className="grid grid-cols-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mb-6">
                {categories.map((category, index) => (
                    <IdealForItem key={index} title={category.title} icon={category.icon} description={category.description} />
                ))}
            </div>
        </div>
    );
}
