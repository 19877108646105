import { createSlice } from '@reduxjs/toolkit';

/*interface INITIALSTATE {
  uid : string,
  nombre : string,
  apellidoPaterno : string,
  apellidoMaterno : string,
  tenant : string,
  email : string,
  fechaIngreso : string,    
  fechaNacimiento : string,
  direccion : string,
  planMensualTipo : string,
  planStatusActivo : boolean,
  planMensualMonto : string,
  estado : boolean, // si aun existe el usuario, si no se ha borrado
  role : string,
  photoURL : string,
}  
 */

export const clientRegisterSlide = createSlice({
    name : "client-register",
    initialState : {
        status : 'un-register', //'checking', 'not-authenticated' , 'authenticated'
        uid : null,
        nombre : null,
        apellidoPaterno : null,
        apellidoMaterno : null,
        tenant : null,
        email : null,
        fechaIngreso : null,
        fechaNacimiento : null,
        direccion : null,
        planMensualTipo : null,
        planStatusActivo : null,
        planMensualMonto : null,
        estado:null,
        role : null,
        photoURL : null,
        BackendErrorMessage : null
    },
    reducers : {
        failedClientRegister: ( state, { payload } ) => {
            state.status = 'un-register-user'; 
            state.uid = null;
            state.nombre = null;
            state.apellidoPaterno = null;
            state.apellidoMaterno = null;
            state.tenant = null;
            state.email = null;
            state.fechaIngreso = null;
            state.fechaNacimiento = null;
            state.direccion = null;
            state.planMensualTipo = null;
            state.planStatusActivo = null;
            state.planMensualMonto = null;
            state.estado = null;
            state.role = null;
            state.photoURL = null;
            state.BackendErrorMessage = payload;
        },
        clientRegistered: ( state, { payload } ) => {
            state.status = 'user-registered'; // 'checking', 'not-authenticated', 'authenticated'
            state.uid = payload.uid;
            state.nombre = payload.nombre;
            state.apellidoPaterno = payload.apellidoPaterno;
            state.apellidoMaterno = payload.apellidoMaterno;
            state.tenant = payload.tenant;
            state.email = payload.email;
            state.fechaIngreso = payload.fechaIngreso;
            state.fechaNacimiento = payload.fechaNacimiento;
            state.direccion = payload.direccion;
            state.planMensualTipo = payload.planMensualTipo;
            state.planStatusActivo = payload.planStatusActivo;
            state.planMensualMonto = payload.planMensualMonto;
            state.estado = payload.estado;
            state.role = payload.role;
            state.photoURL = payload.photoURL;
            state.BackendErrorMessage = null;
        },
        clientRegistering : ( state )=>{
            state.status = 'registering';
        }
    }
});

export const { failedClientRegister,clientRegistered,clientRegistering } = clientRegisterSlide.actions;