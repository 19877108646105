import { useDispatch } from "react-redux"
import { useNavigate } from 'react-router-dom';
import { startDeleteEventThunk } from "../store/eventos";
import { deleteCategoryThunk } from '../store/categorias'
import { AppDispatch } from '../store/store';
import { startDeleteUsersThunk } from "../store/clientes/thunk";
import { FormEventosUpdate } from '../components/FormEventosUpdate';
import { createCategoryThunk } from '../store/categorias/index';
import { CHANGE_PASSWORD, POST_CATEGORY, RENEW_MEMBERSHIP } from '../interfaces/interfaces';
import CreateProductModal from '../atoms/ProductoModal';
import { openModal } from "../store/modal";
import { getProductByIdThunk, startDeleteProductsThunk } from "../store/productos";
import { changePasswordThunk, renewMembershipThunk, saveUserId } from "../store/usuarios";
import { config } from "../config";


/**
 * columna de opciones en las tablas
 * improve: cambiar normbre a useMenuActions instead od userActions, because has all the actiosn
 * productos/categorias/eventos
 */
export const useUsersActions = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  /**eventos */
  const handleEventEdit = (uid: string | undefined) => {
    navigate(`/user/eventos/actualizar?uid=${uid}`);

  }

  const EventDelete = async (uid: string | undefined) => {
    const shouldDelete = window.prompt('Para confirmar la eliminación del evento, escribe "eliminar" y haz clic en Aceptar:');
    if (uid === undefined || uid.length === 0) {
      return;
    } else if (shouldDelete && shouldDelete.toLowerCase() === 'eliminar') {
      await dispatch(startDeleteEventThunk(uid));
    }
  }
  const handleDelete = (uid: string) => {
    // const shouldDelete = window.prompt('Para confirmar la eliminación, escribe "eliminar" y haz clic en Aceptar:');

    // if (shouldDelete && shouldDelete.toLowerCase() === 'eliminar') {
    //   dispatch(startDeleteUsersThunk(uid))
    // }
  }
  const RederiqDetailsEvents = (id: string | undefined) => {
    navigate(`/user/eventos/${id}/detalles`)
  }

  const createEvent = ( ) =>{
    dispatch({
      type : openModal.type,
      payload : config.MODAL_TYPE_CREATE_EVENT
    });
  }
  
  /**usuarios */
  const handleRegisterUser = ( modalType : string) => {
    dispatch({
      type : openModal.type,
      payload : modalType
    });
  }

  const handleConfirmDelete = (uid: string) => {
    const shouldDelete = window.prompt('Para confirmar la eliminación, escribe "eliminar" y haz clic en Aceptar:');

    if (uid !== undefined && shouldDelete && shouldDelete.toLowerCase() === 'eliminar') {
      dispatch(startDeleteUsersThunk(uid))
    } else {
      console.error('Error al eliminar usuario')
    }
  }

  const handleOpenModal = (userId: string) => {
    dispatch({
      type : saveUserId.type,
      payload : { userId: userId }
    });

    dispatch(openModal())
  }
  
  const handleRenewMembership = (membershipInfo: RENEW_MEMBERSHIP, userId: string | null) => {
    if (userId !== null) {
      dispatch(renewMembershipThunk(membershipInfo, userId))
    }
  }

  const handlUserEdit = ( uid: string | undefined, role: string | undefined) => {
    navigate(`/user/edit/${uid}/${role}`);
    //navigate(`/user/edit/?uid=${uid}`);
  }

  const handlePasswordChange = async (formState: CHANGE_PASSWORD, userId: string | null, onResetForm: () => void) => {
    const useToResetForm = await dispatch(changePasswordThunk(formState, userId as string));

    if (useToResetForm?.ok === true) {
      onResetForm();
      dispatch({
        type: openModal.type,
        payload: config.MODAL_TYPE_CHANGED_PASSWORD
      });
    }
  }
  /**category */

  const createCategory = async () => {
    const nombre = window.prompt('Escribe el nombre de la categoría:');    
    if (nombre !== null && nombre !== undefined) {
      const nombreSeparado = nombre.split(' ').join('-');
      await dispatch(createCategoryThunk(nombreSeparado))
    } else {
      console.error(`El valor ingresado no es válido`)
    }
  }

  const deleteCategory = async (id: string | undefined) => {
    const shouldDelete = window.prompt('Para confirmar la eliminación de la categoría, escribe "eliminar" y haz clic en Aceptar:');
    if (id === undefined || id.length === 0) {
      return;
    } else if (shouldDelete && shouldDelete.toLowerCase() === 'eliminar') {
      await dispatch(deleteCategoryThunk(id));
    }
  }

  const handleEditCategory = (id: string | undefined) => {
    navigate(`/user/categorias/actualizar?uid=${id}`);
  }

  /** products */

  const handleEditProduct = () => {
    dispatch({
      type: openModal.type,
      payload: config.MODAL_TYPE_EDIT_PRODUCT
    });
  }

  const handleSellProduct = () => {
    dispatch({
      type: openModal.type,
      payload: config.MODAL_TYPE_SELL_PRODUCT
    });
  }

  const ProductDelete = async (uid: string | undefined) => {
    const shouldDelete = window.prompt('Para confirmar la eliminación del producto, escribe "eliminar" y haz clic en Aceptar:');
    if (uid === undefined || uid.length === 0 ) {
      return;
    } else if(shouldDelete && shouldDelete.toLowerCase() === 'eliminar') {
      await dispatch(startDeleteProductsThunk(uid));
    }
  }
  return {
    handleEventEdit,
    handleDelete,
    handleRenewMembership,
    handleOpenModal,
    handleRegisterUser,
    RederiqDetailsEvents,
    EventDelete,
    handleConfirmDelete,
    handlUserEdit,
    handleEditProduct,
    handleSellProduct,
    createCategory,
    handleEditCategory,
    deleteCategory,
    ProductDelete,
    createEvent,
    handlePasswordChange
  }
}