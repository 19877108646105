import { createSlice } from '@reduxjs/toolkit';
/**  Evento plan BASICO*/
/*interface INITIALSTATE {
    "nombre" : "Introducción a la programacion",
    "descripcion" : "Grupo 2B",
    "fecha_inicio" : "2023-10-29",
    "duracion":"1640",
    "ubicacion":"Frente a bodega ahorrera, Benito Juarez",
    "cupo_maximo" : "12"
}
 */

export const editUserSlice = createSlice({
    name: "user-edit",
    initialState: {
        status: 'initial',
        msg: null,
        uid: null,
        nombre: null,
        apellidoPaterno: null,
        apellidoMaterno: null,
        tenant: null,
        email: null,
        fechaIngreso: null,
        fechaNacimiento: null,
        direccion: null,
        planMensualTipo: null,
        planMensualMonto: null,
        salarioMensual: null,
        planStatusActivo: null,
        licencia: null,
        antiguedad: null,
        estado: null,
        role: null,
        photoURL: null,
        img: null,
        timeZone: null,
        BackendErrorMessage: null
    },
    reducers: {
        failedEditUser: (state, { payload }) => {
            state.status = 'failed';
            state.msg = null;
            state.nombre = null;
            state.apellidoPaterno = null;
            state.apellidoMaterno= null;
            state.tenant= null;
            state.email= null;
            state.fechaIngreso= null;
            state.fechaNacimiento= null;
            state.direccion= null;
            state.planMensualTipo= null;
            state.planMensualMonto= null;
            state.salarioMensual= null;
            state.planStatusActivo= null;
            state.licencia= null;
            state.antiguedad= null;
            state.estado= null; 
            state.role= null;
            state.photoURL= null;
            state.img= null;
            state.timeZone= null;
            state.BackendErrorMessage = payload;
        },
        userEdited: (state, { payload }) => {
            state.status = 'success';
            state.msg = payload.msg;
            state.nombre = payload.nombre;
            state.apellidoPaterno = payload.apellidoPaterno;
            state.apellidoMaterno= payload.apellidoMaterno;
            state.tenant= payload.tenant;
            state.email= payload.email;
            state.fechaIngreso= payload.fechaIngreso;
            state.fechaNacimiento= payload.fechaNacimiento;
            state.direccion= payload.direccion;
            state.planMensualTipo= payload.planMensualTipo;
            state.planMensualMonto= payload.planMensualMonto;
            state.salarioMensual= payload.planMensualMonto;
            state.planStatusActivo= payload.planStatusActivo;
            state.licencia= payload.licencia;
            state.antiguedad= payload.antiguedad;
            state.estado= payload.estado; 
            state.role= payload.role;
            state.photoURL= payload.photoURL;
            state.img= payload.img;
            state.timeZone= payload.timeZone;
            state.BackendErrorMessage = null;
        },
        EditingUser: (state) => {
            state.status = 'in-progress';
        }
    }
});

export const { failedEditUser, userEdited, EditingUser } = editUserSlice.actions;