import React, { useEffect, useState } from 'react';
import { Modal, TextField, Button, Box } from '@mui/material';

import { useForm } from '../customhooks';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { closeModal } from '../store/modal';
import { createProductThunk } from '../store/productos/thunk';
import { FormProductCreateProps, POST_PRODUCT } from '../interfaces/interfaces';
import { SnackBar } from './SnackBar';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 4,
};

const btnStyle = {
    width: '100%'
}

const CreateProductModal = ({ modalType }: FormProductCreateProps) => {

    const open = useSelector((state: RootState) => state.modal.openModal);
    const modalTypeStore = useSelector((state: RootState) => state?.modal.modalType);

    const productHandler = useSelector((state: RootState) => state?.postProduct);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {

    }, [open]);

    const handleClose = () => {
        dispatch(closeModal());
    };

    const { onInputChange, formState, onResetForm } = useForm({
        nombre: '',
        categoria: '',
        descripcion: '',
        precio: '0',
        stock: '1',
    })


    const handleSubmit = async () => {
        //mandar la data al thunk
        const productData: POST_PRODUCT = {
            nombre: formState.nombre as string,
            categoria: formState.categoria as string,
            descripcion: formState.descripcion as string,
            precio: formState.precio as number | any,
            stock: formState.stock as number | any,
        };
        const success = await dispatch(createProductThunk(productData));        
        if (success?.ok === true) {
            onResetForm()
            handleClose();
        }
    };
    return (
        <>
        {
            modalType === modalTypeStore && (
                <Modal open={open} onClose={handleClose} aria-labelledby="Crear producto" aria-describedby="añade un producto a stock">
                    <Box sx={style}>
                        <TextField
                            label="Nombre"
                            name="nombre"
                            value={formState.nombre}
                            onChange={onInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Categoría"
                            name="categoria"
                            value={formState.categoria}
                            onChange={onInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Descripción"
                            name="descripcion"
                            value={formState.descripcion}
                            onChange={onInputChange}
                            fullWidth
                            multiline
                            rows={4}
                            margin="normal"
                        />
                        <TextField
                            label="Precio"
                            name="precio"
                            type="number"
                            value={formState.precio}
                            onChange={onInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Stock"
                            name="stock"
                            type="number"
                            value={formState.stock}
                            onChange={onInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <Button variant="contained" color="primary" onClick={handleSubmit} sx={btnStyle}>
                            Crear Producto
                        </Button>
                    </Box>
                </Modal>
            )
        }
            {
                (productHandler.status === 'in-progress') && <SnackBar severity='info' errorMsg='Creando producto , apreciamos tu paciencia...'></SnackBar>
            }
            {
                /** products messages */
                (productHandler.status === 'failed') && <SnackBar severity='error' errorMsg={productHandler.BackendErrorMessage}></SnackBar>
            }
        </>
    );
};

export default CreateProductModal;
