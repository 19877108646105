import { createSlice } from "@reduxjs/toolkit";
import { GET_PRODUCT_BY_ID } from "../../../interfaces/interfaces";

export const getProductByIdSlice = createSlice({
  name: "productosById",
  initialState: {
    status: "normal",
    msg: null,
    producto: {} as GET_PRODUCT_BY_ID,
    BackendErrorMessage: null,
  },
  reducers: {
    failedGetProductById: (state, { payload }) => {
      state.status = "failed";
      state.producto = {} as GET_PRODUCT_BY_ID;
      state.BackendErrorMessage = payload;
    },
    getProductById: (state, { payload }) => {
      state.status = "success";
      state.msg = payload.msg;
      state.producto = payload.producto;
      state.BackendErrorMessage = null;
    },
    gettingProductById: (state) => {
      state.status = "in-progress";
    },
  },
});

export const {
  failedGetProductById,
  getProductById,
  gettingProductById,
} = getProductByIdSlice.actions;