import { useState } from 'react';

import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material';
import Title from '../atoms/Title';

import { closeModal } from '../store/modal';
import { AppDispatch, RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';

import { useUsersActions } from '../customhooks/useUsersActions';
import { useForm } from '../customhooks';


const style = {
  display: 'flex',
  gap: '10px',
  flexDirection: 'column',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,

};

const btnStyle = {
  minWidth: '100px',
  fontSize: '13px',
}
const selectStyle = {
  marginBottom: '3px',
  marginTop: '1px'
};

export const FormReNewMembership = () => {
  const { onInputChange, handleSelectChange, formState , onResetForm } = useForm({
    planMensualTipo: '',
    planMensualMonto: '',
  })
  const open = useSelector((state: RootState) => state?.modal?.openModal);
  const userId = useSelector((state: RootState) => state?.saveUserId?.userId);
  const usr = useSelector((state: RootState) => state?.auth);
  const dispatch = useDispatch<AppDispatch>()

  const { handleRenewMembership } = useUsersActions();
  const selectOptions = usr?.role === 'MANAGEMENT-SERVICE-ROLE' ?
    ["Mensual", "Semestral", "Anual"] :
    ["Semanal", "Quincenal", "Mensual", "Semestral", "Anual", "Cuatrimestral"];

  const handleClose = () => {
    dispatch(closeModal());
    onResetForm();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleRenewMembership(formState as any, userId)
    dispatch(closeModal())   
  }

  return (
    <>
      <Modal open={ open } onClose={ handleClose } aria-labelledby="Renovar membresía" aria-describedby="añade un producto a stock">
        <form onSubmit={ handleSubmit } style={ { display: 'flex', flexWrap: 'wrap', flexDirection: 'column', width: '100%' } }>
          <Box sx={ style }>
            <Title>Renovar membresía</Title>
            <FormControl style={ selectStyle } fullWidth>
              <InputLabel id="activarPlan-label">Activar Plan de suscripción</InputLabel>
              <Select
                labelId="Plan mensual tipo"
                name="planMensualTipo"
                value={ formState?.planMensualTipo }
                onChange={ handleSelectChange as any }
              >
                { selectOptions.map(option => (
                  <MenuItem key={ option } value={ option.toUpperCase() }>{ option }</MenuItem>
                )) }
              </Select>
            </FormControl>
            <TextField
              label="Monto del plan"
              name="planMensualMonto"
              type='number'
              value={ formState?.planMensualMonto }
              onChange={ onInputChange }
              fullWidth
              margin="normal"
              required
            />

            <Box sx={ { display: 'flex', justifyContent: 'space-between' } }>
              <Button
                variant="outlined"
                color="error"
                onClick={ handleClose }
                style={ btnStyle }
              >
                Cancelar
              </Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                style={ btnStyle }
              >
                Renovar
              </Button>
            </Box>
          </Box>
        </form>
      </Modal>
    </>
  )
}