import FormEditConfiguration from "./FormEditConfiguration";
import ConfigurationUserData from "./ConfigurationUserData";
import FormChangePassword from "./FormChangePassword";
import InfoGrid from "./InfoGrid";
import Title from "../atoms/Title";

import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";

import { Grid, Paper, Box, Tabs, Tab } from "@mui/material"
import CircularProgress from '@mui/material/CircularProgress';


import { useForm, useChooseForm } from "../customhooks";
import { Notifications } from "../atoms/Notifications";
import FormChangeLicense from "./FormChangeLicense";
import InformationModal from "./InformationModal";

export const Configuraciones = () => {
  const {
    email,
    nombreEmpresa,
    tenant,
    licencia,
    role,
    uid
  } = useSelector((state: RootState) => state.auth);
  const handleErrorEdit = useSelector((state: RootState) => state?.editUser);
  const handleErrorPassword = useSelector((state: RootState) => state?.changePassword);
  const handleErrorLicense = useSelector((state: RootState) => state?.changeLicense);

  const [tab, setTab] = useState(0);
  const { chooseForm, chooseShowInformation } = useChooseForm(role)
  const { onInputChange, formState, onResetForm } = useForm(chooseForm)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  }

  return (
    <>
      <Grid item width={ '100%' }>
        <Paper sx={ { p: 2 } }>
          {
            handleErrorEdit.status === 'in-progress' ? 
            <Box width='100%' height='500px' display='flex' alignItems='center' justifyContent='center'>
              <CircularProgress />
            </Box> :
            <>
              <Title>Información de la empresa</Title>
              <ConfigurationUserData
                email={ email }
                nombreEmpresa={ nombreEmpresa }
                tenant={ tenant }
                licencia={ licencia }
              />

              <Tabs value={ tab } onChange={ handleChange } scrollButtons allowScrollButtonsMobile variant="scrollable">
                <Tab label="Información personal" />
                <Tab label="Editar información" />
                <Tab label="Cambiar contraseña" />
                { role === 'SUPER_ADMIN' && <Tab label="Cambiar licencia" />}
              </Tabs>
              { tab === 0 &&
                <Box display='flex' alignItems='center' flexDirection='column' paddingY={ 6 } >
                  <InfoGrid form={ chooseShowInformation } />
                </Box>
              }
              <Box display='flex' flexDirection='column' paddingX={ 6 } paddingTop={ 2 } alignItems='center'>
                { tab === 1 && <FormEditConfiguration email={ email } form={ formState } onResetForm={ onResetForm } uid={ uid } inputs={ chooseShowInformation } onInputChange={ onInputChange } /> }
                { tab === 2 && <FormChangePassword userId={uid} /> }
                { tab === 3 && <FormChangeLicense role={role} /> }
              </Box>
            </>
          }
          </Paper>
      </Grid>
        
      <InformationModal text='Por favor, vuelve a iniciar sesión con la nueva contraseña' />
      <InformationModal text='Los cambios se verán reflejados tras iniciar sesión nuevamente' />
      
      <Notifications handler={ handleErrorLicense } actionType="Actualizando" actionName="licencia" />
      <Notifications handler={ handleErrorEdit } actionType="Actualizando" actionName="usuario" />
      <Notifications handler={ handleErrorPassword } actionType="Actualizando" actionName="contraseña" />
    </>

  )
}
