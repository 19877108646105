export const validateEmail = (email:string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return !emailRegex.test(email)&&'El correo debe ser válido.';
};

export const validatePassword = (password : string | any) => {

  if (!/[A-Z]/.test(password)) {
    return 'La contraseña debe contener al menos una letra mayúscula.';
  } else if (!/[a-z]/.test(password)) {
    return 'La contraseña debe contener al menos una letra minúscula.';
  }else if (!/\d/.test(password)) {
    return 'La contraseña debe contener al menos un número del 0 al 9.';
  }else if (!/(?=.*[%\/&@!°><# ])/.test(password)) { //antes : !/[!@#$%^&*]/
    return 'La contraseña debe contener al menos un carácter especial de estos :  % / & @ ! ° >< # $';
  }else if (password.length < 8) {
    return 'La contraseña debe tener al menos 8 caracteres.';
  }else if (password === '') {
    return 'La contraseña es obligatoria.';
  }

  return null;
};

export const sigindbName = (field: string) => {
  if (!field || field === '') {
    return false;
  }
  const parts = field.split('-');
  if (parts.length <= 5 && parts[0] !== '' && parts[1] !== '') {
    return true;
  }
  return false;
};

