import { Box, Modal, Button } from "@mui/material"
import InfoIcon from '@mui/icons-material/Info';

import { useNavigate } from "react-router-dom"

import { closeModal } from "../store/modal"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../store"

import { config } from "../config";

import type { InformationModalProps } from "../interfaces/interfaces";

const style = {
  display: 'flex',
  gap: '10px',
  flexDirection: 'column',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,

};

const InformationModal = ({ text }: InformationModalProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const typeModal = useSelector((state: RootState) => state?.modal?.modalType);
  const open = useSelector((state: RootState) => state?.modal?.openModal);

  const handleClose = () => {
    dispatch(closeModal());
    if (typeModal === config.MODAL_TYPE_CHANGED_PASSWORD) {
      localStorage.removeItem('token');
      navigate('/login');
    }
  }

  return (
    <Modal open={ open } onClose={ handleClose } aria-labelledby="Iniciar sesión" aria-describedby="vuleve a iniciar sesión">
      <Box sx={ style }>
        <Box display='flex' alignItems='center' gap={2} paddingY={2}>
          <InfoIcon sx={ { fontSize: 50 } } color="primary" />
          <h3>{text}</h3>
        </Box>
        <Button
          sx={ {alignSelf: 'flex-end'} }
          variant="contained"
          color="primary"
          onClick={handleClose}
        >
          Aceptar
        </Button>
      </Box>
    </Modal>
  )
}

export default InformationModal