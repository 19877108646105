import React, { useState, ChangeEvent, useEffect } from 'react';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Chip, Grid, Paper, Typography } from '@mui/material';
import { useForm } from '../customhooks/useForm';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { useNavigate } from 'react-router-dom';
import { SnackBar } from '../atoms/SnackBar';
import { startRegisterUser } from '../store/slides/Auth';
import { useFormStateValidation } from '../customhooks/useFormStateValidation';
import { getTimeZoneFromUser } from '../utils/timeZoneParser';


const paperStyle = {
  padding: '20px',
  margin: 'auto auto auto 30px',
  borderRadius: '10px',
  width: '100%',
  marginTop: '25px',
  //maxWidth: '500px',
};

const formStyle = {
  display: 'flex',
  flexWrap: 'wrap',
};

const textFieldStyle = {
  marginBottom: '20px',
  marginTop: '10px',
  width: '100%'
};

const selectStyle = {
  marginBottom: '20px',
  marginTop: '40px'
};

const buttonStyle = {
  marginTop: '20px',
  width: '40%'
};
export const RegisterFormTenants = () => {
  const timeZoneData = getTimeZoneFromUser()

  const [activeStep, setActiveStep] = useState(0);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const tenantHandler = useSelector((state: RootState) => state?.register);

  const { handleSelectChange, onInputChange, formState, onResetForm } = useForm({
    tenantName: '',
    nombreEmpresa: '',
    databaseName: '',
    email: '',
    fechaNacimiento: '',
    direccion: '',
    planMensualTipo: 'MENSUAL',
    planStatusActivo: 'true',
    contrasena: '',
    licencia: 'BASICO',
    phoneNumber: '',
    //pagoDeInscripcion: '',
    timeZone: {
      identifier: timeZoneData.userTimeZone,
      offset: timeZoneData.userOffset
    },
    role: '',
  })
  const { error, errorPassword, fieldFormatError, passwordFormatError, dateBorn, dateBornError, handleEmail, handlePassword, dateValidation  } = useFormStateValidation(formState);

  /* Srepper */
  const handleNextStep = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBackStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
  const conditionHandler = () => {
    if (formState?.planStatusActivo !== 'true' || Object.keys(formState).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const email = formState?.email
    const password = formState?.contrasena
    const dateBorn = formState?.fechaNacimiento
    handleEmail(email as string)
    handlePassword(password as string)
    dateValidation(dateBorn as string)
  }, [formState?.email, formState?.contrasena,formState.fechaNacimiento]);


  const handleSubmit = async () => {
    const { nombreEmpresa, tenantName, databaseName, email, contrasena, fechaNacimiento, direccion, planMensualTipo, licencia, role ,timeZone ,phoneNumber} = formState;

    handleEmail(formState.email as string);

    const success = await dispatch(startRegisterUser({ nombreEmpresa, tenantName, databaseName, email, contrasena, fechaNacimiento, direccion, planMensualTipo, licencia, role ,timeZone,phoneNumber}, navigate));
    if (success?.ok === true) {
      onResetForm()
    } else {
      return
    }
  };

  //const steps = ['Datos Personales', 'Dirección', 'Detalles del Plan'];
  const steps = [
    'Datos Personales',
    'Dirección',
    'Detalles del Plan',
    'Revisar y Enviar',
  ];

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Paper style={paperStyle} sx={{ p: 2, display: 'flex', flexDirection: 'column', overflowX: 'auto' }}>
        <Typography variant="h6" align="center" mb={3}>
          Registro de Tenants y/o role de mantenimiento
        </Typography>
        <Stepper activeStep={activeStep} sx={{ display: 'flex', flexDirection: 'row', overflowX: 'auto', marginBottom: '3px' }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <form style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }} noValidate autoComplete="off">
          {activeStep === 0 && (
            <div>
              <TextField
                name="tenantName"
                label="Nombre del Inquilino"
                value={formState?.tenantName}
                onChange={onInputChange}
                style={textFieldStyle}
                fullWidth
                required
              />
              <TextField
                name="nombreEmpresa"
                label="Empresa"
                value={formState?.nombreEmpresa}
                onChange={onInputChange}
                style={textFieldStyle}
                fullWidth
                required
              />
              <TextField
                name="databaseName"
                label="Registrar base de datos"
                value={formState?.databaseName}
                onChange={onInputChange}
                style={textFieldStyle}
                fullWidth
                required
              />
              <TextField
                name="email"
                label="Email"
                value={formState?.email}
                onChange={onInputChange}
                style={textFieldStyle}
                fullWidth
                error={fieldFormatError}
                helperText={
                  fieldFormatError && error
                }
                required
              />
              <TextField
                name="fechaNacimiento"
                label="Fecha de Nacimiento"
                value={formState?.fechaNacimiento}
                onChange={onInputChange}
                type="date"
                style={textFieldStyle}
                fullWidth
                error={dateBornError}
                helperText={
                  dateBornError && dateBorn
                }
                InputLabelProps={{
                  shrink: true, // Ajusta la posición de la etiqueta
                }}
              />
              <TextField
                name="contrasena"
                label="Contraseña"
                value={formState?.contrasena}
                onChange={onInputChange}
                type="password"
                style={textFieldStyle}
                fullWidth
                error={passwordFormatError}
                helperText={
                  passwordFormatError ? errorPassword : ''
                }
                required
              />
            </div>
          )}
          {activeStep === 1 && (
            <div>
              <TextField
                name="direccion"
                label="Dirección/Ubicación de la empresa"
                value={formState?.direccion}
                onChange={onInputChange}
                style={textFieldStyle}
                fullWidth
              />
              <TextField
                name="phoneNumber"
                label="Número de teléfono"
                value={formState?.phoneNumber}
                onChange={onInputChange}
                style={textFieldStyle}
                fullWidth
                required
              />
            </div>
          )}
          {activeStep === 2 && (
            <div>
              <FormControl style={selectStyle} fullWidth>
                <InputLabel id="role-label">Role</InputLabel>
                <Select
                  labelId="role-label"
                  name="role"
                  value={formState?.role}
                  onChange={handleSelectChange}
                >
                  <MenuItem value="SUPER_ADMIN">SUPER_ADMIN</MenuItem>
                  <MenuItem value="MANAGEMENT-SERVICE-ROLE">MANAGEMENT-SERVICE-ROLE</MenuItem>
                </Select>
              </FormControl>

              {
                (formState?.role === 'SUPER_ADMIN') && (
                  <FormControl style={selectStyle} fullWidth>
                    <InputLabel id="licencia-label">Licencia</InputLabel>
                    <Select
                      labelId="licencia-label"
                      name="licencia"
                      value={formState?.licencia}
                      onChange={handleSelectChange}
                    >
                      <MenuItem value="BASICO">BASICO</MenuItem>
                      <MenuItem value="ESENCIAL">ESENCIAL</MenuItem>
                      <MenuItem value="ELITE">ELITE</MenuItem>
                    </Select>
                  </FormControl>
                )
              }
              {
                (formState?.role === 'SUPER_ADMIN') && (
                  <FormControl style={selectStyle} fullWidth>
                    <InputLabel id="planMensual-label">Tipo de plan</InputLabel>
                    <Select
                      labelId="planMensual-label"
                      name="planMensualTipo"
                      value={formState?.planMensualTipo}
                      onChange={handleSelectChange}
                    >
                      <MenuItem value="MENSUAL">Mensual</MenuItem>
                      <MenuItem value="SEMESTRAL">Semestral</MenuItem>
                      <MenuItem value="ANUAL">Anual</MenuItem>
                    </Select>
                  </FormControl>
                )
              }
              <FormControl style={selectStyle} fullWidth>
                <InputLabel id="activarPlan-label">Activar Plan de suscripción</InputLabel>
                <Select
                  labelId="activarPlan-label"
                  name="planStatusActivo"
                  value={formState?.planStatusActivo}
                  onChange={handleSelectChange}
                >
                  <MenuItem value="true">Si</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </FormControl>

            </div>
          )}
          {activeStep === 3 && (
            <div style={{ marginTop: '10px' }}>
              <Typography variant="h6">Resumen del Formulario</Typography>
              <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '5px', marginBottom: '20px', width: '100%' }}>
                <Typography key="tenantName" variant="body1">
                  {`Nombre del Inquilino: ${formState?.tenantName}`}
                </Typography>
                <Typography key="nombreEmpresa" variant="body1">
                  {`Empresa : ${formState?.nombreEmpresa}`}
                </Typography>
                <Typography key="databaseName" variant="body1">
                  {`Registrar base de datos : ${formState?.databaseName}`}
                </Typography>
                <Typography key="email" variant="body1">
                  {`Email: ${(fieldFormatError) ? <Chip color='error' label={error} /> : formState?.email}`}
                </Typography>
                <Typography key="fechaNacimiento" variant="body1">
                  {`Fecha de Nacimiento: ${formState?.fechaNacimiento}`}
                </Typography>
                <Typography key="direccion" variant="body1">
                  {`Dirección: ${formState?.direccion}`}
                  <Typography key="role" variant="body1">
                    {`Role: ${formState?.role}`}
                  </Typography>
                </Typography>
                <Typography key="planMensualTipo" variant="body1">
                  {`Tipo del plan : ${formState?.planMensualTipo}`}
                </Typography>
                <Typography key="contrasena" variant="body1">
                  {`contraseña : ${formState?.contrasena}`}
                </Typography>
                <Typography key="planStatusActivo" variant="body1">
                  {`Status del plan : ${(formState?.planStatusActivo !== 'false') ? 'ACTIVO' : <Chip label='ATENCIÓN , FALTA ACTIVAR EL PLAN EN CAMPO ACTIVAR PLAN DE SUSCRIPCIÓN!!!' color='error' />}`}
                </Typography>
                <Typography key="licencia" variant="body1">
                  {`licencia : ${formState?.licencia}`}
                </Typography>
                <Typography key="phoneNumber" variant="body1">
                  {`Número de teléfono: ${formState?.phoneNumber}`}
                </Typography>
              </div>
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {activeStep !== 0 && (
              <Button
                variant="contained"
                color="inherit"
                style={buttonStyle}
                onClick={handleBackStep}
              >
                Atrás
              </Button>
            )}
            {activeStep < steps.length - 1 && (
              <Button
                variant="contained"
                color="primary"
                style={buttonStyle}
                onClick={handleNextStep}
                disabled={passwordFormatError || errorPassword || fieldFormatError || error}
              >
                Siguiente
              </Button>
            )}
            {activeStep === steps.length - 1 && (
              <Button
                variant="contained"
                color="primary"
                style={buttonStyle}
                onClick={handleSubmit}
                disabled={conditionHandler()}
              // disabled={formState.planStatusActivo==='false' || fieldFormatError || error}
              >
                Enviar
              </Button>)
            }
          </div>
          {
            (tenantHandler?.status === 'usuario-no-registrado') && <SnackBar severity='error' errorMsg={tenantHandler?.BackendErrorMessage}></SnackBar>
          }
          {
            (tenantHandler?.status === 'registrando') && <SnackBar severity='info' errorMsg='Registrando usuario...'></SnackBar>
          }
          {
            (tenantHandler?.status === 'usuario-registrado') && <SnackBar severity='success' errorMsg='Usuario registrado con éxito, visible en sección de búsqueda'></SnackBar>
          }
        </form>
      </Paper>
    </Grid>
  );
}
